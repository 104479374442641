import React, { useState, useEffect } from 'react';
import api from '../hooks/Api';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import useFileManager from '../hooks/files/useFileManager';
// import { projectFirestore } from '../firebase/config';
import styled from 'styled-components';
import Config from '../../src/config';

import {
  Grid,
  TextField,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Paper,
  Typography,
  IconButton,
} from '@material-ui/core';

import { Add, Remove } from '@material-ui/icons';
import { stringLastElement } from '../helpers/stringLastElement';

const ImgGrid = styled.div`
  margin: 20px auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 40px;
`;

const useStyles = makeStyles((theme) => ({
  pageContent: {
    marging: theme.spacing(5),
    padding: theme.spacing(3),
  },
  card: {
    minWidth: 220,
    minHeight: 300,
    backgroundColor: 'lightgray',
  },
  imgCard: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
}));

const ElevationsForm = () => {
  const [refresh, setRefersh] = useState(false);
  // here useFileManager hook first arguments is request that can be fetch, delete or update as value
  // param will be null when request is fetch else param will be a
  // parameter on which delete or update of a file will be executed
  // refresh and setRefresh are state variable by which we change state of page and refresh it
  const { docs } = useFileManager('fetch', null, refresh, setRefersh);

  const classes = useStyles();

  let history = useHistory();

  const { homeId } = useParams();

  const [formValues, setFormValues] = useState([
    {
      name: '',
      imagePath: '',
      status: 1,
      home_id: 1,
      homebuilder_id: '',
    },
  ]);

  const [numberOfElevations, setNumberOfElevations] = useState(1);

  const [open, setOpen] = React.useState(false);

  const [clickedElevationGridIndex, setClickedElevationGridIndex] =
    useState(null);

  const [selectedImg, setSelectedImg] = useState([
    {
      id: '',
      imagePath: '',
    },
  ]);

  const [homebuilderId, setHomebuilderId] = useState('');

  const urlSegment = useLocation();
  const requestType = urlSegment.pathname.split('/')[2]
    ? urlSegment.pathname.split('/')[2]
    : '';

  const { id } = useParams();

  const [deletedFormValues, setDeletedFormValues] = useState([]);

  const getHome = async () => {
    const response = await api.get(`/homes/${homeId}`);
    return response.data;
  };

  const getElevation = async () => {
    const elevation = await api.get(`/elevations/${id}`);
    return elevation.data;
  };

  const getElevationsByHome = async () => {
    const elevations = await api.get(`/elevations/home/${homeId}`);
    return elevations.data;
  };

  //   const retreveElevations = async () => {
  //     const response  = await api.get('/elevations');
  //     return response.data;
  // }

  const getAllElevations = async () => {
    const allElevations = await getElevationsByHome();

    // console.log(allElevations);
    if (allElevations.length > 0) {
      const newImgsArr = [];
      // console.log(allElevations.image);
      for (let i = 0; i < allElevations.length; i++) {
        newImgsArr[i] = {
          id: allElevations[i]._id,
          imagePath: allElevations[i].imagePath,
        };
      }
      // const [ image ] = allElevations;
      setNumberOfElevations(allElevations.length);
      setSelectedImg(newImgsArr);
      setFormValues(allElevations);
    } else {
      const getSingleHome = await getHome();
      setNumberOfElevations(getSingleHome.no_of_elevations);
      setHomebuilderId(getSingleHome.homebuilder_id);

      // setNumberOfElevations(current => current = getSingleHome.no_of_elevations);

      makeElevationGrids(
        getSingleHome.no_of_elevations,
        getSingleHome.homebuilder_id
      );
    }
  };

  const handleClickOpen = (index) => {
    // console.log(index);
    setClickedElevationGridIndex((current) => (current = index));
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const elevation = async () => {
    const getSingleElevation = await getElevation();
    // console.log(getSingleElevation)
    if (getSingleElevation) {
      // console.log(selectedImg);
      // setSelectedImg(current => {current[0]['id'] = ''; current[0]['url'] = getSingleElevation.image; } )
      const newImgsArray = [...selectedImg];
      newImgsArray[0]['id'] = '';
      newImgsArray[0]['imagePath'] = getSingleElevation.imagePath;
      // console.log(newImgsArray);
      setSelectedImg(newImgsArray);
      // console.log(selectedImg);
      setFormValues([getSingleElevation]);
    }
  };

  useEffect(() => {
    // const home = async () => {

    // const elevations = getElevationsByHome();
    // console.log(elevations);
    // if(getAllElevations()){
    // getAllElevations();
    // } else {
    //   makeElevationGrids(getSingleHome.no_of_elevations)
    // }
    // makeElevationGrids(getSingleHome.no_of_elevations);

    // };
    if (homeId !== undefined) {
      getAllElevations();
    }

    if (requestType !== '' && homeId === undefined) {
      elevation();
    }
  }, []);

  const makeElevationGrids = (noOfElevations, homebuilder_id) => {
    // console.log(homebuilder_id);
    // console.log(homebuilderId);
    const newFormValuesArray = [];
    const newImgsArr = [];
    for (let i = 0; i < noOfElevations; i++) {
      newFormValuesArray[i] = {
        name: '',
        imagePath: '',
        status: 1,
        home_id: homeId,
        homebuilder_id: homebuilder_id,
      };
      newImgsArr[i] = {
        id: '',
        imagePath: '',
      };
    }
    setSelectedImg(newImgsArr);
    setFormValues(newFormValuesArray);
  };

  const handleInputChange = (index, e) => {
    const values = [...formValues];
    values[index][e.target.name] = e.target.value;
    setFormValues(values);
  };

  const handleClickImage = (doc) => {
    console.log('file: ', doc);
    const newImgsArray = [...selectedImg];
    newImgsArray[clickedElevationGridIndex].id = doc._id;
    newImgsArray[clickedElevationGridIndex].imagePath = doc.path;
    console.log(newImgsArray);
    setSelectedImg(newImgsArray);
    // setSelectedImg(current => {
    //   current[clickedElevationGridIndex]['id'] = doc.id;
    //   current[clickedElevationGridIndex]['url'] = doc.url;
    // });

    const values = [...formValues];
    values[clickedElevationGridIndex]['imagePath'] =
      selectedImg[clickedElevationGridIndex]['imagePath'];
    setFormValues(values);
    setOpen(false);
    // console.log(selectedImg);
    // console.log(values);
    console.log(formValues);
  };

  const modalDialog = (
    <Dialog
      fullWidth
      maxWidth='md'
      open={open}
      onClose={handleClose}
      aria-labelledby='max-width-dialog-title'
    >
      <DialogTitle id='max-width-dialog-title'>Optional sizes</DialogTitle>
      <DialogContent>
        <ImgGrid>
          {docs &&
            docs.map((doc, index) => (
              <Card
                className={classes.imgCard}
                onClick={() => handleClickImage(doc)}
                key={index}
              >
                <CardActionArea>
                  <CardMedia
                    component='img'
                    className={classes.media}
                    src={Config.baseURL + doc.path}
                    title={doc.name}
                  />
                  <CardContent>
                    <Typography>{doc.name}</Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            ))}
        </ImgGrid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color='primary'>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );

  const handleSubmit = async (event) => {
    event.preventDefault();
    // console.log(homebuilderId);
    console.log('formvalues: ', formValues);
    const getSingleHome = await getHome();

    // Single elevation edit
    if (
      requestType === 'edit' &&
      urlSegment.pathname.split('/')[3] !== 'home' &&
      homebuilderId === ''
    ) {
      await api.patch(`/elevations/${id}`, formValues[0]);
      // Multiple elevations edit
    } else if (
      requestType === 'edit' &&
      urlSegment.pathname.split('/')[3] === 'home' &&
      homebuilderId === ''
    ) {
      const oldFormValues = formValues.filter((obj) =>
        checkKeyExists(obj, '_id')
      );

      const newFormValues = formValues.filter(
        (obj) => !checkKeyExists(obj, '_id')
      );
      if (oldFormValues.length > 0) {
        const updateElevations = await api.patch(
          `/elevations/home/${homeId}`,
          oldFormValues
        );
        // console.log(`updated: ${updateElevations}`);
      }
      if (newFormValues.length > 0) {
        const createElevations = await api.post('/elevations', newFormValues);
        // console.log(`created: ${createElevations}`);
      }
      if (deletedFormValues.length > 0) {
        // console.log(deletedFormValues);
        const deletedElevations = await api.patch(
          `/elevations/home/${homeId}`,
          deletedFormValues
        );
        // console.log(`deleted: ${deletedElevations}`);
      }
      const homesResponse = await api.patch(
        `/homes/query/{home_id: ${homeId}}`,
        {
          no_of_elevations: numberOfElevations,
        }
      );
      // console.log(`homes: ${homesResponse}`);
    } else {
      // console.log(formValues);
      // console.log(homeId);
      await api.post('/elevations', formValues);
      await api.patch(`/homes/query/home_id-${homeId}`, {
        no_of_elevations: numberOfElevations,
      });
    }

    history.push('/elevations');
  };

  const checkKeyExists = (obj, key) => {
    if (obj.hasOwnProperty(key)) {
      return true;
    } else {
      return false;
    }
  };

  const handleAddGrid = () => {
    setFormValues([
      ...formValues,
      {
        name: '',
        imagePath: '',
        status: 1,
        home_id: homeId,
        homebuilder_id: formValues[0].homebuilder_id,
      },
    ]);
    setSelectedImg([
      ...selectedImg,
      {
        id: '',
        imagePath: '',
      },
    ]);
    setNumberOfElevations((currentValue) => currentValue + 1);
  };

  const handleRemoveGrid = (e, index) => {
    const newFormValues = [...formValues];
    const selectedImgValues = [...selectedImg];

    let newArr = newFormValues.splice(index, 1);
    newArr[0].status = 0;
    setDeletedFormValues((current) => {
      current = deletedFormValues;
      current.push(newArr[0]);
      return current;
    });
    selectedImgValues.splice(index, 1);

    setFormValues(newFormValues);
    setSelectedImg(selectedImgValues);

    setNumberOfElevations((currentValue) => currentValue - 1);
  };

  return (
    <div>
      <Grid container>
        <Grid item container>
          <Grid item container spacing={2}>
            <Grid item>
              <Button variant='contained' size='large'>
                <Link to='/elevationsform'>Add Elevation</Link>
              </Button>
            </Grid>
            <Grid item>
              <Button variant='contained' size='large'>
                <Link to='/elevations'>View All Elevations</Link>
              </Button>
            </Grid>
            <Grid item>
              <Button variant='contained' size='large'>
                <Link to={`/elevations/edit/${id}`}>Edit Elevation</Link>
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <form onSubmit={handleSubmit}>
        <Paper className={classes.pageContent} style={{ marginTop: '10px' }}>
          {/* <Grid container spacing={2}>
          <Grid item xs={6}>
            <Controls.Input
              name="no_of_elevations"
              label="No. of elevations to add"
              value={values.no_of_elevations}
              type="text"
              
              onChange={handleInputChange}
            />  
          </Grid>
          <Grid item xs={6} style={{marginTop: '10px'}}>
          <Button variant="contained" color="primary" size="large" type="button" onClick={addElevationGrid}>
            +
          </Button>
          </Grid>
        </Grid> */}

          <Grid container>
            {formValues.map((formValue, index) => (
              <Grid key={index} item container xs={3}>
                <Grid item container>
                  <Grid item>
                    <Card
                      onClick={
                        requestType === 'view'
                          ? null
                          : () => handleClickOpen(index)
                      }
                      style={{
                        minWidth: 180,
                        minHeight: 260,
                        textAlign: 'center',
                      }}
                    >
                      <CardActionArea>
                        {selectedImg[index]['imagePath'] ? (
                          <CardMedia
                            component='img'
                            // className={classes.media}
                            style={{ height: '100%' }}
                            image={
                              Config.baseURL + selectedImg[index]['imagePath']
                            }
                            title={
                              stringLastElement(
                                selectedImg[index]['imagePath'],
                                '-'
                              ).split('.')[0]
                            }
                          />
                        ) : (
                          <CardContent>
                            <Typography>
                              Add/Display Elevation Image 1
                            </Typography>
                          </CardContent>
                        )}
                      </CardActionArea>
                    </Card>
                  </Grid>
                  <Grid item>
                    <Card
                      style={{
                        minWidth: 220,
                        minHeight: 50,
                        backgroundColor: 'lightgray',
                        marginTop: '15px',
                        textAlign: 'center',
                      }}
                    >
                      <TextField
                        label='Elavation name'
                        value={formValue.name}
                        name='name'
                        variant='outlined'
                        onChange={(e) => handleInputChange(index, e)}
                        inputProps={{
                          readOnly: requestType === 'view' ? true : false,
                        }}
                      />
                    </Card>
                  </Grid>
                  {/* <Grid item container style={{ marginTop: '10px' }}>
                    <Grid item xs={6}>
                      <Typography>No. of floor plans</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        label=''
                        name='no_of_floor_plans'
                        value={formValue.no_of_floor_plans}
                        variant='outlined'
                        type='number'
                        onChange={(e) => handleInputChange(index, e)}
                        inputProps={{
                          readOnly: requestType === 'view' ? true : false,
                        }}
                      />
                    </Grid>
                  </Grid> */}
                  {requestType === 'edit' ? (
                    <Grid item container style={{ marginTop: '10px' }}>
                      <Grid item xs={6}>
                        <IconButton onClick={(e) => handleRemoveGrid(e, index)}>
                          <Remove />
                        </IconButton>
                      </Grid>
                      <Grid item xs={6}>
                        <IconButton onClick={() => handleAddGrid()}>
                          <Add />
                        </IconButton>
                      </Grid>
                    </Grid>
                  ) : (
                    ''
                  )}
                </Grid>
              </Grid>
            ))}
          </Grid>
          <Grid container spacing={2} style={{ marginTop: '10px' }}>
            <Grid item>
              <Button
                variant='contained'
                color='primary'
                type='submit'
                onClick={handleSubmit}
                disabled={requestType === 'view' ?? true}
              >
                Save
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant='contained'
                color='primary'
                type='button'
                disabled={requestType === 'view' ?? true}
              >
                Clear
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </form>
      {modalDialog}
    </div>
  );
};

export default ElevationsForm;
