import api from '../hooks/Api';

const service = 'flooroptions';
export const getFloorOptions = async () => {
  try {
    const { data } = await api.get(`/${service}`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const getSingleFloorOption = async (id) => {
  try {
    const { data } = await api.get(`/${service}/${id}`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const getOptionsByFloorPlan = async (floorPlanId) => {
  try {
    const { data } = await api.get(`/${service}/floorplan/${floorPlanId}`);
    return data;
  } catch (error) {
    throw error;
  }
};
// floorOptionCoordinates

export const updateFloorOptionCoordinates = async (req) => {
  try {
    const response = await api.patch(
      `/${service}/floorOptionCoordinates/update`,
      req
    );
    return response;
  } catch (error) {
    throw error;
  }
};
