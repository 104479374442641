import Api from '../hooks/Api';
const service = 'communities';

export const getCommunitiesByHomebuilder = async (homebuilderId) => {
  try {
    const response = await Api.get(`/${service}/homebuilder/${homebuilderId}`);
    return response;
  } catch (error) {
    throw error;
  }
};
