import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import { getSingleFloorPlan } from '../services/floorPlansService';
import {
  getOptionsByFloorPlan,
  updateFloorOptionCoordinates,
} from '../services/floorOptionsService';
import {
  Grid,
  Paper,
  makeStyles,
  List,
  ListItem,
  ListItemText,
  Typography,
  Divider,
  Button,
  ImageList,
  ImageListItem,
} from '@material-ui/core';
import Draggable from 'react-draggable';
import Config from '../config';
import { stringLastElement } from '../helpers/helpers';
import $ from 'jquery';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiImageListItem-root': {
      width: 'auto',
      height: 'auto',
    },
  },
  container: {
    display: 'grid',
    gridTemplateColumns: 'repeat(12, 1fr)',
    gridGap: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    whiteSpace: 'nowrap',
    marginBottom: theme.spacing(1),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  imgListItem: {
    width: 'auto',
    height: 'auto',
  },
}));

const OptionsCoordinates = () => {
  const classes = useStyles();
  const { id: floorPlanId } = useParams();
  const [floorPlan, setFloorPlan] = useState(null);
  const [floorOptions, setFloorOptions] = useState(null);
  const [coordinates, setCoordinates] = useState({});
  const [positions, setPositions] = useState({});
  const [hasLoaded, setHasLoaded] = useState(false);
  const [frameLeft, setFrameLeft] = useState(0);
  const [frameTop, setFrameTop] = useState(0);
  const [success, setSuccess] = useState(null);
  const frameRef = React.useRef(null);
  const elementRef = React.useRef(null);
  useEffect(() => {
    const singleFloorPlan = async () => {
      const floorPlanData = await getSingleFloorPlan(floorPlanId);
      // console.log('floor Plan : ', floorPlanData);
      if (floorPlanData) setFloorPlan(floorPlanData);
    };

    let dummyPositions = {};

    const getFloorOptions = async () => {
      const floorOptionsData = await getOptionsByFloorPlan(floorPlanId);

      if (floorOptionsData) {
        setFloorOptions(floorOptionsData);

        //   for(const floorOption in floorOptionsData){

        //   }
        floorOptionsData.forEach((floorOption) => {
          const itemId = floorOption._id;
          dummyPositions[itemId] = {};
          dummyPositions[itemId]['edited'] = false;
          dummyPositions[itemId]['plan'] = floorOption.planCoordinate;
          dummyPositions[itemId]['label'] = floorOption.labelCoordinate;
        });
        // if (frameRef.current != null) {
        //   const frameRect = frameRef.current;
        //   console.log('frame left: ', frameRect.offsetLeft);
        //   console.log('frame top: ', frameRect.offsetTop);
        //   setFrameLeft(frameRect.offsetLeft);
        //   setFrameTop(frameRect.offsetTop);
        //   console.log('frame left: ', frameRect);
        // }
        console.log('dummy positions: ', dummyPositions);
        setPositions(dummyPositions);
        // const response = await updateFloorOptionCoordinates(positions);
        setHasLoaded(true);
        console.log('position at refresh: ', positions);
        //   setPositions(floorOptionsData);
      }
    };

    // const existingDivPositions = JSON.parse(
    //     localStorage.getItem('positions_div')
    //   );

    // console.log('existing positions: ', dummyPositions);
    // console.log('has loaded');
    getFloorOptions();
    singleFloorPlan();
  }, []);
  let coords = [];
  let dummyPositions = { ...positions };
  const handleStop = async (e, data) => {
    const frameoffset = document.getElementById('frame');

    // console.log('data: ', data);

    const id = e.target.id;
    const itemId = stringLastElement(id, '-');

    const ele = document.querySelector('#' + id).getBoundingClientRect();
    const toppx = $('#' + id).offset().top - $('#frame').offset().top;
    const leftpx = $('#' + id).offset().left - $('#frame').offset().left;
    // console.log('dragged element position: ', { x: leftpx, y: toppx });

    console.log('frame: ', frameRef.current.getBoundingClientRect());
    // console.log({
    //   x: data.x - frameoffset.offsetLeft,
    //   y: data.y - frameoffset.offsetTop,
    // });

    coords.push({
      [itemId]: {
        x: ele.left - frameoffset.offsetLeft,
        y: ele.top - frameoffset.offsetTop,
      },
    });
    setCoordinates((prev) => [...coords]);

    dummyPositions[itemId] = {};
    let positionCoords = {};
    positionCoords[itemId] = {};
    dummyPositions[itemId]['x'] = leftpx;
    dummyPositions[itemId]['y'] = toppx;

    // positionCoords[itemId]['x'] = 0;
    // positionCoords[itemId]['y'] = 0;
    // console.log('frameoffeset', frameRef);
    // try {
    //   positionCoords[itemId]['x'] = ele.left - frameoffset.offsetLeft;
    //   positionCoords[itemId]['y'] = ele.top - frameoffset.offsetTop;
    //   // const response = await updateFloorOptionCoordinates(positionCoords);
    //   // console.log('res data: ', response.data);
    // } catch (error) {
    //   // throw error;
    //   console.log(error);
    // }

    setPositions('dummy: ', dummyPositions);
    console.log('new positions:', positions);
    // console.log('frame offset top:', frameoffset.offsetTop);
    // console.log('element: ', coords);
    // console.log('positions: ', coordinates);
  };

  const hanndleCoords = async (e, data) => {
    console.log('pre positions:', positions);
    var itemlist = { ...positions };

    console.log('item list: ', itemlist);

    var toppx;

    var leftpx;
    const itemId = stringLastElement(e.target.id, '-');
    const type = e.target.dataset.type;
    console.log('type: ', type);

    toppx = $('#' + e.target.id).offset().top - $('#frame').offset().top;

    leftpx = $('#' + e.target.id).offset().left - $('#frame').offset().left;

    itemlist[itemId][type] = { x: leftpx, y: toppx };
    itemlist[itemId]['edited'] = true;
    // itemlist[itemId][type]['x'] = leftpx;
    // itemlist[itemId][type]['y'] = toppx;
    console.log('wth: ', itemlist);
    // setPositions(itemlist);
    // for (const item in itemlist) {
    //   if (itemlist[item][type]['x'] === 0 && itemlist[item][type]['y'] === 0) {
    //     delete itemlist[item];
    //     console.log('deleted');
    //   }
    // }
    setPositions(itemlist);
    // $('.option-drag-img').each(function () {
    //   toppx =
    //     $('#' + $(this).attr('id')).offset().top - $('#frame').offset().top;

    //   leftpx =
    //     $('#' + $(this).attr('id')).offset().left - $('#frame').offset().left;
    //   const itemId = stringLastElement($(this).attr('id'), '-');
    //   if (positions[stringLastElement($(this).attr('id'), '-')]['x'] < 0) {
    //     console.log(
    //       'negative left: ',
    //       positions[stringLastElement($(this).attr('id'), '-')]['x']
    //     );
    //     itemlist[itemId] = {};
    //     itemlist[itemId]['x'] = leftpx;
    //     itemlist[itemId]['y'] = toppx;
    //   } else {
    //     // if (
    //     //   positions[stringLastElement($(this).attr('id'), '-')]['x'] ===
    //     //     leftpx &&
    //     //   positions[stringLastElement($(this).attr('id'), '-')]['y'] ===
    //     //     toppx
    //     // ) {
    //     //   itemlist[itemId] =
    //     //     positions[stringLastElement($(this).attr('id'), '-')];
    //     // } else {
    //     //   itemlist[itemId] = {};
    //     //   itemlist[itemId]['x'] = leftpx;
    //     //   itemlist[itemId]['y'] = toppx;
    //     // }
    //     itemlist[itemId] =
    //       positions[stringLastElement($(this).attr('id'), '-')];
    //   }

    //   // itemlist =
    //   //   itemlist + [$(this).attr('id')] + ' x :' + toppx + ' y:' + leftpx;
    // });

    // console.log('newpos', itemlist);

    // console.log(positions);
  };

  const saveCords = async () => {
    console.log('edited positions: ', positions);
    let itemlist = { ...positions };
    for (const item in itemlist) {
      if (itemlist[item]['edited'] === false) {
        delete itemlist[item];
        console.log('deleted');
      } else {
        itemlist[item]['coordinated'] = true;
        delete itemlist[item]['edited'];
      }
    }
    console.log('submited positions: ', itemlist);

    // const response = await updateFloorOptionCoordinates(itemlist);
    // console.log('res status: ', response.status);
    // if (response.status === 200) {
    //   setSuccess('Coordinates saved successfully!');
    // }
  };
  return (
    <Paper className={classes.paper}>
      <Grid container spacing={3}>
        <Grid item xs={3}>
          <Button variant='contained' onClick={saveCords}>
            Save Coordinates
          </Button>
        </Grid>
        <Grid item xs={3}>
          {success !== '' && (
            <Typography style={{ color: 'red' }} component='p'>
              {success}
            </Typography>
          )}
        </Grid>
        <Grid item xs={3}></Grid>
        <Grid item xs={3}>
          <Button variant='contained'>
            <Link to={`/previewoptions/${floorPlanId}`} target='_blank'>
              Preview Options
            </Link>
          </Button>
        </Grid>
        <Grid item xs={3}>
          {/* <Paper className={classes.paper}> */}
          <Typography>Drag and drop floor options</Typography>
          {floorOptions !== null && (
            <List
              sx={{
                width: '100%',
                maxWidth: 360,
                bgcolor: 'background.paper',
              }}
            >
              {Object.keys(positions).length !== 0 &&
                floorOptions.map(
                  (floorOption, index) =>
                    !floorOption.coordinated && (
                      <>
                        <Divider component='li' />
                        <ListItem>
                          <ListItemText
                            primary={
                              <Draggable onStop={hanndleCoords}>
                                <div
                                  // id={`label-drag-${floorOption._id}`}
                                  // data-type='label'
                                  className='labelDragElement'
                                  // ref={elementRef}
                                  style={{
                                    position: 'relative',
                                    display: 'inline-block',
                                    zIndex: '3',
                                  }}
                                >
                                  <Typography
                                    id={`label-drag-${floorOption._id}`}
                                    data-type='label'
                                    component='div'
                                    variant='caption'
                                  >
                                    {floorOption.name}
                                  </Typography>
                                </div>
                              </Draggable>
                            }
                            secondary={
                              <>
                                <Draggable
                                  // defaultPosition={
                                  //   positions === null
                                  //     ? { x: 0, y: 0 }
                                  //     : !positions[floorOption._id]
                                  //     ? { x: 0, y: 0 }
                                  //     : {
                                  //         x: positions[floorOption._id].x,
                                  //         y: positions[floorOption._id].y,
                                  //       }
                                  // }
                                  // position={floorOption.planCoordinate}
                                  // onStart={Draggable.handleStart}
                                  // onDrag={Draggable.handleDrag}

                                  onStop={hanndleCoords}
                                >
                                  <div
                                    // id={`option-drag-${floorOption._id}`}
                                    // data-type='plan'
                                    className='optionDragElement'
                                    ref={elementRef}
                                    style={{
                                      position: 'relative',
                                      display: 'inline-block',
                                      zIndex: '2',
                                    }}
                                  >
                                    <img
                                      data-type='plan'
                                      id={`option-drag-img-${floorOption._id}`}
                                      className='option-drag-img'
                                      src={
                                        Config.baseURL + floorOption.imagePath
                                      }
                                      alt='floorplan'
                                    />
                                  </div>
                                </Draggable>
                              </>
                            }
                          />
                        </ListItem>{' '}
                      </>
                    )
                )}
            </List>
          )}
          {/* </Paper> */}
        </Grid>
        <Grid item xs={9}>
          {/* <Paper className={classes.paper} style={{ height: '1280px' }}> */}
          {floorPlan !== null && (
            <div
              ref={frameRef}
              id='frame'
              style={{
                position: 'relative',
                display: 'inline-block',
                zIndex: '1',
              }}
            >
              <img src={Config.baseURL + floorPlan.imagePath} alt='floorplan' />

              {Object.keys(positions).length !== 0 &&
                floorOptions.map(
                  (floorOption, index) =>
                    floorOption.coordinated && (
                      <>
                        <Draggable onStop={hanndleCoords}>
                          <span
                            className='optionDragElement'
                            style={{
                              position: 'absolute',
                              zIndex: '2',
                              left: positions[floorOption._id]['plan']['x'],
                              top: positions[floorOption._id]['plan']['y'],
                            }}
                          >
                            <img
                              id={`option-drag-${floorOption._id}`}
                              data-type='label'
                              id={`option-drag-img-${floorOption._id}`}
                              src={Config.baseURL + floorOption.imagePath}
                              alt='floorplan'
                            />
                          </span>
                        </Draggable>
                        <Draggable onStop={hanndleCoords}>
                          <div
                            // id={`label-drag-${floorOption._id}`}
                            // data-type='label'
                            // ref={elementRef}
                            className='labelDragElement'
                            style={{
                              position: 'absolute',
                              zIndex: '3',
                              left: positions[floorOption._id]['label']['x'],
                              top: positions[floorOption._id]['label']['y'],
                            }}
                          >
                            <Typography
                              id={`label-drag-${floorOption._id}`}
                              data-type='label'
                              component='div'
                              variant='caption'
                            >
                              {floorOption.name}
                            </Typography>
                          </div>
                        </Draggable>
                      </>
                    )
                )}
            </div>
          )}
          {/* </Paper> */}
        </Grid>
      </Grid>
    </Paper>
  );
};

export default OptionsCoordinates;
