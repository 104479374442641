import React, { useState } from 'react';
import useFileManager from '../hooks/files/useFileManager';

import styled from 'styled-components';
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  makeStyles,
  Paper,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import Config from '../config';

const ImgGrid = styled.div`
  margin: 20px auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 40px;
`;

const usesStyles = makeStyles((theme) => ({
  card: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
}));

const ImageGrid = () => {
  const classes = usesStyles();
  const [refresh, setRefresh] = useState(false);
  const [request, setRequest] = useState('fetch');
  const [param, setParam] = useState(null);
  // here useFileManager hook first arguments is request that can be fetch, delete or update as value
  // param will be null when request is fetch else param will be a
  // parameter on which delete or update of a file will be executed
  // refresh and setRefresh are state variable by which we change state of page and refresh it
  const { docs } = useFileManager(request, param, refresh, setRefresh);

  const [open, setOpen] = useState(false);
  const [modalContent, setModalContent] = React.useState('');
  const [selectedFile, setSelectedFile] = React.useState({});

  const handleClickOpen = (doc) => {
    setOpen(true);
    setSelectedFile(doc);
    setModalContent(
      <Paper style={{ width: '320px', height: '240px' }}>
        <img src={`http://localhost:5000/${doc.path}`} alt={doc.name} />
      </Paper>
    );
  };
  const handleClose = () => {
    setOpen(false);
    setModalContent('');
  };

  function deleteDoc() {
    setRequest('delete');
    setParam(selectedFile._id);
    setOpen(false);
    setModalContent('');
  }

  const ModalDialog = (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth='md'
        onClose={handleClose}
        aria-labelledby='customized-dialog-title'
        open={open}
      >
        <DialogTitle id='customized-dialog-title' onClose={handleClose}>
          Modal title
        </DialogTitle>
        <DialogContent dividers>{modalContent}</DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color='primary'>
            Save changes
          </Button>
          <Button variant='contained' onClick={deleteDoc}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );

  return (
    <ImgGrid>
      {docs &&
        docs.map((doc, index) => (
          <Card
            className={classes.card}
            onClick={() => handleClickOpen(doc)}
            key={index}
          >
            <CardActionArea>
              <CardMedia
                component='img'
                className={classes.media}
                src={Config.baseURL + doc.path}
                title={doc.name}
              />
              <CardContent>
                <Typography>{doc.name}</Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        ))}
      {ModalDialog}
      {/* <ModalDialog open={open} handleClickOpen={handleClickOpen} handleClose={handleClose} content={modalContent} imgId={imgId} deleteDoc={deleteDoc} /> */}
    </ImgGrid>
  );
};

export default ImageGrid;
