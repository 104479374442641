import React from 'react';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { CssBaseline } from '@material-ui/core';
import MaterialNavbar from './components/materialNavbar';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#fffff',
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <MaterialNavbar />
      <CssBaseline />
    </ThemeProvider>
  );
}

export default App;
