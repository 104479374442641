import React from 'react';
import {
  Paper,
  Grid,
  Typography,
  FormControl,
  FormLabel,
  Select,
  MenuItem,
  Button,
  Dialog,
  AppBar,
  Toolbar,
} from '@mui/material';
import { makeStyles, Theme, createTheme } from '@material-ui/core/styles';
import $ from 'jquery';
import { getCommunitiesByHomebuilder } from '../services/communitiesService';
import { getHomesByCommunities } from '../services/homesService';
import { getSingleHomebuilder } from '../services/homebuildersService';
import useFurnitures from '../hooks/useFurnitures';
import { getElevationsByHome } from '../services/elevationsService';
import Config from '../config';
import { getFloorPlansByHome } from '../services/floorPlansService';
import {
  getFloorOptions,
  getOptionsByFloorPlan,
} from '../services/floorOptionsService';
import Optionaccordian from './optionaccordian';
import {
  Stage,
  Layer,
  Text,
  Image,
  Transformer,
  Rect,
  Circle,
} from 'react-konva';
import useImage from 'use-image';

import IfpTabsNew from '../hooks/IfpTabsNew';
import Optionaccordiannew from './optionaccordian';
import URLImage from './URLImage';
import html2canvas from 'html2canvas';
import Printfloorplan from './printfloorplan';
import TrText from './TrText';

const theme = createTheme();

// Widget styles
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    margin: 'auto',
  },
  image: {
    width: 260,
    height: 260,
  },
  panelHeader: {
    display: 'flex',
    alignItems: 'center',
    height: 41,
    paddingLeft: theme.spacing(3),
    backgroundColor: '#45507f',
    color: '#fff',
  },
  img: {
    display: 'block',
    maxWidth: '100%',
    overflow: 'hidden',
  },
  disinlinebox: {
    display: '-webkit-inline-box',
  },
}));
const IfpWidgetOptimised = ({ domElement }) => {
  // widget styles as constant to use withing component
  const classes = useStyles();
  // getting homebuilderId from dom through widget script properties
  const homebuilderId = domElement.props.data;

  // state to store and change communities data
  const [communities, setCommunities] = React.useState([]);

  // state to store and change homes data
  const [homes, setHomes] = React.useState([]);

  // state to store and change elevations data
  const [elevations, setElevations] = React.useState([]);

  // state to store and change floorplans data
  const [floorPlans, setFloorPlans] = React.useState([]);

  const [floorPlansLoaded, setFloorPlansLoaded] = React.useState(false);

  // state to store and change floor options data
  const [floorOptions, setFloorOptions] = React.useState([]);

  const [floorOptionsLoaded, setFloorOptionsLoaded] = React.useState(false);

  const [positions, setPositions] = React.useState([]);

  // state to store and change floor options data
  const { furnitures } = useFurnitures();

  const [framecolwidth1, Setframecolwidth] = React.useState();
  const [framecolheight1, Setframecolheight] = React.useState();
  const [defaultcheck, setDefaultcheck] = React.useState({});

  const [error, setError] = React.useState(null);

  const [isLoading, setIsLoading] = React.useState(true);

  // state to store and change selected values from different inputs of widget data
  const [selectedValues, setSelectedValues] = React.useState({
    homebuilder: '',
    community: '',
    home: '',
    elevation: '',
    floorPlan: '',
    floorOption: [],
    canvasImages: [],
  });
  const [canvasimg, setCanvasimg] = React.useState([]);
  const [selectedTabIndex, setSelectedTabIndex] = React.useState(0);
  const [inactiveElements, setInactiveElements] = React.useState([]);

  const dragImageUrl = React.useRef();
  const stageRef = React.useRef();
  const tabRef = React.useRef(null);

  const [images, setImages] = React.useState([]);
  const [selectedId, selectShape] = React.useState(null);
  const [note, setNote] = React.useState('');
  const [draggedNotes, setDraggedNotes] = React.useState([]);

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setCanvasimg([]);
    setOpen(false);
  };

  //reffering floor plan svg image
  const svgRef = React.useRef(null);

  function framecolwidth() {
    setTimeout(() => {
      var a = $('.framecol').outerWidth();
      Setframecolwidth(a - 13);
    }, 1000);

    // return document.getElementsByClassName('framecol')[0].clientWidth;
  }
  function framecolheight() {
    setTimeout(() => {
      var a = $('.framecol').outerHeight();
      Setframecolheight(a - 100);
    }, 1000);
    // return document.getElementsByClassName('framecol')[0].clientHeight;
  }

  // homebuilder service
  const homebuilder = async () => {
    const homebuilder = await getSingleHomebuilder(homebuilderId);
    if (homebuilder.status === 200) {
      // set selected homebuilderId with persistance of other values in the object
      setSelectedValues({
        ...selectedValues,
        homebuilder: homebuilder.data,
      });
      setError(null);
    } else {
      setError('Homebuilders could not be loaded!');
    }
  };

  // communities service
  const getCommunities = async () => {
    const response = await getCommunitiesByHomebuilder(homebuilderId);
    if (response.status === 200) {
      setCommunities(response.data);
      setError(null);
    } else {
      setError('Communities could not be loaded!');
    }
  };

  // homes service
  const getHomes = async () => {
    const response = await getHomesByCommunities(selectedValues.community._id);
    if (response.status === 200) {
      setHomes(response.data);
      setError(null);
      setIsLoading(false);
    } else {
      setError('Homes could not be loaded!');
    }
  };

  // elevations service
  const getElevations = async () => {
    const response = await getElevationsByHome(selectedValues.home._id);
    if (response.status === 200) {
      setElevations(response.data);
      setSelectedValues({
        ...selectedValues,
        elevation: response['data'][0],
      });
      setError(null);
      console.log('selected elevation: ', selectedValues.elevation);
    } else {
      setError('Elevations could not be loaded!');
    }
  };
  let dummyPositions = { ...positions };
  let defaultInactive = {};

  // floorPlans service
  const getFloorPlans = async () => {
    const response = await getFloorPlansByHome(selectedValues.home._id);
    if (response.status === 200) {
      setFloorPlans(response.data);
      const firstFloorPlanId = response['data'][0]['_id'];

      // setSelectedValues({
      //   ...selectedValues,
      //   floorPlan: firstFloorPlanId,
      // });
      // console.log('selected floorplan: ', selectedValues.floorPlan);

      setError(null);
      setFloorPlansLoaded(!floorPlansLoaded);
      framecolwidth();
      framecolheight();
      console.log('the helll: ', floorOptions);
    } else {
      setError('Floorplans could not be loaded!');
    }
  };

  // floorOptions service
  const getFloorOptions = async () => {
    // console.log('floor -plasn: ', floorPlans);
    let newFloorOptions = [];
    const newArr = [];
    const newObj = {};
    const newInactiveElements = inactiveElements;

    for (let i = 0; i < floorPlans.length; i++) {
      newArr[i] = [];
      newObj[i] = {};
      const response = await getOptionsByFloorPlan(floorPlans[i]['_id']);

      if (response) {
        const floorOptionsData = response;

        newFloorOptions[i] = floorOptionsData;
        newInactiveElements[i] = [];
        floorOptionsData.forEach((floorOption) => {
          const itemId = floorOption._id;
          dummyPositions[itemId] = {};
          dummyPositions[itemId]['plan'] = floorOption.planCoordinate;
          dummyPositions[itemId]['label'] = floorOption.labelCoordinate;
          defaultInactive[itemId] = floorOption.defaultInactive;
          newArr[i].push(floorOption.defaultInactive);
          newObj[i][floorOption._id] = {};
          newObj[i][floorOption._id] = !floorOption.defaultInactive;
          newInactiveElements[i].push(floorOption.inactiveElements);
        });
      } else {
        newFloorOptions[i] = [];
      }
    }

    if (newFloorOptions.length > 0) {
      setFloorOptions(newFloorOptions);
      // setSelectedValues({
      //   ...selectedValues,
      //   floorOption: newFloorOptions[0],
      // });

      setPositions(dummyPositions);
      setFloorOptions(newFloorOptions);

      setDefaultcheck(defaultInactive);
      console.log('default checked: ', defaultcheck);

      setInactiveElements(newInactiveElements);
      setError(null);
      setFloorPlansLoaded(!floorOptionsLoaded);
    } else {
      setError('Floor Options could not be loaded!');
    }
  };

  // get singgle homebuilder object
  React.useEffect(() => {
    homebuilder();
  }, []);

  // get all communities of the homebuilder id if default community data not available
  React.useEffect(() => {
    getCommunities();
  }, [homebuilderId]);

  // get all homes of the community id if default home data not available
  React.useEffect(() => {
    getHomes();
  }, [selectedValues.community]);

  // get all elevations of the home id
  React.useEffect(() => {
    getElevations();
    getFloorPlans();
  }, [selectedValues.home]);

  React.useEffect(() => {
    if (floorPlans.length > 0) {
      // setSelectedValues({
      //   ...selectedValues,
      //   floorPlan: floorPlans[0]['_id'],
      // });
      getFloorOptions();

      setIsLoading(false);
    }
  }, [floorPlans]);

  React.useEffect(() => {
    framecolwidth();
    framecolheight();
  }, [selectedTabIndex]);

  // get all homes of the selected community id if default home data not available

  // get all elevations
  // get all floorplans
  // get all flooroptions of all floorplans
  // get all furnitures

  // Handling change of input values like dropdown
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSelectedValues(
      (prev) =>
        (prev = {
          ...selectedValues,
          [name]: value,
        })
    );
  };

  const zoomIn = () => {
    if ($('.zoom').hasClass('zoomin')) {
      $('.zoom').removeClass('zoomin');
      $('.zoom, .konvajs-content canvas').css('transform', 'scale(1.1)');
    } else {
      $('.zoom').addClass('zoomin');
      $('.zoom, .konvajs-content canvas').css('transform', 'scale(1.1)');
    }
  };

  const zoomOut = (level) => {
    if ($('.zoom').hasClass('zoomin')) {
      $('.zoom').removeClass('zoomin');
      $('.zoom, .konvajs-content canvas').css('transform', 'scale(1)');
    } else {
      $('.zoom').addClass('zoomin');
      $('.zoom, .konvajs-content canvas').css('transform', 'scale(1)');
    }
  };

  const transform = () => {
    if ($('.frame').hasClass('rotated')) {
      $('.frame').removeClass('rotated');
      $('.frame, .konvajs-content, .framecanvas').css(
        'transform',
        'rotateY(0deg)'
      );
      $('.option-lable div').css('transform', 'rotateY(0deg)');
    } else {
      $('.frame').addClass('rotated');
      $('.frame, .konvajs-content, .framecanvas').css(
        'transform',
        'rotateY(190deg)'
      );
      $('.option-lable div').css('transform', 'rotateY(190deg)');
    }
  };

  const reset = () => {
    $('.frame, .konvajs-content, .zoom, .konvajs-content canvas').css(
      'transform',
      'none'
    );
  };

  const Downloadpdf = () => {
    // <Printfloorplan floorOption={floorOptions}/>
    // window.open('/printfloorplan', '_blank');
    setFloorOptions([...floorOptions]);

    $('.framecanvas').css('display', 'inline-block');
    let imgarr = [];
    let canigg = [];
    let i = 0;
    $('.framecanvas').map(function () {
      var frameid = $(this).attr('id');
      var dataid = $(this).attr('data-id');
      // console.log('asd',dataid)

      canigg = html2canvas(document.getElementById(frameid), {
        useCORS: true,
      })
        .then(function (canvas) {
          var img = { token: canvas.toDataURL('image/jpeg,1.0') };
          // console.log(img.token);
          // alert(img.token);
          // return img.token
          // imgarr.push(img.token);
          //  setCanvasimg(prev => [...prev, img.token])
          // const canvasUrl = canvas.toDataURL();
          // return canvasUrl;img
          return img.token;
        })
        .then((res) => {
          if (res) {
            imgarr.push(res);
            setCanvasimg((prev) => [...prev, res]);
            setSelectedValues((prev) => {
              prev['canvasImages'].push(res);
              return prev;
            });
          }
        });
    });
    console.log('imgarr', imgarr);

    console.log('Selected Values before download: ', selectedValues);
    $('.framecanvas').css('display', 'none');
    setOpen(true);
  };

  const checkDeselect = (e) => {
    // deselect when clicked on empty area
    const clickedOnEmpty = e.target === e.target.getStage();
    if (clickedOnEmpty) {
      selectShape(null);
    }
  };

  const unSelectShape = (prop) => {
    selectShape(prop);
  };

  const onDeleteImage = (node) => {
    const newImages = [...images];
    newImages.splice(node.index, 1);
    setImages(newImages);
  };

  const handleExport = () => {
    console.log('stage ref: ', tabRef);

    html2canvas(document.getElementById(`canvas-parent-0`), {
      useCORS: true,
    }).then(function (canvas) {
      try {
        console.log(`canvas 0: `, canvas.toDataURL());
      } catch (error) {
        console.log('canvas error: ', error);
      }
    });

    floorPlans.map((o, i) => {
      if (i !== selectedTabIndex) {
        tabRef.current.click();
      }
      console.log('canvas index: ', i);

      html2canvas(document.getElementById(`canvas-parent-${i}`), {
        useCORS: true,
      }).then(function (canvas) {
        try {
          console.log(`canvas ${i}: `, canvas.toDataURL());
        } catch (error) {
          console.log('canvas error: ', error);
        }
      });
      // }
    });
  };

  const tabContent = (index) => (
    <Grid
      item
      style={{
        width: '100%',
        textAlign: 'center',
        padding: '5px',
      }}
    >
      <div
        id={`canvas-parent-${selectedTabIndex}`}
        className='canvas-parent'
        style={{
          zIndex: '1',
          position: 'relative',
          background: '#f8f8f8',
        }}
        onDrop={(e) => {
          e.preventDefault();
          // register event position

          stageRef.current.setPointersPositions(e);
          // images[index] = [];
          // add image
          if (dragImageUrl.current !== '') {
            setImages(
              images.concat([
                {
                  ...stageRef.current.getPointerPosition(e),
                  src: dragImageUrl.current,
                  id: Math.random() * 100,
                  style: {},
                  tabId: index,
                },
              ])
            );
            dragImageUrl.current = '';
          }
          if (note !== '') {
            // draggedNotes[index] = [];
            setDraggedNotes(
              draggedNotes.concat([
                {
                  ...stageRef.current.getPointerPosition(e),
                  text: note,
                  id: Math.random() * 100,
                  style: {},
                  tabId: index,
                },
              ])
            );
            setNote('');
          }
        }}
        onDragOver={(e) => {
          e.preventDefault();
          // console.log('current index', index);
          console.log('dragged images: ', images);
        }}
        crossOrigin='Anonymous'
      >
        <Stage
          id={`konvaStage${index}`}
          className={`konvaClass${index}`}
          crossOrigin='Anonymous'
          width={framecolwidth1}
          height={framecolheight1}
          style={{
            // width:'100%',
            // height:'100%',
            zIndex: '9999',
            position: 'absolute',
            border: '1px solid #d8d8d8',
          }}
          onMouseDown={checkDeselect}
          onTouchStart={checkDeselect}
          ref={stageRef}
        >
          <Layer crossOrigin='Anonymous' className='konvaLayer'>
            {images.map((image, i) => {
              return (
                image.tabId === index && (
                  <URLImage
                    key={i}
                    shapeProps={image}
                    isSelected={image.id === selectedId}
                    onSelect={() => {
                      selectShape(image.id);
                    }}
                    onChange={(newAttrs) => {
                      console.log('new attr: ', newAttrs);
                      const rects = images.slice();
                      rects[i] = newAttrs;
                      setImages(rects);
                    }}
                    image={image}
                    unSelectShape={unSelectShape}
                    onDelete={onDeleteImage}
                  />
                )
              );
            })}

            {draggedNotes.map((draggedNote, i) => {
              return (
                draggedNote.tabId === index && (
                  <TrText
                    key={i}
                    shapeProps={draggedNote}
                    isSelected={draggedNote.id === selectedId}
                    onSelect={() => {
                      selectShape(draggedNote.id);
                    }}
                    onChange={(newAttrs) => {
                      const rects = draggedNotes.slice();
                      rects[i] = newAttrs;
                      setDraggedNotes(rects);
                    }}
                  />
                )
              );
            })}
          </Layer>
        </Stage>

        <div
          id={`frame${index}`}
          className='frame'
          style={{
            position: 'relative',
            display: 'inline-block',
            zIndex: '1',
          }}
        >
          <div className='zoom'>
            <img
              ref={svgRef}
              src={Config.baseURL + floorPlans[selectedTabIndex]['imagePath']}
              alt='floorplan'
              id='fp'
            />
            {Object.keys(positions).length !== 0 &&
              floorOptions[index].map((floorOption, index) => {
                return (
                  <div key={floorOption._id}>
                    <span
                      id={`option-drag-${floorOption._id}`}
                      className='item'
                      style={{
                        position: 'absolute',
                        zIndex: '2',
                        left: positions[floorOption._id]['plan']['x'],
                        top: positions[floorOption._id]['plan']['y'],
                        visibility: floorOption.defaultInactive
                          ? 'hidden'
                          : 'visible',
                      }}
                    >
                      <img
                        id={`option-drag-img-${floorOption._id}`}
                        src={Config.baseURL + floorOption.imagePath}
                        alt='floorplan'
                      />
                    </span>
                    <span className='option-lable'>
                      <Typography
                        id={`label-drag-${floorOption._id}`}
                        className='labelDragElement'
                        data-type='label'
                        component='div'
                        variant='caption'
                        style={{
                          position: 'absolute',
                          zIndex: '3',
                          left: positions[floorOption._id]['label']['x'],
                          top: positions[floorOption._id]['label']['y'],
                          visibility: floorOption.defaultInactive
                            ? 'hidden'
                            : 'visible',
                        }}
                      >
                        {floorOption.name}
                      </Typography>
                    </span>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </Grid>
  );

  return (
    <div className='reddit_widget__app'>
      <Paper className={classes.paper}>
        {error === null ? (
          <>
            {/* Widget main grid start */}
            <Grid container spacing={2}>
              <Grid item>
                <Typography variant='h5'>
                  {selectedValues.homebuilder.name}
                </Typography>
              </Grid>
              <Grid item container spacing={2}>
                <Grid item>
                  <FormControl variant='outlined'>
                    <FormLabel>Select Community</FormLabel>
                    <Select
                      name='community'
                      value={selectedValues.community}
                      label='community'
                      onChange={handleInputChange}
                    >
                      {communities.map((community, index) => {
                        return (
                          <MenuItem key={community._id} value={community}>
                            {community.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl variant='outlined'>
                    <FormLabel>Select Home </FormLabel>
                    <Select
                      name='home'
                      value={selectedValues.home}
                      label='home'
                      onChange={handleInputChange}
                      // inputProps={{
                      //   readOnly: requestType === 'view' ? true : false,
                      // }}
                    >
                      {homes.map((home, index) => {
                        return (
                          <MenuItem key={home._id} value={home}>
                            {home.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            {/* Main widget start */}
            {isLoading === false ? (
              <div style={{ marginTop: '50px' }}>
                {selectedValues.home !== '' && (
                  <Grid
                    spacing={2}
                    style={{
                      display: '-webkit-inline-box',
                    }}
                  >
                    {/* Widget main grid right panel start */}
                    <Grid
                      item
                      xs={9}
                      className='framecol'
                      spacing={3}
                      style={{ textAlign: 'center' }}
                    >
                      <Grid
                        item
                        style={{ position: 'relative', zIndex: '999' }}
                      >
                        <Button onClick={zoomIn}>Zoom In</Button>|
                        <Button onClick={zoomOut}>Zoom Out</Button>|
                        <Button onClick={transform}>Reverse</Button>|
                        <Button onClick={reset}>Reset</Button>|
                        <Button onClick={zoomOut}>Print</Button>|
                        <Button onClick={Downloadpdf}>Download</Button>|
                        <Button onClick={handleExport}>Email</Button>|
                        <Button onClick={zoomOut}>Help</Button>
                      </Grid>

                      <Grid item>
                        {floorPlans.length > 0 && (
                          <>
                            <IfpTabsNew
                              tabItems={floorPlans}
                              tabContent={tabContent}
                              selectedTabIndex={selectedTabIndex}
                              setSelectedTabIndex={setSelectedTabIndex}
                              tabRef={tabRef}
                            />
                            {floorPlans.map((fp, fpIndex) => (
                              <div
                                data-id={fpIndex}
                                id={`framecan${fpIndex}`}
                                className='framecanvas'
                                style={{
                                  position: 'relative',
                                  zIndex: 1,
                                  display: 'none',
                                }}
                              >
                                <div className='zoom'>
                                  <img
                                    ref={svgRef}
                                    src={
                                      Config.baseURL +
                                      floorPlans[fpIndex]['imagePath']
                                    }
                                    alt='floorplan'
                                    id='fp'
                                  />
                                  {Object.keys(positions).length !== 0 &&
                                    floorOptions[fpIndex].map(
                                      (floorOption, index) => {
                                        return (
                                          <div key={floorOption._id}>
                                            <span
                                              id={`option-drag-${floorOption._id}`}
                                              className='item'
                                              style={{
                                                position: 'absolute',
                                                zIndex: '2',
                                                left: positions[
                                                  floorOption._id
                                                ]['plan']['x'],
                                                top: positions[floorOption._id][
                                                  'plan'
                                                ]['y'],
                                                visibility:
                                                  floorOption.defaultInactive
                                                    ? 'hidden'
                                                    : 'visible',
                                              }}
                                            >
                                              <img
                                                id={`option-drag-img-${floorOption._id}`}
                                                src={
                                                  Config.baseURL +
                                                  floorOption.imagePath
                                                }
                                                alt='floorplan'
                                              />
                                            </span>
                                            <span className='option-lable'>
                                              <Typography
                                                id={`label-drag-${floorOption._id}`}
                                                className='labelDragElement'
                                                data-type='label'
                                                component='div'
                                                variant='caption'
                                                style={{
                                                  position: 'absolute',
                                                  zIndex: '3',
                                                  left: positions[
                                                    floorOption._id
                                                  ]['label']['x'],
                                                  top: positions[
                                                    floorOption._id
                                                  ]['label']['y'],
                                                  visibility:
                                                    floorOption.defaultInactive
                                                      ? 'hidden'
                                                      : 'visible',
                                                }}
                                              >
                                                {floorOption.name}
                                              </Typography>
                                            </span>
                                          </div>
                                        );
                                      }
                                    )}
                                </div>
                              </div>
                            ))}
                          </>
                        )}
                      </Grid>
                    </Grid>
                    {/* Widget main grid right panel end */}
                    {/* Widget main grid left panel start */}
                    <Grid item xs={3} spacing={2}>
                      {/* Elevation display with selection dropdown start */}
                      <Grid item>
                        <Paper
                          square
                          elevation={0}
                          className={classes.panelHeader}
                        >
                          <Typography>
                            {selectedValues.elevation.name}
                          </Typography>
                        </Paper>
                        <div>
                          <img
                            className={classes.img}
                            src={
                              Config.baseURL +
                              selectedValues.elevation.imagePath
                            }
                            alt={selectedValues.elevation.name}
                          />
                        </div>
                        <FormControl variant='outlined' fullWidth>
                          <FormLabel>Select Elevation</FormLabel>
                          <Select
                            name='elevation'
                            value={selectedValues.elevation}
                            label='elevation'
                            onChange={handleInputChange}
                          >
                            {elevations.map((elevation, index) => {
                              return (
                                <MenuItem key={index} value={elevation}>
                                  {elevation.name}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Grid>
                      {/* Elevation display with selection dropdown end */}

                      {/* Floor options and furnitures tabpanel start */}
                      <Grid item style={{ width: '100%' }}>
                        {/* tabs layout for flunitures and options */}
                        {floorOptions.length > 0 && (
                          <Optionaccordiannew
                            floorOptions={floorOptions}
                            setFloorOptions={setFloorOptions}
                            furnitures={furnitures}
                            inactiveElements={inactiveElements}
                            dragImageUrl={dragImageUrl}
                            note={note}
                            setNote={setNote}
                            defaultcheck={defaultcheck}
                            setDefaultcheck={setDefaultcheck}
                            selectedTabIndex={selectedTabIndex}
                          />
                        )}
                      </Grid>
                      {/* Floor options and furnitures tabpanel end */}
                    </Grid>

                    {/* Modal Preview */}

                    <Dialog fullScreen open={open} onClose={handleClose}>
                      <AppBar sx={{ position: 'relative' }}>
                        <Toolbar>
                          <Button
                            autoFocus
                            color='inherit'
                            onClick={handleClose}
                          >
                            Close
                          </Button>
                        </Toolbar>
                      </AppBar>
                      {floorOptions.length > 0 && (
                        <Printfloorplan
                          floorOptions={floorOptions}
                          setFloorOptions={setFloorOptions}
                          setSelectedValues={setSelectedValues}
                          canvasimg={canvasimg}
                          selectedvalue={selectedValues}
                        />
                      )}
                    </Dialog>
                    {/* Modal Preview */}

                    {/* Widget main grid left panel end */}
                  </Grid>
                )}

                {/* Widget main grid end */}
              </div>
            ) : (
              <h4>Please select community and home</h4>
            )}
            {/* Main widget end */}
            {/* Widget main grid end */}
          </>
        ) : (
          <>
            <Typography>{error}</Typography>
          </>
        )}
      </Paper>
    </div>
  );
};

export default IfpWidgetOptimised;
