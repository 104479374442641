import Api from '../hooks/Api';
const service = 'homes';

export const getHomesByCommunities = async (communityId) => {
  try {
    const response = await Api.get(`/${service}/community/${communityId}`);
    return response;
  } catch (error) {
    throw error;
  }
};
