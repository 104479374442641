import { useState, useEffect } from 'react';
import Api from './Api';

const useData = (call, url, parent = null, parentId = null, post = null) => {
  const [data, setData] = useState([]);
  const [isPending, setIsPending] = useState(true);
  const [error, setError] = useState(null);

  //   const getDataByParent = async (url, parent, parentId) => {
  //     try {
  //       const { data } = await Api.get(`/${url}/${parent}/${parentId}`);
  //       setData(data);
  //       setIsPending(false);
  //       setError(null);
  //     } catch (error) {
  //       setIsPending(false);
  //       setError(error);
  //     }
  //   };

  const getData = async () => {
    try {
      url = parent ? `/${url}/${parent}/${parentId}` : `/${url}`;
      const { data } = await Api.get(url);
      console.log(data);
      setData(data);
      setIsPending(false);
      setError(null);
    } catch (error) {
      console.log(error);
      setIsPending(false);
      setError(error);
    }
  };

  const postData = async () => {
    try {
      const { data } = await Api.post(`/${url}`, post);
      setData(data);
      setIsPending(false);
      setError(null);
    } catch (error) {
      setIsPending(false);
      setError(error);
    }
  };

  const noData = () => {
    setIsPending(false);
    setError('data not found!');
  };

  useEffect(() => {
    if (call === 'get') {
      getData();
    } else if (call === 'post') {
      postData();
    } else {
      noData();
    }
  }, []);

  return { data, setData, isPending, error };
};

export default useData;
