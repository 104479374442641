import React, { useRef } from 'react';
import IfpWidget from './IfpWidget';
import IfpWidgetNew from './IfpWidgetNew';
import IfpWidgetOptimised from './IfpWidgetOptimised';

const Widget = ({ data }) => {
  const code = <div id='ifp_widget' data={data}></div>;
  const widgetRef = useRef(code);
  const WidgetDiv = widgetRef.current;

  return (
    <div>
      {code}
      {/* <IfpWidget domElement={WidgetDiv} /> */}
      <IfpWidgetOptimised domElement={WidgetDiv} />
    </div>
  );
};

export default Widget;
