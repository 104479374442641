import { FormControl, FormControlLabel, Checkbox as MuiCheckbox } from '@material-ui/core'
import React from 'react'

export default function Checkbox(props) {
    const { name, value, label, onChange } = props;

    const convertToDefEventPara = (name, value) => ({
        target : {
            name, value
        }
    })

    return (
        <FormControl>
            <FormControlLabel control={
                <MuiCheckbox
                name={name}
                checked={value}
                onChange={e => onChange(convertToDefEventPara(name, e.target.checked))} />
            }
            label={label} />
        </FormControl>
    )
}
