import * as React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import html2canvas from 'html2canvas';

export default function IfpTabs({
  tabItems,
  tabContent,
  selectedTabIndex,
  setSelectedTabIndex,
  tabRef,
}) {
  const [value, setValue] = React.useState(tabItems[selectedTabIndex]['_id']);

  // React.useEffect(() => {
  //   tabItems.map((o, i) => {
  //     console.log('tab ref: ', o);
  //     tabRef.current.click();
  //     html2canvas(document.getElementById(`canvas-parent-${i}`), {
  //       useCORS: true,
  //     }).then(function (canvas) {
  //       // document.body.appendChild(canvas);
  //       try {
  //         console.log(`canvas ${i}: `, canvas.toDataURL());
  //       } catch (error) {
  //         console.log('canvas error: ', error);
  //       }
  //     });
  //   });
  // }, [tabItems, tabContent]);

  const handleChange = (e, newValue) => {
    setValue(newValue);
    setSelectedTabIndex(e.target.dataset.index);
  };

  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label='lab API tabs example'>
            {tabItems.map((item, itemIndex) => (
              <Tab
                id={`tab-btn-${itemIndex}`}
                ref={tabRef}
                onClick={() => {
                  console.log('canvas: ', itemIndex);
                }}
                name='floorPlan'
                key={item._id}
                label={item.name}
                value={item._id}
                data-index={itemIndex}
              />
            ))}
          </TabList>
        </Box>
        {tabItems.map((itemContent, itemContentIndex) => (
          <TabPanel
            value={itemContent._id}
            style={{
              padding: 0,
            }}
          >
            {tabContent(itemContentIndex)}
          </TabPanel>
        ))}
      </TabContext>
    </Box>
  );
}
