import React from 'react';
import XLSX from 'xlsx';
import { Button } from '@material-ui/core';
import api from '../hooks/Api';
import { useHistory } from 'react-router-dom';

const ImportRecords = () => {
  let history = useHistory();
  const convertToJson = (headers, data) => {
    let rows = [];
    data.forEach((row, i) => {
      // console.log(row);
      let rowData = {};
      row.forEach((element, index) => {
        // console.log(element);
        rowData[headers[index]] = element;
      });
      // console.log(rowData);
      // const comm = ;
      // rowData = {...rowData, communities: []};
      rows.push(rowData);
    });
    // console.log(rows);
    return rows;
  };
  let newData = [];
  const handleChange = (e) => {
    // console.log(e.target.files[0]);
    const file = e.target.files[0];

    const reader = new FileReader();
    reader.onload = (event) => {
      // console.log(event);
      // parse data
      const bstr = event.target.result;
      const workBook = XLSX.read(bstr, { type: 'binary' });

      // get first sheet
      const workSheetName = workBook.SheetNames[0];
      const workSheet = workBook.Sheets[workSheetName];

      // convert to array
      const fileData = XLSX.utils.sheet_to_json(workSheet, { header: 1 });
      const headers = fileData[0];
      fileData.splice(0, 1);
      // console.log(fileData);
      const rows = convertToJson(headers, fileData);
      newData = rows;
      console.log(rows);
    };
    reader.readAsBinaryString(file);
  };

  // const onSubmit = (event) => {
  //     event.preventDefault();
  //     console.log(newData);
  //     props.setValues(newData);
  //     props.handleSubmit();
  // }

  const formSubmit = async (event) => {
    event.preventDefault();
    await api.post('/homebuilders/multi', newData);
    // console.log(response.data);

    history.push('/homebuilders');
  };

  return (
    <form onSubmit={formSubmit}>
      <input type='file' name='excelFile' onChange={handleChange} />
      <Button variant='contained' type='submit'>
        Submit
      </Button>
    </form>
  );
};

export default ImportRecords;
