import React from 'react'
import EmployeesForm from './EmployeesForm'
import { Paper, makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    pageContent: {
        marging: theme.spacing(5),
        padding: theme.spacing(3)
    }
}));

export default function Employees() {

    const classes = useStyles();

    return (
        <>
            <Paper className={classes.pageContent}>
                <EmployeesForm />
            </Paper>
        </>
    )
}
