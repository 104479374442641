import React, { useState, useEffect, useRef } from 'react';
import api from '../hooks/Api';
import {
  FormControl,
  FormLabel,
  Grid,
  MenuItem,
  MobileStepper,
  Paper,
  Select,
  Typography,
  Button,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';
import Config from '../config';
import Optionaccordian from './optionaccordian';
import $ from 'jquery';
import useFurnitures from '../hooks/useFurnitures';
import { Stage, Layer, Image, Transformer, Text } from 'react-konva';
import useImage from 'use-image';
import { useHistory } from 'react-router-dom';
import IfpTabs from '../hooks/IfpTabs';
import IfpTabsNew from '../hooks/IfpTabsNew';
import { getOptionsByFloorPlan } from '../services/floorOptionsService';

const URLImage = ({
  shapeProps,
  isSelected,
  onSelect,
  onChange,
  style,
  image,
}) => {
  const [img] = useImage(image.src);
  const shapeRef = React.useRef();
  const trRef = React.useRef();
  React.useEffect(() => {
    if (isSelected) {
      // we need to attach transformer manually
      trRef.current.nodes([shapeRef.current]);
      trRef.current.getLayer().batchDraw();
    }
  }, [isSelected]);
  return (
    <>
      <Image
        image={img}
        style={image.style}
        x={image.x}
        y={image.y}
        id={image.id}
        onClick={onSelect}
        onTap={onSelect}
        ref={shapeRef}
        {...shapeProps}
        draggable
        onDragEnd={(e) => {
          onChange({
            ...shapeProps,
            x: e.target.x(),
            y: e.target.y(),
          });
        }}
        onTransformEnd={(e) => {
          // transformer is changing scale of the node
          // and NOT its width or height
          // but in the store we have only width and height
          // to match the data better we will reset scale on transform end
          const node = shapeRef.current;
          const scaleX = node.scaleX();
          const scaleY = node.scaleY();

          // we will reset it back
          node.scaleX(1);
          node.scaleY(1);
          onChange({
            ...shapeProps,
            x: node.x(),
            y: node.y(),
            // set minimal value
            width: Math.max(5, node.width() * scaleX),
            height: Math.max(node.height() * scaleY),
          });
        }}
        // I will use offset to set origin to the center of the image
        offsetX={img ? img.width / 2 : 0}
        offsetY={img ? img.height / 2 : 0}
      />
      {isSelected && (
        <Transformer
          ref={trRef}
          boundBoxFunc={(oldBox, newBox) => {
            // limit resize
            if (newBox.width < 5 || newBox.height < 5) {
              return oldBox;
            }
            return newBox;
          }}
        />
      )}
    </>
  );
};

// Widget styles
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    margin: 'auto',
  },
  image: {
    width: 260,
    height: 260,
  },
  carouselHeader: {
    display: 'flex',
    alignItems: 'center',
    height: 41,
    paddingLeft: theme.spacing(3),
    backgroundColor: '#45507f',
    color: '#fff',
  },
  img: {
    display: 'block',
    maxWidth: '100%',
    overflow: 'hidden',
  },
  disinlinebox: {
    display: '-webkit-inline-box',
  },
}));

const IfpWidgetNew = ({ domElement }) => {
  // widget styles as constant to use withing component
  const classes = useStyles();
  let history = useHistory();
  //using theme
  const theme = useTheme();

  const dragImageUrl = React.useRef();
  const stageRef = React.useRef();
  const [images, setImages] = React.useState([]);
  const [selectedId, selectShape] = React.useState(null);
  const [note, setNote] = React.useState('');
  const [draggedNotes, setDraggedNotes] = React.useState([]);

  //reffering floor plan svg image
  const svgRef = useRef(null);
  const svgTextNormalRef = useRef(null);
  const svgTextFlippedRef = useRef(null);
  const svgBlank = useRef(null);

  const [widgetImageControls, setWidgetImageControls] = useState({
    zoomLevel: 1,
    imageAngle: 0,
    flipped: false,
  });

  useEffect(() => {
    if (svgRef !== undefined && svgRef !== null) {
      const svg = svgRef.contentDocument;
    }
  }, []);
  const zoomIn = () => {
    if ($('.zoom').hasClass('zoomin')) {
      $('.zoom').removeClass('zoomin');
      $('.zoom, .konvajs-content canvas').css('transform', 'scale(1.1)');
    } else {
      $('.zoom').addClass('zoomin');
      $('.zoom, .konvajs-content canvas').css('transform', 'scale(1.1)');
    }
  };
  const checkDeselect = (e) => {
    // deselect when clicked on empty area
    const clickedOnEmpty = e.target === e.target.getStage();
    if (clickedOnEmpty) {
      selectShape(null);
    }
  };

  const zoomOut = (level) => {
    if ($('.zoom').hasClass('zoomin')) {
      $('.zoom').removeClass('zoomin');
      $('.zoom, .konvajs-content canvas').css('transform', 'scale(1)');
    } else {
      $('.zoom').addClass('zoomin');
      $('.zoom, .konvajs-content canvas').css('transform', 'scale(1)');
    }
  };

  const transform = () => {
    if ($('.frame').hasClass('rotated')) {
      $('.frame').removeClass('rotated');
      $('.frame, .konvajs-content').css('transform', 'rotateY(0deg)');
    } else {
      $('.frame').addClass('rotated');
      $('.frame, .konvajs-content').css('transform', 'rotateY(190deg)');
    }
  };

  const reset = () => {
    $('.frame, .konvajs-content, .zoom, .konvajs-content canvas').css(
      'transform',
      'none'
    );
  };

  const Downloadpdf = () => {
    window.open('/printfloorplan', '_blank');
  };

  function framecolwidth() {
    setTimeout(() => {
      var a = $('.framecol').outerWidth();
      Setframecolwidth(a - 13);
    }, 1000);

    return document.getElementsByClassName('framecol')[0].clientWidth;
  }
  function framecolheight() {
    setTimeout(() => {
      var a = $('.framecol').outerHeight();
      Setframecolheight(a - 100);
    }, 1000);
    return document.getElementsByClassName('framecol')[0].clientHeight;
  }
  // getting homebuilderId from dom through widget script properties
  const homebuilderId = domElement.props.data;

  // state to store and change communities data
  const [communities, setCommunities] = useState([]);

  // state to store and change homes data
  const [homes, setHomes] = useState([]);

  // state to store and change elevations data
  const [elevations, setElevations] = useState([]);

  // state to store and change floorplans data
  const [floorplans, setFloorplans] = useState([]);

  // state to store and change floor options data
  const [floorOptions, setFloorOptions] = useState([]);

  const [positions, setPositions] = useState([]);

  // state to store and change floor options data
  const { furnitures } = useFurnitures();

  const [framecolwidth1, Setframecolwidth] = useState();
  const [framecolheight1, Setframecolheight] = useState();
  const [defaultcheck, setDefaultcheck] = useState({});

  // state to store and change selected values from different inputs of widget data
  const [selectedValues, setSelectedValues] = useState({
    homebuilder: '',
    community: '',
    home: '',
    elevation: '',
    floorplan: '',
    floorOption: [],
  });

  const [selectedObjects, setSelectedObjects] = useState({
    community: {},
    home: {},
    elevation: {},
    floorplan: {},
    floorOption: [],
  });

  // state to store and change carousel active step
  const [activeStep, setActiveStep] = React.useState(0);

  const [inactiveElements, setInactiveElements] = React.useState([]);

  const [tabItems, setTabItems] = useState([]);

  const [loading, setLoading] = useState(true);

  // state to store and change maximum steps of carousel elements
  const maxSteps = elevations.length;

  // handling and changing active step of carousel
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  // handling carousel back arrow button of carousel
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  // handling automatic step change
  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  // single homebuilder get api call
  const getHomebuilder = async () => {
    const homebuilder = await api.get(`/homebuilders/${homebuilderId}`);
    return homebuilder.data;
  };

  // communities get api call based on selected homebuilderId
  const getCommunities = async () => {
    const communities = await api.get(
      `/communities/homebuilder/${homebuilderId}`
    );
    return communities.data;
  };

  // homes get api call based on selected communitiesId
  const getHomes = async (community) => {
    // console.log('comm:' + community);
    const homes = await api.get(`/homes/community/${selectedValues.community}`);
    return homes.data;
  };

  // elevations get api call based on selected homeId
  const getElevations = async () => {
    const elevations = await api.get(`/elevations/home/${selectedValues.home}`);
    return elevations.data;
  };

  // floorplans get api call based on selected homeId
  const getFloorPlans = async () => {
    const floorplans = await api.get(`/floorplans/home/${selectedValues.home}`);
    return floorplans.data;
  };

  // single floorplan get api call based on selected floorplan
  const getSingleFloorPlan = async () => {
    const floorplan = await api.get(`/floorplans/${selectedValues.floorplan}`);
    return floorplan.data;
  };

  const getSingleElevation = async () => {
    const elevation = await api.get(`/elevations/${selectedValues.elevation}`);
    return elevation.data;
  };

  // flooroptions get api call based on selected floorPlan
  const getFloorOptions = async () => {
    const floorOptions = await api.get(
      `/flooroptions/floorplan/${selectedValues.floorplan}`
    );
    return floorOptions.data;
  };

  // furnituress get api call based on selected floorPlan
  const getFurnitures = async () => {
    const furnitures = await api.get(
      `/furnitures/floorplan/${selectedValues.floorplan}`
    );
    return furnitures.data;
  };

  // widget initial values setup
  useEffect(() => {
    const homebuilder = async () => {
      const getSingleHomebuilder = await getHomebuilder();
      if (getSingleHomebuilder)
        // set selected homebuilderId with persistance of other values in the object
        setSelectedValues({
          ...selectedValues,
          homebuilder: getSingleHomebuilder,
        });
    };

    homebuilder();
  }, []);

  // component re-render based on homebuilderId change
  useEffect(() => {
    const communities = async () => {
      const getAllCommunities = await getCommunities();
      if (getAllCommunities) {
        // set fetched communities
        setCommunities(getAllCommunities);
        // since communities changed hence homes, elevations and activeStep
        // of carousel values will reset
        setHomes([]);
        setElevations([]);
        setActiveStep(0);
      }
    };
    // check if selectedValues homebuilder object have non empty _id
    // then proceed for communities fetch else reset all states
    if (selectedValues.homebuilder._id !== '') {
      communities();
    } else {
      setCommunities([]);
      setHomes([]);
      setElevations([]);
      setActiveStep(0);
    }
  }, [homebuilderId]);

  // component re-render based on selected community
  useEffect(() => {
    const homes = async () => {
      const getAllHomes = await getHomes();
      if (getAllHomes) {
        // set fetched homes
        setHomes(getAllHomes);

        // when homes changes then elevations and activeStep
        // of carousel values will reset
        setElevations([]);
        setActiveStep(0);
      }
    };
    if (selectedValues.community !== '') {
      setSelectedObjects((prev) => {
        prev.community = communities.filter(
          (obj) => obj._id === selectedValues.community && obj
        );
        return prev;
      });
      homes();
    } else {
      setHomes([]);
      setElevations([]);
      setActiveStep(0);
    }
  }, [selectedValues.community]);

  // component re-render based on selected home
  useEffect(() => {
    const elevations = async () => {
      const getAllElevations = await getElevations();
      if (getAllElevations) {
        setElevations(getAllElevations);
        setSelectedValues((prev) => {
          prev.elevation = getAllElevations[0]._id;
          // Setframecolwidth(framecolwidth());
          // Setframecolheight(framecolheight());
          return prev;
        });
        setSelectedObjects((prev) => {
          prev.elevation = getAllElevations[0];
          return prev;
        });
        // setActiveStep(0);
      }
    };
    const floorplans = async () => {
      const getAllFloorplans = await getFloorPlans();

      if (getAllFloorplans) {
        setFloorplans(getAllFloorplans);
        setSelectedValues((prev) => {
          prev.floorplan = getAllFloorplans[0]._id;
          Setframecolwidth(framecolwidth());
          Setframecolheight(framecolheight());
          return prev;
        });
        setSelectedObjects((prev) => {
          prev.floorplan = getAllFloorplans[0];
          return prev;
        });
        const newTabItems = [];
        getAllFloorplans.forEach((floorplan, index) => {
          newTabItems.push({
            _id: floorplan._id,
            name: floorplan.name,
            content: tabContent,
          });
        });
        let filledArray = new Array(getAllFloorplans.length);

        setTabItems(newTabItems);
      }
    };
    // console.log('selected floor plan: ', selectedValues.floorplan);

    if (selectedValues.home !== '') {
      elevations();
      floorplans();

      // console.log(elevations);
    } else {
      setElevations([]);
      setFloorplans([]);

      setActiveStep(0);
    }
  }, [selectedValues.home]);

  let dummyPositions = { ...positions };
  const [isDragging, setDragging] = React.useState(false);

  useEffect(() => {
    const allFloorOptions = async () => {
      // const getAllFloorOptions = await getFloorOptions();
      for (let i = 0; i < floorplans.length; i++) {
        const getAllFloorOptions = await getOptionsByFloorPlan(
          floorplans[i]['_id']
        );
        if (getAllFloorOptions) {
          getAllFloorOptions.forEach((floorOption) => {
            const itemId = floorOption._id;
            dummyPositions[itemId] = floorOption.planCoordinate;
          });
          setPositions(dummyPositions);
          setFloorOptions([...floorOptions, getAllFloorOptions]);
          const newArr = [];
          const newObj = Object.assign({});
          getAllFloorOptions.map((o, i) => {
            newArr.push(o.defaultInactive);
            newObj[o._id] = !o.defaultInactive;
          });
          setDefaultcheck(newObj);
          const newInactiveElements = inactiveElements;
          setInactiveElements(newInactiveElements);
        }
      }
      console.log('op data', floorOptions);
    };

    const singleFloorPlan = async () => {
      const floorPlan = await getSingleFloorPlan();
      if (floorPlan) {
        // console.log('wor:', floorPlan);
        setSelectedObjects({ ...selectedObjects, floorplan: floorPlan });
      }
    };

    const singleElevation = async () => {
      const elevation = await getSingleElevation();
      if (elevation) {
        setSelectedObjects({ ...selectedObjects, elevation: elevation });
      }
    };

    if (selectedValues.home !== '') {
      allFloorOptions();
      setLoading(false);
    } else {
      setFloorOptions([]);
    }
    if (selectedValues.floorplan !== '') {
      singleFloorPlan();

      Setframecolwidth(framecolwidth());
      Setframecolheight(framecolheight());
      setLoading(false);
    }

    // console.log('options: ', floorOptions);
  }, [selectedValues.home, selectedValues.floorplan]);

  useEffect(() => {
    const singleElevation = async () => {
      const elevation = await getSingleElevation();
      if (elevation) {
        setSelectedObjects({ ...selectedObjects, elevation: elevation });
      }
    };

    if (selectedValues.elevation !== '' && selectedValues.home !== '') {
      singleElevation();
      setLoading(false);
      // Setframecolwidth(framecolwidth());
      // Setframecolheight(framecolheight());
    }
  }, [selectedValues.home, selectedValues.elevation]);

  // Handling change of input values like dropdown
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSelectedValues(
      (prev) =>
        (prev = {
          ...selectedValues,
          [name]: value,
        })
    );
  };
  const [error, setError] = useState('');
  const [data, setData] = useState([]);

  const tabContent = (index) => (
    <Grid
      item
      style={{
        width: '100%',
        textAlign: 'center',
        padding: '5px',
      }}
    >
      <div
        style={{
          zIndex: '1',
          position: 'relative',
          background: '#f8f8f8',
        }}
        onDrop={(e) => {
          e.preventDefault();
          // register event position
          stageRef.current.setPointersPositions(e);
          // images[index] = [];
          // add image
          if (dragImageUrl.current !== '') {
            setImages(
              images.concat([
                {
                  ...stageRef.current.getPointerPosition(),
                  src: dragImageUrl.current,
                  id: Math.random() * 100,
                  style: {},
                  tabId: index,
                },
              ])
            );
            dragImageUrl.current = '';
          }
          if (note !== '') {
            // draggedNotes[index] = [];
            setDraggedNotes(
              draggedNotes.concat([
                {
                  ...stageRef.current.getPointerPosition(),
                  text: note,
                  id: Math.random() * 100,
                  style: {},
                  tabId: index,
                },
              ])
            );
            setNote('');
          }
        }}
        onDragOver={(e) => {
          e.preventDefault();
          // console.log('current index', index);
          console.log('dragged images: ', images);
        }}
      >
        <Stage
          width={framecolwidth1}
          height={framecolheight1}
          style={{
            // width:'100%',
            // height:'100%',
            zIndex: '999',
            position: 'absolute',
            border: '1px solid #d8d8d8',
          }}
          onMouseDown={checkDeselect}
          onTouchStart={checkDeselect}
          ref={stageRef}
        >
          <Layer>
            {images.map((image, i) => {
              return (
                image.tabId === index && (
                  <URLImage
                    key={i}
                    shapeProps={image}
                    isSelected={image.id === selectedId}
                    onSelect={() => {
                      selectShape(image.id);
                    }}
                    onChange={(newAttrs) => {
                      console.log('new attr: ', newAttrs);
                      const rects = images.slice();
                      rects[i] = newAttrs;
                      setImages(rects);
                    }}
                    image={image}
                  />
                )
              );
            })}

            {draggedNotes.map((draggedNote, i) => {
              return (
                draggedNote.tabId === index && (
                  <Text
                    draggable
                    key={i}
                    id={draggedNote.id}
                    x={draggedNote.x}
                    y={draggedNote.y}
                    text={draggedNote.text}
                  />
                )
              );
            })}
          </Layer>
        </Stage>
        <div
          id={'frame'}
          className='frame'
          style={{
            position: 'relative',
            display: 'inline-block',
            zIndex: '1',
          }}
        >
          <div className='zoom'>
            <img
              ref={svgRef}
              src={Config.baseURL + floorplans[index].imagePath}
              alt='floorplan'
              id='fp'
            />
            {Object.keys(positions).length !== 0 &&
              floorOptions[index].map((floorOption) => (
                <div key={floorOption._id}>
                  <span
                    id={`option-drag-${floorOption._id}`}
                    className='item'
                    style={{
                      position: 'absolute',
                      zIndex: '2',
                      left: positions[floorOption._id]['x'],
                      top: positions[floorOption._id]['y'],
                    }}
                  >
                    <img
                      id={`option-drag-img-${floorOption._id}`}
                      src={Config.baseURL + floorOption.imagePath}
                      alt='floorplan'
                    />
                  </span>
                  {/* <Divider component='li' /> */}
                </div>
              ))}
          </div>
        </div>
      </div>
    </Grid>
  );

  return (
    <div className={classes.root + ' reddit_widget__app'}>
      <Paper className={classes.paper}>
        {/* Widget main grid start */}
        <Grid container spacing={2}>
          <Grid item>
            <h1>{selectedValues.homebuilder.name}</h1>
          </Grid>
          <Grid item container spacing={2}>
            <Grid item>
              <FormControl variant='outlined'>
                <FormLabel>Select Community</FormLabel>
                <Select
                  name='community'
                  value={selectedValues.community}
                  label='community'
                  onChange={handleInputChange}
                  // inputProps={{
                  //   readOnly: requestType === 'view' ? true : false,
                  // }}
                >
                  {communities.map((community, index) => {
                    return (
                      <MenuItem key={index} value={community._id}>
                        {community.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl variant='outlined'>
                <FormLabel>Select Home </FormLabel>
                <Select
                  name='home'
                  value={selectedValues.home}
                  label='home'
                  onChange={handleInputChange}
                  // inputProps={{
                  //   readOnly: requestType === 'view' ? true : false,
                  // }}
                >
                  {homes.map((home, index) => {
                    return (
                      <MenuItem key={index} value={home._id}>
                        {home.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        {/* Main widget start */}
        {loading === false ? (
          <div style={{ marginTop: '50px' }}>
            {selectedValues.home !== '' && (
              <Grid spacing={2} className={classes.disinlinebox}>
                {/* Widget main grid right panel start */}
                <Grid
                  item
                  xs={9}
                  className='framecol'
                  spacing={3}
                  style={{ textAlign: 'center' }}
                >
                  <Grid item style={{ position: 'relative', zIndex: '999' }}>
                    <Button onClick={zoomIn}>Zoom In</Button>|
                    <Button onClick={zoomOut}>Zoom Out</Button>|
                    <Button onClick={transform}>Reverse</Button>|
                    <Button onClick={reset}>Reset</Button>|
                    <Button onClick={zoomOut}>Print</Button>|
                    <Button onClick={Downloadpdf}>Download</Button>|
                    <Button onClick={zoomIn}>Email</Button>|
                    <Button onClick={zoomOut}>Help</Button>
                  </Grid>
                  <Grid item>
                    <IfpTabsNew
                      tabItems={tabItems}
                      tabContent={tabContent}
                      selectedValues={selectedValues}
                      setSelectedValues={setSelectedValues}
                    />
                  </Grid>
                </Grid>
                {/* Widget main grid right panel end */}
                {/* Widget main grid left panel start */}
                <Grid item xs={3} spacing={2}>
                  {/* Elevation display with selection dropdown start */}
                  <Grid item>
                    <Paper
                      square
                      elevation={0}
                      className={classes.carouselHeader}
                    >
                      <Typography>{selectedObjects.elevation.name}</Typography>
                    </Paper>
                    <div>
                      <img
                        className={classes.img}
                        src={
                          Config.baseURL + selectedObjects.elevation.imagePath
                        }
                        alt={selectedObjects.elevation.name}
                      />
                    </div>
                    <FormControl variant='outlined' fullWidth>
                      <FormLabel>Select Elevation</FormLabel>
                      <Select
                        name='elevation'
                        value={selectedValues.elevation}
                        label='elevation'
                        onChange={handleInputChange}
                      >
                        {elevations.map((elevation, index) => {
                          return (
                            <MenuItem key={index} value={elevation._id}>
                              {elevation.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  {/* Elevation display with selection dropdown end */}

                  {/* Floor options and furnitures tabpanel start */}
                  <Grid item style={{ width: '100%' }}>
                    {/* tabs layout for flunitures and options */}

                    <Optionaccordian
                      floorOptions={floorOptions}
                      setFloorOptions={setFloorOptions}
                      furnitures={furnitures}
                      inactiveElements={inactiveElements}
                      dragImageUrl={dragImageUrl}
                      note={note}
                      setNote={setNote}
                      defaultcheck={defaultcheck}
                      setDefaultcheck={setDefaultcheck}
                    />
                  </Grid>
                  {/* Floor options and furnitures tabpanel end */}
                </Grid>

                {/* Widget main grid left panel end */}
              </Grid>
            )}

            {/* Widget main grid end */}
          </div>
        ) : (
          <h4>Please select community and home</h4>
        )}
        {/* Main widget end */}
      </Paper>
    </div>
  );
};

export default IfpWidgetNew;
