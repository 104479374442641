import { useState, useEffect } from 'react';
import { getFurnituresWithCategory } from '../services/furnituresService';

const useFurnitures = () => {
  const [furnitures, setFurnitures] = useState([]);
  const getAllFurnituresWithCategory = async () => {
    console.log('furnitures with category!');
    const allFurnitures = await getFurnituresWithCategory();
    // console.log(allFurnitures);
    setFurnitures(allFurnitures);
  };
  useEffect(() => {
    const allFurnitures = getAllFurnituresWithCategory();
    console.log(allFurnitures);
    // return () => getAllFurnituresWithCategory();
  }, []);

  return {
    furnitures,
  };
};

export default useFurnitures;
