import Input from './Input';
import RadioGroupI from './RadioGroupI';
import Select from './Select';
import Switch from './Switch';
import Checkbox from './Checkbox';
import DatePicker from './DatePicker';
import Button from './Button';


const Controls = {
    Input,
    RadioGroupI, 
    Select,
    Switch,
    Checkbox,
    DatePicker,
    Button
}

export default Controls;
