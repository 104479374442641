import Api from '../hooks/Api';

const service = 'homebuilders';

export const getSingleHomebuilder = async (homebuilderId) => {
  try {
    const response = Api.get(`/${service}/${homebuilderId}`);
    return response;
  } catch (error) {
    throw error;
  }
};
