import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import api from '../hooks/Api';
import { useHistory, useParams, useLocation, Link } from 'react-router-dom';
import { useForm, Form } from './useForm';
import Controls from '../hooks/controls/Controls';
import {
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Paper,
  Select,
} from '@material-ui/core';
import ImportRecords from './ImportRecords';

const defaultValues = {
  name: '',
  no_of_elevations: 1,
  noOfFloorPlans: 1,
  floor_plans_same: 0,
  status: 0,
  community_id: '',
  homebuilder_id: '',
  created_by: 1,
  last_update_by: 1,
};
const useStyles = makeStyles((theme) => ({
  pageContent: {
    marging: theme.spacing(5),
    padding: theme.spacing(3),
  },
  grid: {
    width: '100%',
    margin: '0px',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

const toggleItems = [
  { id: '0', title: 'Yes' },
  { id: '1', title: 'No' },
];
const HomesForm = () => {
  const uri = useLocation();
  const requestType = uri.pathname.split('/')[2]
    ? uri.pathname.split('/')[2]
    : '';
  const classes = useStyles();
  let history = useHistory();
  const [communitiesData, setCommunitiesData] = useState([]);

  const { id } = useParams();
  const retreveCommunities = async () => {
    const response = await api.get('/communities');
    return response.data;
  };
  const getHome = async () => {
    const response = await api.get(`/homes/${id}`);
    return response.data;
  };

  useEffect(() => {
    const getAllCommunities = async () => {
      const allHomebuilders = await retreveCommunities();
      if (allHomebuilders) setCommunitiesData(allHomebuilders);
    };
    const home = async () => {
      const getSingleHome = await getHome();
      if (getSingleHome) setValues(getSingleHome);
    };
    home();
    getAllCommunities();
  }, []);
  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormValues({
  //     ...formValues,
  //     [name]: value,
  //   });
  // };
  const handleSubmit = async (event) => {
    event.preventDefault();
    // const newValues = {...values};
    // newValues.homebuilder_id = communitiesData.find(community => community.community_id = values.community_id).homebuilder_id;

    // console.log(communitiesData);
    setValues(
      (current) =>
        (current.homebuilder_id = communitiesData.find(
          (community) => (community.community_id = values.community_id)
        ).homebuilder_id)
    );
    console.log(values);
    if (requestType === 'edit') {
      await api.patch(`/homes/${id}`, values);
    } else {
      await api.post('/homes', values);
    }
    history.push('/homes');
  };

  const { values, setValues, handleInputChange } = useForm(defaultValues);

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Grid container direction='column' spacing={2} className={classes.grid}>
          <Grid item container spacing={2}>
            <Grid item>
              <Button variant='contained' size='large'>
                <Link to='/homesform'>Add Home</Link>
              </Button>
            </Grid>
            <Grid item>
              <Button variant='contained' size='large'>
                <Link to={`/homes/edit/${id}`}>Edit Home</Link>
              </Button>
            </Grid>
            <Grid item>
              <Button variant='contained' size='large'>
                <Link to='/homes'>View All Homes</Link>
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Paper className={classes.paper}>
              <Controls.Input
                name='name'
                label='Name'
                value={values.name}
                onChange={handleInputChange}
                InputProps={{ readOnly: requestType === 'view' ? true : false }}
              />
              <Controls.Input
                name='no_of_elevations'
                label='No of elevations'
                value={values.no_of_elevations}
                onChange={handleInputChange}
                InputProps={{ readOnly: requestType === 'view' ? true : false }}
              />
              <Controls.Input
                name='noOfFloorPlans'
                label='No of floor plans'
                value={values.noOfFloorPlans}
                onChange={handleInputChange}
                InputProps={{ readOnly: requestType === 'view' ? true : false }}
              />
              <Controls.RadioGroupI
                name='floor_plans_same'
                value={values.floor_plans_same}
                label='Floor plans same for all elevations ?'
                onChange={handleInputChange}
                items={toggleItems}
              />
              <FormControl variant='outlined'>
                <InputLabel>Community</InputLabel>
                <Select
                  name='community_id'
                  label='Community'
                  value={values.community_id}
                  onChange={handleInputChange}
                  inputProps={{
                    readOnly: requestType === 'view' ? true : false,
                  }}
                >
                  <MenuItem key='0' value=''>
                    None
                  </MenuItem>
                  {communitiesData.map((option) => {
                    return (
                      <MenuItem key={option._id} value={option._id}>
                        {option.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <Controls.Switch
                name='status'
                label='Status'
                value={values.status}
                onChange={handleInputChange}
                disabled={requestType === 'view' ?? true}
              />
            </Paper>
            {/* <Controls.Input
            name="homebuilder_id"
            label="Homebuilder id"
            value={
              values.homebuilder_id
            }
            onChange={() => {
              values.homebuilder_id = communitiesData.find(community => community.community_id = values.community_id).homebuilder_id
            }
              
            }
            InputProps={{ readOnly: requestType === "view" ? true : false }}
            
          /> */}
          </Grid>
          <Grid item container spacing={2}>
            <Grid item>
              <Button
                variant='contained'
                color='primary'
                type='submit'
                disabled={requestType === 'view' ?? true}
              >
                Save
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant='contained'
                color='primary'
                type='submit'
                disabled={requestType === 'view' ?? true}
              >
                Clear
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Form>
      <div>
        <Grid container>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <ImportRecords />
            </Paper>
          </Grid>
        </Grid>
      </div>
    </>
  );
};
export default HomesForm;
