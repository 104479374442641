const hostname = window.location.hostname;

const Config = {
  baseURL:
    hostname === 'localhost'
      ? 'http://localhost:5000/'
      : 'https://ifp-node-backend.herokuapp.com/',
};

export default Config;
