import React from 'react';
import { forwardRef } from 'react';
import MaterialTable from 'material-table';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const Datatable = (props) => {
  // const [tableData, setTableData] = useState([]);
  // setTableData(props.data);

  // const [isLoading, setIsLoading] = React.useState(false);

  const onDelete = async (oldData) => {
    await props.delete(oldData);
  };

  const onAdd = async (newData) => {
    console.log(newData);
    await props.create(newData);
  };

  const onUpdate = async (newData, oldData) => {
    await props.update(newData, oldData);
  };
  return (
    <MaterialTable
      title={props.title}
      isLoading={props.isPending}
      icons={tableIcons}
      data={props.data}
      columns={props.columns}
      options={{
        search: true,
        paging: true,
        filtering: false,
        exportButton: true,
        selection: true,
        tableLayout: 'fixed',
        actionsColumnIndex: -1,
        addRowPosition: 'first',
      }}
      // actions={[
      //     {
      //       icon: 'save',
      //       tooltip: 'Save User',
      //       onClick: (event, rowData) => {
      //         // Do save operation
      //       }
      //     }
      //   ]}
      editable={{
        onRowAdd: (newData) =>
          new Promise(async (resolve, reject) => {
            await onAdd(newData);
            resolve();
          }),
        onBulkUpdate: (changes) =>
          new Promise((resolve, reject) => {
            setTimeout(() => {
              /* setData([...data, newData]); */

              resolve();
            }, 1000);
          }),

        onRowUpdate: (newData, oldData) =>
          new Promise(async (resolve, reject) => {
            await onUpdate(newData, oldData);
            // console.log(newData);
            resolve();
            // console.log(oldData);
          }),

        onRowDelete: async (oldData) => {
          //   await props.api.delete(`/homebuilder/${oldData.id}`);
          await onDelete(oldData);

          // console.log(oldData);

          // const newHomebuilder = data.filter((homebuilder) => {
          //   return homebuilder.id !== oldData.id;
          // });
          // data = newHomebuilder;
        },
      }}
    />
  );
};

export default Datatable;
