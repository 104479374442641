import React, { useState, useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { getOptionsByFloorPlan } from '../services/floorOptionsService';
import {
  Button,
  FormControlLabel,
  FormGroup,
  Grid,
  Switch,
  Typography,
} from '@material-ui/core';
import { arrayRemove } from '../helpers/helpers';

const FloorOptionRules = () => {
  const [floorOptions, setFloorOptions] = useState([]);

  const [defaultInactive, setDefauleInactive] = useState({
    0: {
      name: 'Covered Patio',
      status: false,
    },
    1: {
      name: 'Fridge',
      status: true,
    },
    2: {
      name: 'Guest Suit',
      status: true,
    },
  });

  const [floorOptionRules, setFloorOptionRules] = useState({
    2: {
      1: {
        name: 'Covered Patio',
        status: true,
      },
      2: {
        name: 'Fridge',
        status: true,
      },
      3: {
        name: 'Guest Suit',
        status: false,
      },
    },
    1: {
      1: {
        name: 'Covered Patio',
        status: true,
      },
      2: {
        name: 'Fridge',
        status: true,
      },
      3: {
        name: 'Guest Suit',
        status: true,
      },
    },

    3: {
      1: {
        name: 'Covered Patio',
        status: true,
      },
      2: {
        name: 'Fridge',
        status: false,
      },
      3: {
        name: 'Guest Suit',
        status: false,
      },
    },
    0: {
      1: {
        name: 'Covered Patio',
        status: false,
      },
      2: {
        name: 'Fridge',
        status: true,
      },
      3: {
        name: 'Guest Suit',
        status: true,
      },
    },
  });

  const [inactiveFloorOptions, setInactiveFloorOptions] = useState({});

  const { floorPlanId } = useParams();

  const getFloorOptionRules = async () => {
    // getting flooroptions based on floorplan id by using floor plan service
    const floorOptionsResponse = await getOptionsByFloorPlan(floorPlanId);
    // check weather response status is 200 or not
    if (floorOptionsResponse) {
      // floor options response data as an array
      const floorOptionsData = floorOptionsResponse;
      // floor options default rule for default rule of inactive values containing id of defaultInactive true
      const defaultInactiveElements = Object.assign({});
      // floor options rules object for checked status of toggle, containing key as id and
      // value as array of id of inactive elements
      const newFloorOptionRules = {};
      // map through floorOptions data to extract ids of
      //default inactive values and floor option rules of checked toggle
      floorOptionsData.map((floorOption) => {
        // default floor option rule, inactive floor options
        // if(floorOption.defaultInactive === true){
        const newObj = Object.assign({});
        defaultInactiveElements[floorOption._id] = floorOption.defaultInactive;
        // defaultInactiveElements = Object.assign({...defaultInactiveElements}, {floorOption['_id']: 'floorOption.defaultInactive'})
        // defaultInactiveElements['default'] = Object.assign({}, );
        // defaultInactiveElements.push(floorOption._id);
        // }

        // each floor option rule, inactive floor options
        newFloorOptionRules[floorOption._id] = floorOption.inactiveElements;
        return [];
      });
      // adding a default floor option rule at the biginning of floor option data array
      floorOptionsData.unshift({
        _id: 'default',
        name: 'Default',
        inactiveElements: defaultInactiveElements,
        defaultInactive: false,
      });

      // floor option rules object first element key as 0 and value as default inactive floor options array
      newFloorOptionRules['default'] = defaultInactiveElements;

      console.log('flooroption rules: ', newFloorOptionRules);
      // console.log('flooroptions: ', floorOptionsData);
      // console.log('default inactive elements', defaultInactiveElements);

      // set flooroptions array to the state
      setFloorOptions(floorOptionsData);
      // set flooroption rules object to the state
      setFloorOptionRules(newFloorOptionRules);
    }
    return;
  };

  const handleChange = (e, index, rule) => {
    // setFloorOptionRules({
    //   ...floorOptionRules,
    //   [e.target.name]: e.target.checked,
    // });
    const newFloorOptionRules = floorOptionRules;
    console.log('index: ', index);
    console.log('rule: ', rule._id);
    console.log('checked value: ', e.target.name);
    let newFloorOptions = floorOptions;

    // e.target.checked = false;
    // console.log(e);

    if (newFloorOptions[rule._id]['inactiveElements'].includes(e.target.name)) {
      // const elementClickedIndex = newFloorOptions.indexOf(
      //   e.target.name
      // );
      // newFloorOptions = newFloorOptions.filter(
      //   (nfo) => {
      //     if(nfo._id === e.target.name) {
      //       newFloorOptions[index]['inactiveElements']
      //     }
      //   }
      // );
      const elementClickedIndex = newFloorOptions[rule._id][
        'inactiveElements'
      ].indexOf(e.target.name);

      // console.log('element clicked index: ', elementClickedIndex);
      if (elementClickedIndex > -1) {
        newFloorOptions[rule._id]['inactiveElements'].splice(
          elementClickedIndex,
          1
        );
      }
      // // newFloorOptions[rule._id]['inactiveElements'].push(e.target.name);
    } else {
      newFloorOptions[rule._id]['inactiveElements'].push(e.target.name);
    }
    console.log('new after checked: ', newFloorOptions);
    setFloorOptions(newFloorOptions);
    // console.log(floorOptionRules[rule._id].includes(e.target.name));

    // e.target.checked
    //   ? newFloorOptionRules[rule._id].push(e.target.name)
    //   : delete newFloorOptionRules[rule._id][e.target.name];
    // console.log(newFloorOptionRules);
  };

  const modifyOptionRules = () => {};

  const handleSwitch = (e, key) => {
    console.log('checked: ', e.target.checked);
    console.log('name: ', e.target.name);
    console.log('key: ', key);
    const obj = floorOptionRules;
    obj[key] = { ...obj[key], [e.target.name]: e.target.checked };
    setFloorOptionRules({
      ...floorOptionRules,
      key: { ...floorOptionRules[key], [e.target.name]: e.target.checked },
    });
  };

  useEffect(() => {
    // getFloorOptionRules();
  }, []);

  return (
    <>
      <FormGroup>
        <Grid container spacing={2}>
          {Object.keys(floorOptionRules).length > 0 ? (
            Object.keys(floorOptionRules)
              .reverse()
              .map((floorOptionRuleKey, floorOptionRuleIndex) => {
                return (
                  <>
                    <Grid item xs={12}>
                      <Typography>
                        {floorOptionRuleIndex === 3
                          ? 'Default'
                          : floorOptionRules[floorOptionRuleKey][
                              floorOptionRuleKey
                            ]['name']}
                      </Typography>
                    </Grid>
                    <Grid key={floorOptionRuleIndex} item xs={6}>
                      {Object.entries(floorOptionRules[floorOptionRuleKey])
                        .slice(
                          0,
                          Math.floor(
                            Object.keys(floorOptionRules[floorOptionRuleKey])
                              .length / 2
                          ) + 1
                        )
                        .map((option, optionIndex) => {
                          return (
                            <FormControlLabel
                              key={optionIndex}
                              control={
                                <Switch
                                  checked={option[1]['status']}
                                  disabled={option[0] === floorOptionRuleKey}
                                  name={option[0]}
                                  // id={option._id}
                                  onChange={(e) =>
                                    handleSwitch(e, floorOptionRuleKey)
                                  }
                                />
                              }
                              label={option[1]['name']}
                            />
                          );
                        })}
                    </Grid>
                    <Grid key={floorOptionRuleIndex} item xs={6}>
                      {Object.entries(floorOptionRules[floorOptionRuleKey])
                        .slice(
                          Math.floor(
                            Object.keys(floorOptionRules[floorOptionRuleKey])
                              .length / 2
                          ) + 1,
                          Object.keys(floorOptionRules[floorOptionRuleKey])
                            .length
                        )
                        .map((option, optionIndex) => {
                          return (
                            <FormControlLabel
                              key={optionIndex}
                              control={
                                <Switch
                                  checked={option[1]['status']}
                                  disabled={option[0] === floorOptionRuleKey}
                                  name={option[0]}
                                  // id={option._id}
                                  onChange={(e) =>
                                    handleSwitch(e, floorOptionRuleKey)
                                  }
                                />
                              }
                              label={option[1]['name']}
                            />
                          );
                        })}
                    </Grid>
                    {/* <Grid key={floorOptionRuleIndex} item xs={6}>
                    {Object.entries(floorOptionRules[floorOptionRuleKey])
                      .slice(
                        0,
                        Math.floor(
                          Object.keys(floorOptionRules[floorOptionRuleKey])
                            .length / 2
                        ) + 1
                      )
                      .map((option, optionIndex) => (
                        <FormControlLabel
                          key={optionIndex}
                          control={
                            <Switch
                              checked={option[optionIndex]['status']}
                              // disabled={floorOption._id === option._id}
                              // name={option._id}
                              // id={option._id}
                              // onChange={(e) =>
                              //   handleChange(e, index, floorOption)
                              // }
                            />
                          }
                          label={option[optionIndex]['name']}
                        />
                      ))}
                  </Grid>
                  <Grid item xs={6}>
                    {Object.entries(floorOptionRules[floorOptionRuleKey])
                      .slice(
                        0,
                        Math.floor(
                          Object.keys(floorOptionRules[floorOptionRuleKey])
                            .length / 2
                        ) + 1
                      )
                      .map((option, optionIndex) => (
                        <FormControlLabel
                          key={optionIndex}
                          control={
                            <Switch
                              checked={option[optionIndex]['status']}
                              // disabled={floorOption._id === option._id}
                              // name={option._id}
                              // onChange={(e) =>
                              //   handleChange(e, index, floorOption)
                              // }
                            />
                          }
                          label={option[optionIndex]['name']}
                        />
                      ))}
                  </Grid> */}
                  </>
                );
              })
          ) : (
            <>
              <Grid item xs={12}>
                <Typography>Default Rule</Typography>
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel
                  control={<Switch defaultChecked />}
                  label='Default 0'
                />
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel
                  control={<Switch defaultChecked />}
                  label='Seat with storage in Entry'
                />
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <Button variant='contained' onClick={(e) => alert('floorOption')}>
              Save
            </Button>
          </Grid>
        </Grid>
      </FormGroup>
    </>
  );
};

export default FloorOptionRules;
