import {
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Typography,
} from '@material-ui/core';
import Config from '../../config';
import styled from 'styled-components';

const ImgGrid = styled.div`
  margin: 20px auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 40px;
`;

const useStyles = makeStyles((theme) => ({
  imgCard: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
}));

const FilesModalDilog = ({ files, open, setOpen, handleClickFile }) => {
  const classes = useStyles();
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Dialog
      fullWidth
      maxWidth='md'
      open={open}
      onClose={handleClose}
      aria-labelledby='max-width-dialog-title'
    >
      <DialogTitle id='max-width-dialog-title'>Optional sizes</DialogTitle>
      <DialogContent>
        <ImgGrid>
          {files &&
            files.map((doc, index) => (
              <Card
                className={classes.imgCard}
                onClick={() => handleClickFile(doc)}
                key={index}
              >
                <CardActionArea>
                  <CardMedia
                    component='img'
                    className={classes.media}
                    src={Config.baseURL + doc.path}
                    title={doc.name}
                  />
                  <CardContent>
                    <Typography>{doc.name}</Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            ))}
        </ImgGrid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color='primary'>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FilesModalDilog;
