import React, { useState, useEffect } from 'react';
import api from '../hooks/Api';

import { Button, ButtonGroup, Container } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Datatable from '../hooks/Datatable';

const retreveCommunities = async () => {
  const response = await api.get('/communities');
  // console.log(response.da);
  return response.data;
};

const title = 'Communities';

function Communities() {
  const [data, setData] = useState([]);
  const columns = [
    {
      title: 'Name',
      field: 'name',
    },
    {
      title: 'Status',
      field: 'status',
      render: (rowData) => {
        if (rowData.status === 1) {
          return <span style={{ color: 'green' }}>Active</span>;
        } else {
          return <span style={{ color: 'red' }}>Inactive</span>;
        }
      },
    },
    {
      title: 'View/Edit Community Info',
      field: '_id',
      render: (rowData) => {
        return (
          <div>
            <ButtonGroup
              variant='text'
              color='primary'
              aria-label='text primary button group'
            >
              <Button>
                <Link to={`/communities/view/${rowData._id}`}>View</Link>
              </Button>
              <Button>
                <Link to={`/communities/edit/${rowData._id}`}> Edit </Link>
              </Button>
              <Button onClick={() => deleteCommunity(rowData._id)}>
                Delete
              </Button>
            </ButtonGroup>
          </div>
        );
      },
    },
    {
      title: 'Homes',
      field: 'homes',
      render: (rowData) => rowData.homes.length,
    },
    {
      title: 'City',
      field: 'city',
    },
    {
      title: 'State',
      field: 'state',
      // render: (rowData) => csc.getStateByCode(rowData.state).name
    },
  ];

  const deleteCommunity = async (id) => {
    console.log(id);
    await api.delete(`/communities/${id}`);
    getAllCommunities();
  };

  const getAllCommunities = async () => {
    const allCommunities = await retreveCommunities();
    if (allCommunities) setData(allCommunities);
  };

  useEffect(() => {
    // fetch('https://ifp.xscad2.com/api/index.php/community')
    // .then(response => response.json())
    // .then(json => setData(json));

    getAllCommunities();
    // console.log(getAllCountries);
  }, []);

  const deleteRow = async (oldData) => {
    await api.delete(`/communities/${oldData.id}`);
  };

  return (
    <div className='mt-6'>
      <Container className='mt-6'>
        <Button variant='contained'>
          <Link to='/communityform'>Add New</Link>
        </Button>
      </Container>
      {/* <CommunitiesTable data={data} /> */}
      <Datatable
        title={title}
        data={data}
        columns={columns}
        delete={deleteRow}
        component='communities'
      />
    </div>
  );
}

export default Communities;
