import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Button, ButtonGroup, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Datatable from '../hooks/Datatable';
import api from '../hooks/Api';
import HomebuilderServices from '../services/HomebuilderServices';
import { HomebuildersData } from './materialNavbar';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },

  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(2),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  grid: {
    width: '100%',
    margin: '0px',
  },
}));

const title = 'Homebuilder';

const endpoint = 'homebuilders';

function Homebuilders() {
  const classes = useStyles();
  const homebuildersData = useContext(HomebuildersData);
  // const isPending = useContext(isPending);
  // const error = useContext(error);

  const [data, setData] = useState([]);
  useEffect(() => {
    setData(homebuildersData);
  }, [homebuildersData]);

  // HomebuilderServices.useGetData(endpoint, setData);

  const columns = [
    {
      title: 'Homebuilder',
      field: 'name',
    },
    {
      title: 'Country',
      field: 'country',
    },
    {
      title: 'Status',
      field: 'status',
      render: (rowData) => {
        if (rowData.status === 1) {
          return <span style={{ color: 'green' }}>Active</span>;
        } else {
          return <span style={{ color: 'red' }}>Inactive</span>;
        }
      },
    },
    {
      title: 'View/Edit Homebuilder Info',
      field: '_id',
      render: (rowData) => {
        return (
          <div>
            <ButtonGroup
              variant='text'
              color='primary'
              aria-label='text primary button group'
            >
              <Button>
                <Link to={`/homebuilder/view/${rowData._id}`}>View</Link>
              </Button>
              <Button>
                <Link to={`/homebuilder/edit/${rowData._id}`}> Edit </Link>
              </Button>
              <Button onClick={() => deleteRow(rowData)}>Delete</Button>
            </ButtonGroup>
          </div>
        );
      },
    },
    {
      title: 'No of Communities',
      field: 'communities',
      render: (rowData) => rowData.communities.length,
    },
    {
      title: 'No of Homes',
      field: 'communities',
      render: (rowData) => rowData.communities.length,
    },
  ];

  const deleteRow = async (oldData) => {
    const response = await api.delete(`/homebuilders/${oldData._id}`);
    if (response.status === 200) {
      setData((data) => {
        const index = oldData.tableData.id;
        const updatedRows = [...data];
        updatedRows.splice(index, 1);
        return updatedRows;
      });
    }
  };

  const addRow = async (newData) => {
    console.log(newData);
    setData([...data, newData]);
    await api.post(`/homebuilders`, newData);
  };

  const updateRow = async (newData, oldData) => {
    const index = oldData.tableData.id;
    const updatedRows = [...data];
    updatedRows[index] = newData;
    setData(updatedRows);
    await api.patch(`/homebuilders/${oldData._id}`, newData);
  };

  return (
    <>
      <Grid containter className={classes.grid}>
        <Grid item style={{ marginBottom: '20px' }}>
          <Button variant='contained'>
            <Link style={{ textDecoration: 'none' }} to='/homebuilderform'>
              Add Homebuilder
            </Link>
          </Button>
        </Grid>
        <Grid item>
          <Datatable
            title={title}
            data={data}
            isPending={homebuildersData.isPending}
            error={homebuildersData.error}
            columns={columns}
            delete={deleteRow}
            create={addRow}
            update={updateRow}
            component='homebuilder'
          />
        </Grid>
      </Grid>
    </>
  );
}

export default Homebuilders;
