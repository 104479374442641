import { useState, useEffect } from 'react';
import { getFiles, deleteFile } from '../../services/filesService';

const useFileManager = (req, id, refresh, setRefresh) => {
  const [docs, setDocs] = useState([]);

  const getAllFiles = async () => {
    const allFiles = await getFiles();
    setDocs(allFiles);
  };

  const deleteSingle = async (id) => {
    const allFiles = await deleteFile(id);
    console.log(allFiles);
    // setDocs(allFiles);
    // setReq('fetch');
    // setId(null);
    // return;
    // window.location.reload(false);

    // getAllFiles();
  };

  useEffect(() => {
    const fetch = getAllFiles();
    const deleteSingle = deleteFile(id);

    if (req === 'delete' && id !== null) {
      return () => deleteFile(id);
    }
    return () => getAllFiles();
  }, [req, id, refresh]);

  return {
    docs,
  };
};

export default useFileManager;
