import { useState, useEffect } from 'react';
import { filesUpload } from '../../services/filesService';

const useUpload = (files, refresh, setRefresh) => {
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState(null);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const uploadOptions = {
    onUploadProgress: (ProgressEvent) => {
      const { loaded, total } = ProgressEvent;
      const percentage = Math.floor(((loaded / 1000) * 100) / (total / 1000));
      setProgress(percentage);
    },
  };
  const uploadFiles = async () => {
    console.log('files: ', files);
    const formData = new FormData();
    // formData.append('title', 'Upload Files');
    for (let i = 0; i < files.length; i++) {
      console.log(files[i]);
      formData.append('files', files[i]);
    }

    // console.log('formData: ', formData);
    const uploadResponse = await filesUpload(formData);
    // console.log('files: ', files);
    console.log('upload response: ', uploadResponse);

    return uploadResponse.data;
  };

  useEffect(() => {
    const uploadResponse = uploadFiles();
    console.log('uploaded res: ', uploadResponse);
    if (uploadResponse.status === 0) {
      setUploadedFiles([]);
      setError(uploadResponse.message);
      return;
    }
    setError(null);
    setUploadedFiles(uploadResponse.data);
    window.location.reload(false);
  }, [files]);
  // useEffect(() => {
  //   // reference
  //   const storageRef = projectStorage.ref(file.name);
  //   const collectionRef = projectFirestore.collection('files');

  //   storageRef.put(file).on(
  //     'state_changed',
  //     (snap) => {
  //       let percentage = (snap.bytesTransferred / snap.totalBytes) * 100;
  //       setProgress(percentage);
  //     },
  //     (err) => {
  //       setError(err);
  //     },
  //     async () => {
  //       const url = await storageRef.getDownloadURL();
  //       const createdAt = timestamp();
  //       collectionRef.add({ url, createdAt });
  //       setUrl(url);
  //     }
  //   );
  // }, [file]);

  return {
    progress,
    error,
    uploadedFiles,
  };
};

export default useUpload;
