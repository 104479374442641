import React from 'react';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import MenuIcon from '@material-ui/icons/Menu';
import { FormControlLabel, Checkbox, TextField, Typography,
    Container, Paper, Grid, AppBar, IconButton, Toolbar } from '@material-ui/core';

import { makeStyles, ThemeProvider, createMuiTheme } from '@material-ui/core/styles';

const useStyles = makeStyles({
    root: {
        background: 'linear-gradient(45deg, #FB6B3B, #FF8E53)',
        border: 0,
        marginBottom: 15,
        borderRadius: 15,
        color: 'white',
        padding: '5px 30px'
    }
});

const theme = createMuiTheme({
    typography:{
        h3: {
            fontSize: 28,
            marginBottom: 15
        },
    },
    
})

function ButtonStyled() {
    const classes = useStyles();
    return <Button className={classes.root}>Custom Styled Button</Button>
}

function CheckboxExample() {
    const [checked, setChecked] = React.useState(true);
    return (
        <div>
            <FormControlLabel control={
                <Checkbox
                color="secondary"
                checked={checked}
                icon={<FavoriteIcon />}
                checkedIcon={<FavoriteIcon />}
                onChange={(e) => setChecked(e.target.checked)}
                inputProps={{
                    "aria-label": 'secondary checkbox'
                }}
                 />
            }
            label="Testing Checkbox" />
            
        </div>
    )
}

function MaterialUi() {
    return (
        <ThemeProvider theme={theme}>
            <AppBar color="secondary">
                <Toolbar>
                    <IconButton>
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6">
                        MUI Theming
                    </Typography>
                    <Button>
                        Login
                    </Button>
                </Toolbar>
            </AppBar>
            <Container maxWidth="xs">
            <Typography variant="h3">
                Welcome to material ui
            </Typography>
            <Typography variant="subtitle1">
                Learn how to use MUI
            </Typography>
            <ButtonStyled />
            <Grid container spacing={2} justify="center"  >
                <Grid item xs={3} sm={6}>
                    <Paper style={{height: 75, width: '100%'}} />
                </Grid>
                <Grid item xs={3} sm={6}>
                    <Paper style={{height: 75, width: '100%'}} />
                </Grid>
                <Grid item xs={3} sm={6}>
                    <Paper style={{height: 75, width: '100%'}} />
                </Grid>
            </Grid>
            
            <TextField
            type="email"
            variant="filled"
            color="secondary"
            label="Enter email"
            placeholder="test@gmail.com" />
            <CheckboxExample />
            
            <ButtonGroup variant="contained" color="primary">
                <Button
                endIcon={<ArrowDropDown />}
                >
                    Homebuilders
                </Button>
                <Button>
                    Go
                </Button>
            </ButtonGroup>
            </Container>
        </ThemeProvider>
    )
}

export default MaterialUi
