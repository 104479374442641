import React from 'react';
import { Grid } from '@material-ui/core';
import { useForm, Form } from '../components/useForm';
import Controls from '../hooks/controls/Controls';
import * as employeeService from '../services/employeesService';

const initialFValues = {
  id: 0,
  fullName: '',
  email: '',
  mobile: '',
  city: '',
  gender: 'male',
  departmentId: '',
  hireDate: new Date(),
  isPermanent: false,
};

const genderItems = [
  { id: 'male', title: 'Male' },
  { id: 'female', title: 'Female' },
  { id: 'other', title: 'Other' },
];

export default function EmployeesForm() {
  const { values, setValues, handleInputChange } = useForm(initialFValues);

  return (
    <Form>
      <Grid container>
        <Grid item xs={6}>
          <Controls.Input
            name='fullName'
            label='Full Name'
            value={values.fullName}
            onChange={handleInputChange}
          />
          <Controls.Input
            label='Email'
            name='email'
            value={values.email}
            onChange={handleInputChange}
          />
          <Controls.Input
            label='Mobile'
            name='mobile'
            value={values.mobile}
            onChange={handleInputChange}
          />
          <Controls.Input
            label='City'
            name='city'
            value={values.city}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={6}>
          <Controls.RadioGroupI
            name='gender'
            label='Gender'
            value={values.gender}
            onChange={handleInputChange}
            items={genderItems}
          />
          <Controls.Select
            name='departmentId'
            value={values.departmentId}
            label='Department'
            onChange={handleInputChange}
            options={employeeService.getDepartmentCollection()}
          />
          <Controls.DatePicker
            label='Hire Date'
            name='hireDate'
            value={values.hireDate}
            onChange={handleInputChange}
          />
          <Controls.Checkbox
            label='Employee Permanent'
            name='isPermanent'
            value={values.isPermanent}
            onChange={handleInputChange}
          />
          <div>
            <Controls.Button text='Submit' size='large' type='submit' />
            <Controls.Button
              text='Reset'
              color='Secondary'
              size='large'
              type='submit'
            />
          </div>
        </Grid>
      </Grid>
    </Form>
  );
}
