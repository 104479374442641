import api from '../hooks/Api';

export const filesUpload = async (data) => {
  try {
    const res = await api.post('/files/upload', data);
    console.log('res: ', res);
    return res;
  } catch (error) {
    throw error;
  }
};

export const getFiles = async () => {
  try {
    const { data } = await api.get('/files/fetch');
    return data;
  } catch (error) {
    throw error;
  }
};

export const getSingleFile = async (id) => {
  try {
    const { data } = await api.get(`/files/fetch/${id}`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const deleteFile = async (id) => {
  try {
    console.log('id: ', id);
    const response = await api.delete(`/files/${id}`);
    // console.log('delete response: ', response);
    return response;
  } catch (error) {
    throw error;
  }
};
