import React, { useState, useEffect } from 'react';
import api from '../hooks/Api';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import useFileManager from '../hooks/files/useFileManager';
import {
  Grid,
  TextField,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  makeStyles,
  Paper,
  Typography,
  IconButton,
} from '@material-ui/core';
import { Add, Remove } from '@material-ui/icons';
import FilesModalDilog from './files/FilesModalDialog';
import { checkKeyExists } from '../helpers/helpers';
import Config from '../config';

const useStyles = makeStyles((theme) => ({
  pageContent: {
    marging: theme.spacing(5),
    padding: theme.spacing(3),
  },
  card: {
    minWidth: 220,
    minHeight: 300,
    backgroundColor: 'lightgray',
  },
  media: {
    height: 140,
  },
}));

const FloorPlansForm = () => {
  const [refresh, setRefersh] = useState(false);
  // here useFileManager hook first arguments is request that can be fetch, delete or update as value
  // param will be null when request is fetch else param will be a
  // parameter on which delete or update of a file will be executed
  // refresh and setRefresh are state variable by which we change state of page and refresh it
  const { docs } = useFileManager('fetch', null, refresh, setRefersh);

  const classes = useStyles();

  let history = useHistory();

  const { homeId } = useParams();

  const [formValues, setFormValues] = useState([
    {
      name: '',
      imagePath: '',
      noOfPlanOptions: 1,
      status: 1,
      homeId: 1,
      homebuilderId: '',
      createdBy: 1,
      updatedBy: 1,
    },
  ]);

  const [numberOfFloorPlans, setNumberOfFloorPlans] = useState(1);

  const [open, setOpen] = React.useState(false);

  const [clickedFloorPlanGridIndex, setClickedFloorPlanGridIndex] =
    useState(null);

  const [selectedImg, setSelectedImg] = useState([
    {
      id: '',
      imagePath: '',
    },
  ]);

  const [homebuilderId, setHomebuilderId] = useState('');

  const [deletedFormValues, setDeletedFormValues] = useState([]);

  const urlSegment = useLocation();
  const requestType = urlSegment.pathname.split('/')[2]
    ? urlSegment.pathname.split('/')[2]
    : '';

  const { id } = useParams();

  const getHome = async () => {
    const response = await api.get(`/homes/${homeId}`);
    return response.data;
  };

  const getFloorPlan = async () => {
    const FloorPlan = await api.get(`/floorplans/${id}`);
    return FloorPlan.data;
  };

  const getFloorPlansByHome = async () => {
    const FloorPlans = await api.get(`/floorplans/home/${homeId}`);
    return FloorPlans.data;
  };
  const getAllFloorPlans = async () => {
    const allFloorPlans = await getFloorPlansByHome();
    // console.log('floorplans: ', allFloorPlans);
    if (allFloorPlans.length > 0) {
      const newImgsArr = [];
      // console.log(allFloorPlans.image);
      for (let i = 0; i < allFloorPlans.length; i++) {
        newImgsArr[i] = {
          id: allFloorPlans[i]._id,
          imagePath: allFloorPlans[i].imagePath,
        };
      }
      // const [ image ] = allFloorPlans;
      setNumberOfFloorPlans(allFloorPlans.length);
      setSelectedImg(newImgsArr);
      setFormValues(allFloorPlans);
    } else {
      const getSingleHome = await getHome();
      // console.log(getSingleElevation.homebuilder_id);

      setHomebuilderId(getSingleHome.homebuilder_id);
      setNumberOfFloorPlans(getSingleHome.noOfFloorPlans);
      makeFloorPlanGrids(getSingleHome.noOfFloorPlans);
    }
  };

  const handleClickOpen = (index) => {
    // console.log(index);
    setClickedFloorPlanGridIndex((current) => (current = index));
    setOpen(true);
  };

  const FloorPlan = async () => {
    const getSingleFloorPlan = await getFloorPlan();
    // console.log(getSingleFloorPlan)
    if (getSingleFloorPlan) {
      // console.log(selectedImg);
      // setSelectedImg(current => {current[0]['id'] = ''; current[0]['url'] = getSingleFloorPlan.image; } )
      const newImgsArray = [...selectedImg];
      newImgsArray[0]['id'] = '';
      newImgsArray[0]['imagePath'] = getSingleFloorPlan.imagePath;
      // console.log(newImgsArray);
      setSelectedImg(newImgsArray);
      // console.log(selectedImg);
      setFormValues([getSingleFloorPlan]);
    }
  };

  useEffect(() => {
    if (homeId !== undefined) {
      getAllFloorPlans();
    }

    if (requestType !== '' && homeId === undefined) {
      FloorPlan();
    }
  }, []);

  const makeFloorPlanGrids = (noOfFloorPlans) => {
    // console.log('no of grids:', noOfFloorPlans);
    // console.log(homebuilderId);
    const newFormValuesArray = [];
    const newImgsArr = [];
    for (let i = 0; i < noOfFloorPlans; i++) {
      // console.log(homebuilderId);

      newFormValuesArray[i] = {
        name: '',
        imagePath: '',
        noOfPlanOptions: 1,
        status: 1,
        homeId: homeId,
        homebuilderId: homebuilderId,
        createdBy: 1,
        updatedBy: 1,
      };
      newImgsArr[i] = {
        id: '',
        imagePath: '',
      };
    }
    setSelectedImg(newImgsArr);
    setFormValues(newFormValuesArray);
    // console.log(formValues);
  };

  const handleInputChange = (index, e) => {
    const values = [...formValues];
    values[index][e.target.name] = e.target.value;
    setFormValues(values);
  };

  const handleClickImage = (doc) => {
    const newImgsArray = [...selectedImg];
    newImgsArray[clickedFloorPlanGridIndex].id = doc._id;
    newImgsArray[clickedFloorPlanGridIndex].imagePath = doc.path;
    console.log('selected image: ', newImgsArray);
    // setSelectedImg(newImgsArray);

    const values = [...formValues];
    values[clickedFloorPlanGridIndex]['imagePath'] =
      selectedImg[clickedFloorPlanGridIndex]['imagePath'];
    setFormValues(values);
    setOpen(false);
    // console.log(selectedImg);
    // console.log(values);
    console.log(formValues);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    // console.log('form values:', formValues);
    // single record edit
    if (
      requestType === 'edit' &&
      urlSegment.pathname.split('/')[3] !== 'home' &&
      homebuilderId === ''
    ) {
      await api.patch(`/floorplans/${id}`, formValues[0]);
    }
    // multiple records edit
    else if (
      requestType === 'edit' &&
      urlSegment.pathname.split('/')[3] === 'home' &&
      homebuilderId === ''
    ) {
      // filter old form values that are exists
      const oldFormValues = formValues.filter((obj) =>
        checkKeyExists(obj, '_id')
      );
      // filter new form values that are not exists
      const newFormValues = formValues.filter(
        (obj) => !checkKeyExists(obj, '_id')
      );
      // excuting patch request for muliple edit
      if (oldFormValues.length > 0) {
        const updateFloorPlans = await api.patch(
          `/floorplans/home/${homeId}`,
          oldFormValues
        );
        // console.log(`updated: ${updateFloorPlans}`);
      }
      // executing post request to add new record while editing multiple records
      if (newFormValues.length > 0) {
        const createFloorPlans = await api.post('/floorplans', newFormValues);
        // console.log(`created: ${createFloorPlans}`);
      }

      // executing patch request to soft delete a record
      if (deletedFormValues.length > 0) {
        const deletedFloorPlans = await api.patch(
          `/floorplans/home/${homeId}`,
          deletedFormValues
        );
      }
      const homesResponse = await api.patch(`/homes/${homeId}`, {
        noOfFloorPlans: numberOfFloorPlans,
      });
      console.log(`homes: ${homesResponse}`);
    }
    // single or multiple records add
    else {
      await api.post('/floorplans', formValues);
    }
    // nagigate to floor plans data table page
    history.push('/floorplans');
  };

  const handleAddGrid = () => {
    setFormValues([
      ...formValues,
      {
        name: '',
        imagePath: '',
        noOfPlanOptions: 1,
        status: 1,
        homeId: homeId,
        homebuilderId: formValues[0].homebuilderId,
        createdBy: 1,
        updatedBy: 1,
      },
    ]);
    setSelectedImg([
      ...selectedImg,
      {
        id: '',
        imagePath: '',
      },
    ]);
    setNumberOfFloorPlans((currentValue) => currentValue + 1);
  };

  const handleRemoveGrid = (e, index) => {
    const newFormValues = [...formValues];
    const selectedImgValues = [...selectedImg];

    let newArr = newFormValues.splice(index, 1);
    newArr[0].status = 0;
    setDeletedFormValues((current) => {
      current = deletedFormValues;
      current.push(newArr[0]);
      return current;
    });
    selectedImgValues.splice(index, 1);

    setFormValues(newFormValues);
    setSelectedImg(selectedImgValues);

    setNumberOfFloorPlans((currentValue) => currentValue - 1);
  };

  return (
    <div>
      <Grid container>
        <Grid item container>
          <Grid item container spacing={2}>
            <Grid item>
              <Button variant='contained' size='large'>
                <Link to='/floorplansform'>Add FloorPlan</Link>
              </Button>
            </Grid>
            <Grid item>
              <Button variant='contained' size='large'>
                <Link to='/floorplans'>View All FloorPlans</Link>
              </Button>
            </Grid>
            <Grid item>
              <Button variant='contained' size='large'>
                <Link to={`/floorplans/edit/${id}`}>Edit FloorPlan</Link>
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <form onSubmit={handleSubmit}>
        <Paper className={classes.pageContent} style={{ marginTop: '10px' }}>
          <Grid container>
            {formValues.map((formValue, index) => (
              <Grid key={index} item container xs={3}>
                <Grid item container>
                  <Grid item>
                    <Card
                      onClick={
                        requestType === 'view'
                          ? null
                          : () => handleClickOpen(index)
                      }
                      style={{
                        minWidth: 180,
                        minHeight: 260,
                        textAlign: 'center',
                      }}
                    >
                      <CardActionArea>
                        {selectedImg[index]['imagePath'] ? (
                          <CardMedia
                            component='img'
                            // className={classes.media}
                            style={{ height: '100%' }}
                            image={
                              Config.baseURL + selectedImg[index]['imagePath']
                            }
                            title='uploaded pic'
                          />
                        ) : (
                          <CardContent>
                            <Typography>
                              Add/Display FloorPlan Image {index + 1}
                            </Typography>
                          </CardContent>
                        )}
                      </CardActionArea>
                    </Card>
                  </Grid>
                  <Grid item>
                    <Card
                      style={{
                        minWidth: 220,
                        minHeight: 50,
                        backgroundColor: 'lightgray',
                        marginTop: '15px',
                        textAlign: 'center',
                      }}
                    >
                      <TextField
                        label='Floor Plan Name'
                        value={formValue.name}
                        name='name'
                        variant='outlined'
                        onChange={(e) => handleInputChange(index, e)}
                        inputProps={{
                          readOnly: requestType === 'view' ? true : false,
                        }}
                      />
                    </Card>
                  </Grid>
                  <Grid item container style={{ marginTop: '10px' }}>
                    <Grid item xs={6}>
                      <Typography>No. of plan options</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        label=''
                        name='noOfPlanOptions'
                        value={formValue.noOfPlanOptions}
                        variant='outlined'
                        type='number'
                        onChange={(e) => handleInputChange(index, e)}
                        inputProps={{
                          readOnly: requestType === 'view' ? true : false,
                        }}
                      />
                    </Grid>
                  </Grid>

                  {requestType === 'edit' ? (
                    <Grid item container style={{ marginTop: '10px' }}>
                      <Grid item xs={6}>
                        <IconButton onClick={(e) => handleRemoveGrid(e, index)}>
                          <Remove />
                        </IconButton>
                      </Grid>
                      <Grid item xs={6}>
                        <IconButton onClick={() => handleAddGrid()}>
                          <Add />
                        </IconButton>
                      </Grid>
                    </Grid>
                  ) : (
                    ''
                  )}
                </Grid>
              </Grid>
            ))}
          </Grid>
          <Grid container spacing={2} style={{ marginTop: '10px' }}>
            <Grid item>
              <Button
                variant='contained'
                color='primary'
                type='submit'
                onClick={handleSubmit}
                disabled={requestType === 'view' ?? true}
              >
                Save
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant='contained'
                color='primary'
                type='button'
                disabled={requestType === 'view' ?? true}
              >
                Clear
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </form>
      <FilesModalDilog
        files={docs}
        open={open}
        setOpen={setOpen}
        handleClickFile={handleClickImage}
      />
    </div>
  );
};

export default FloorPlansForm;
