import firebase from 'firebase/app';
import 'firebase/storage';
import 'firebase/firestore';

// Your web app's Firebase configuration
var firebaseConfig = {
  apiKey: 'AIzaSyBTW5usvsb6L_dLGzasZxzNgusDZy_0wCM',
  authDomain: 'media-library-5b5df.firebaseapp.com',
  projectId: 'media-library-5b5df',
  storageBucket: 'media-library-5b5df.appspot.com',
  messagingSenderId: '1016000461205',
  appId: '1:1016000461205:web:a948e7b8eef4d8a09b1e9c',
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const projectStorage = firebase.storage();
const projectFirestore = firebase.firestore();
const timestamp = firebase.firestore.FieldValue.serverTimestamp;

export { projectStorage, projectFirestore, timestamp };
