import { FormControl, FormControlLabel, Switch as MuiSwitch } from '@material-ui/core';
import React from 'react'

export default function Switch(props) {
    const { name, value, label, onChange, ...other } = props;
    const convertToDefEventPara = (name, value) => ({
        
        target : {
            name, value
        }
    })
    return (
        <FormControl>
            <FormControlLabel
            label={label}
            control={<MuiSwitch {...other} name={name}
            checked={value}
            onChange={e => onChange(convertToDefEventPara(name, e.target.checked))}
             />} {...other} />
        </FormControl>
    )
}
