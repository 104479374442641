import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Container, Button, ButtonGroup, Avatar } from '@material-ui/core';
import api from '../hooks/Api';
import Datatable from '../hooks/Datatable';

require('es6-promise').polyfill();
require('isomorphic-fetch');

const title = 'Furnitures';

function Furnitures() {
  const [data, setData] = useState([]);

  const deleteRow = async (oldData) => {
    // console.log(oldData);
    const response = await api.delete(`/furnitures/${oldData._id}`);
    // console.log(response.data);
    if (response.status === 200) {
      setData((data) => {
        const index = oldData.tableData.id;
        const updatedRows = [...data];
        // let newData = data.filter((newData) => newData._id !== oldData._id);
        updatedRows.splice(index, 1);
        return updatedRows;
      });
    }
  };
  const columns = [
    {
      title: 'Name',
      field: 'name',
    },
    {
      title: 'Image',
      field: 'image',
      render: (rowData) => <Avatar alt='Uploaded pic' src={rowData.image} />,
    },
    {
      title: 'View/Edit Home',
      field: '_id',
      render: (rowData) => {
        return (
          <div>
            <ButtonGroup
              variant='text'
              color='primary'
              aria-label='text primary button group'
            >
              <Button>
                <Link to={`/furnitures/view/${rowData._id}`}>View</Link>
              </Button>
              <Button>
                <Link to={`/furnitures/edit/${rowData._id}`}> Edit </Link>
              </Button>
              <Button onClick={() => deleteRow(rowData)}>Delete</Button>
            </ButtonGroup>
          </div>
        );
      },
    },
  ];

  const retreveFurnitures = async () => {
    const response = await api.get('/furnitures');
    return response.data;
  };

  useEffect(() => {
    const getAllFurnitures = async () => {
      const allfurnitures = await retreveFurnitures();
      if (allfurnitures) setData(allfurnitures);
    };
    getAllFurnitures();
  }, []);

  return (
    <>
      <Container>
        <Button variant='contained'>
          <Link to='/furnituresform'>Add New</Link>
        </Button>
      </Container>
      <Datatable
        title={title}
        data={data}
        columns={columns}
        delete={deleteRow}
        component='furnitures'
      />
    </>
  );
}

export default Furnitures;
