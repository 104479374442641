import React from 'react';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import {
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Grid,
  Card,
  CardHeader,
  CardActionArea,
  CardMedia,
  Switch,
  TextField,
  Button,
  CardContent,
  Typography,
  Stack,
  Alert,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import Delete from '@mui/icons-material/Delete';
import Add from '@mui/icons-material/Add';

import {
  getFurnitureCategories,
  createFurnitureCategory,
} from '../services/furnitureCategoriesService';
import {
  getFurnituresByCategory,
  createFurnitures,
  deleteFurniture,
  deleteManyFurnitures,
  updateManyFurnitures,
} from '../services/furnituresService';
import useFileManager from '../hooks/files/useFileManager';
import FilesModalDilog from './files/FilesModalDialog';
import Config from '../config';
import { checkKeyExists } from '../helpers/helpers';
import { styled } from '@mui/material/styles';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import SimpleAlertDialog from './SimpleAlertDialog';

const DeleteButton = styled(IconButton)({
  position: 'absolute',
  right: '-20px',
  top: '5px',
  zIndex: 1,
  backgroundColor: '#FF5252',
  color: '#ffffff',
  '&:hover': {
    backgroundColor: 'transparent',
    color: '#FF5252',
  },
});

const FurniturePlanner = () => {
  const [refresh, setRefersh] = React.useState(false);
  // here useFileManager hook first arguments is request that can be fetch, delete or update as value
  // param will be null when request is fetch else param will be a
  // parameter on which delete or update of a file will be executed
  // refresh and setRefresh are state variable by which we change state of page and refresh it
  const { docs } = useFileManager('fetch', null, refresh, setRefersh);
  let history = useHistory();
  const [formValues, setFormValues] = React.useState([
    {
      name: '',
      imagePath: '',
      width: 10,
      height: 10,
      furnitureCategoryId: '',
      status: 1,
    },
  ]);
  const [deletedFormValues, setDeletedFormValues] = React.useState([]);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [categories, setCategories] = React.useState([]);
  const [selectedCategory, setSelectedCategory] = React.useState({});
  const [selectedFurniture, setSelectedFurniture] = React.useState({});
  const [furnitureEditing, setFurnitureEditing] = React.useState(false);

  const [showDelete, setShowDelete] = React.useState(false);
  const [message, setMessage] = React.useState('');

  // const [furnitures, setFurnitures] = React.useState([]);

  const [newCategory, setNewCategory] = React.useState({
    name: '',
  });

  const [selectedImg, setSelectedImg] = React.useState([
    {
      id: '',
      imagePath: '',
    },
  ]);

  const [open, setOpen] = React.useState(false);
  const [openAlert, setOpenAlert] = React.useState(false);

  const [clickedFurnitureGridIndex, setClickedFurnitureGridIndex] =
    React.useState(null);
  const handleInputChange = (index, e) => {
    const values = [...formValues];
    values[index][e.target.name] = e.target.value;
    setFormValues(values);
  };

  const handleClickOpen = (index) => {
    // console.log(index);
    setClickedFurnitureGridIndex(index);
    setOpen(true);
  };

  const handleSetFurniture = (furniture) => {
    setSelectedFurniture(furniture);
    setOpenAlert(true);
  };

  const handleDeleteFurniture = async () => {
    const res = await deleteFurniture(selectedFurniture._id);
    console.log('deleted response: ', res);
    if (res.status === 200) {
      setMessage('Furniture deleted successfully!');
      setFormValues(
        formValues.filter(
          (furniture) => furniture._id !== selectedFurniture._id
        )
      );
      setOpenAlert(false);
    }
  };

  const handleClickImage = (doc) => {
    const newImgsArray = [...selectedImg];
    newImgsArray[clickedFurnitureGridIndex].id = doc._id;
    newImgsArray[clickedFurnitureGridIndex].imagePath = doc.path;
    console.log(newImgsArray);
    setSelectedImg(newImgsArray);
    // setSelectedImg(current => {
    //   current[clickedFurnitureGridIndex]['id'] = doc.id;
    //   current[clickedFurnitureGridIndex]['url'] = doc.url;
    // });

    const values = [...formValues];

    console.log('formvalues: ', values);

    values[clickedFurnitureGridIndex]['imagePath'] =
      selectedImg[clickedFurnitureGridIndex]['imagePath'];
    values[clickedFurnitureGridIndex]['furnitureCategoryId'] =
      selectedCategory._id;
    // console.log(
    //   'selected grid image g: ',
    //   values[clickedFurnitureGridIndex]['imagePath']
    // );
    setFormValues(values);
    setOpen(false);
    // console.log(selectedImg);
    // console.log(values);
    console.log(formValues);
  };

  const getAllCategories = async () => {
    const fcategories = await getFurnitureCategories();
    // console.log('furniture categories', fcategories);
    return fcategories;
  };

  const getAllFurnituresByCategory = async () => {
    // console.log('selected category: ', selectedCategory);
    const furnitures = await getFurnituresByCategory(selectedCategory._id);
    // console.log('furniture by category', furnitures);
    return furnitures;
  };

  React.useEffect(() => {
    const getCategories = async () => {
      const furnitureCategories = await getAllCategories();
      if (furnitureCategories) {
        setCategories(furnitureCategories);
        setSelectedCategory(furnitureCategories[0]);
      }
    };

    getCategories();
  }, []);

  React.useEffect(() => {
    const getFurnituresByCategory = async () => {
      const data = await getAllFurnituresByCategory();
      console.log('data furn: ', data);

      // setSelectedImg(data);

      // setFormValues((prev) => (prev = data));

      if (data.length > 0) {
        const newImgsArr = [];
        // console.log(data.image);
        for (let i = 0; i < data.length; i++) {
          newImgsArr[i] = {
            id: data[i]._id,
            imagePath: data[i].imagePath,
          };
        }
        setSelectedImg(newImgsArr);
        setFormValues(data);

        // const [ image ] = data;
        // setNumberOfFloorOptions(data.length);
      } else {
        setSelectedImg([
          {
            id: '',
            imagePath: '',
          },
        ]);
        setFormValues([
          {
            name: '',
            imagePath: '',
            width: 10,
            height: 10,
            furnitureCategoryId: '',
            status: 1,
          },
        ]);
      }
    };
    getFurnituresByCategory();
    setMessage('');
    return () => {
      setSelectedImg([
        {
          id: '',
          imagePath: '',
        },
      ]);
      setFormValues([
        {
          name: '',
          imagePath: '',
          width: 10,
          height: 10,
          furnitureCategoryId: '',
          status: 1,
        },
      ]);
    };
  }, [selectedCategory]);

  // React.useEffect(() => {}, [formValues]);

  const handleListItemClick = (event, index) => {
    if (index < categories.length) {
      setSelectedIndex(index);
      setSelectedCategory(categories[index]);
    } else {
      setSelectedIndex(index);
    }
  };
  const handleNewCategoryChange = (e) => {
    // console.log(e);
    setNewCategory({ ...newCategory, name: e.target.value });
  };
  const addFurnitureCategory = async (data) => {
    await createFurnitureCategory(data);
  };
  const addCategory = (e, index) => {
    const response = addFurnitureCategory(newCategory);
    if (response.status === 201) {
      setCategories([...categories, newCategory]);
      setSelectedIndex(index - 1);
      setSelectedCategory(categories[index - 1]);
    }
  };

  const handleAddGrid = () => {
    setFormValues([
      ...formValues,
      {
        name: '',
        imagePath: '',
        width: 10,
        height: 10,
        furnitureCategoryId: '',
        status: 1,
      },
    ]);
    setSelectedImg([
      ...selectedImg,
      {
        id: '',
        imagePath: '',
      },
    ]);

    // setNumberOfFloorOptions((currentValue) => currentValue + 1);
  };

  const handleRemoveGrid = (e, index) => {
    const newFormValues = [...formValues];
    const selectedImgValues = [...selectedImg];

    let newArr = newFormValues.splice(index, 1);
    // newArr[0].status = 0;
    // newArr[0].deleted = true;

    setDeletedFormValues((current) => {
      current = deletedFormValues;
      current.push(newArr[0]);
      return current;
    });
    selectedImgValues.splice(index, 1);

    setFormValues(newFormValues);
    setSelectedImg(selectedImgValues);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    // console.log(
    //   'deleted form values: ',
    //   deletedFormValues.filter((obj) => checkKeyExists(obj, '_id'))
    // );
    // deleted
    const existingDeletedFormvalues = deletedFormValues.filter((obj) =>
      checkKeyExists(obj, '_id')
    );
    const oldFormValues = formValues.filter((obj) =>
      checkKeyExists(obj, '_id')
    );

    const newFormValues = formValues.filter(
      (obj) => !checkKeyExists(obj, '_id')
    );
    console.log('added furnitures: ', newFormValues);
    console.log('edited furnitures: ', oldFormValues);
    if (newFormValues.length > 0) {
      const createdFurnituresResponse = await createFurnitures(newFormValues);
      if (createdFurnituresResponse.status === 201) {
        setMessage('Furnitures Updated Successfully!');
        console.log('created: ', createdFurnituresResponse.data);
      }
    }
    if (oldFormValues > 0) {
      const editedFurnituresResponse = await updateManyFurnitures(
        selectedCategory._id,
        oldFormValues
      );
      if (editedFurnituresResponse.status === 200) {
        setMessage('Furnitures Updated Successfully!');
        console.log('created: ', editedFurnituresResponse.data);
      }
    }
    if (existingDeletedFormvalues.length > 0) {
      const deletedResponse = await deleteManyFurnitures(
        existingDeletedFormvalues
      );
      if (deletedResponse.status === 200) {
        setMessage('Furnitures Deleted Successfully!');
        setDeletedFormValues([]);
        console.log('created: ', deletedResponse.data);
      }
    }

    // const deleteResponse = await deleteManyFurnitures(
    //   existingDeletedFormvalues
    // );
    // if (deleteResponse.status === 200) {
    //   setMessage('Furnitures Deleted Successfully!');
    //   setDeletedFormValues([]);
    // }
    // const newFormValues = formValues.filter(
    //   (obj) => !checkKeyExists(obj, '_id')
    // );
    // console.log('new form values: ', newFormValues);
    // const furnitureResponse = await createFurnitures(newFormValues);
    // if (furnitureResponse.status === 201) {
    //   // console.log('furnitures after creation: ', furnitureResponse.data);
    //   window.location.reload(false);
    // }
    // console.log('submitted furnitures: ', formValues);
  };

  return (
    <>
      <Grid container>
        <Grid item xs={3}>
          <h2>Furniture Template</h2>
        </Grid>
        <Grid item xs={6}>
          {message !== '' ? <Alert severity='success'>{message}</Alert> : ''}
        </Grid>
        <Grid item xs={3}>
          <Stack
            direction='row'
            justifyContent='flex-end'
            alignItems='center'
            spacing={2}
          >
            <IconButton
              className='card-corner-button'
              aria-label='Remove'
              onClick={(e) => setFurnitureEditing(!furnitureEditing)}
            >
              <ModeEditIcon fontSize='inherit' />
            </IconButton>
            <IconButton
              className='card-corner-button'
              aria-label='Remove'
              onClick={(e) => setShowDelete(!showDelete)}
            >
              <Delete fontSize='inherit' />
            </IconButton>
          </Stack>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={3}>
          <Box
            sx={{
              width: '100%',
              minHeight: 500,
              bgcolor: 'background.paper',
            }}
          >
            <List component='nav'>
              {categories.map((category, index) =>
                furnitureEditing ? (
                  <ListItemButton
                    key={category._id}
                    selected={selectedIndex === index}
                    onClick={(event) => handleListItemClick(event, index)}
                  >
                    <ListItemText
                      primary={
                        <FormControl sx={{ width: '30ch' }} variant='outlined'>
                          <InputLabel htmlFor='outlined-adornment-category'>
                            {category.name}
                          </InputLabel>
                          <OutlinedInput
                            id='outlined-adornment-category'
                            type='text'
                            value={category.name}
                            // onChange={handleNewCategoryChange}
                            // endAdornment={
                            //   <InputAdornment position='end'>
                            //     <IconButton
                            //       aria-label='new furniture category'
                            //       onClick={(e) =>
                            //         addCategory(e, categories.length)
                            //       }
                            //       edge='end'
                            //     >
                            //       <CheckIcon />
                            //     </IconButton>
                            //   </InputAdornment>
                            // }
                            label='Category'
                          />
                        </FormControl>
                      }
                    />
                  </ListItemButton>
                ) : (
                  <ListItemButton
                    key={category._id}
                    selected={selectedIndex === index}
                    onClick={(event) => handleListItemClick(event, index)}
                  >
                    <ListItemText primary={category.name} />
                    <IconButton
                      style={{
                        display: showDelete ? 'block' : 'none',
                        backgroundColor: '#FF5252',
                        color: '#ffffff',
                      }}
                      size='small'
                      className='card-corner-button'
                      aria-label='Remove'
                      // onClick={(e) => handleSetFurniture(furniture)}
                      onClick={(e) => handleRemoveGrid(e, index)}
                    >
                      <Delete fontSize='inherit' />
                    </IconButton>
                  </ListItemButton>
                )
              )}

              <ListItemButton
                selected={selectedIndex === categories.length}
                onClick={(event) =>
                  handleListItemClick(event, categories.length)
                }
              >
                <ListItemText
                  sx={{ m: 0, p: 0 }}
                  primary={
                    selectedIndex === categories.length ? (
                      <FormControl sx={{ width: '30ch' }} variant='outlined'>
                        <InputLabel htmlFor='outlined-adornment-category'>
                          Category
                        </InputLabel>
                        <OutlinedInput
                          id='outlined-adornment-category'
                          type='text'
                          value={newCategory.name}
                          onChange={handleNewCategoryChange}
                          endAdornment={
                            <InputAdornment position='end'>
                              <IconButton
                                aria-label='new furniture category'
                                onClick={(e) =>
                                  addCategory(e, categories.length)
                                }
                                edge='end'
                              >
                                <CheckIcon />
                              </IconButton>
                            </InputAdornment>
                          }
                          label='Category'
                        />
                      </FormControl>
                    ) : (
                      'Add Category'
                    )
                  }
                />
              </ListItemButton>
            </List>
          </Box>
        </Grid>
        <Grid item container xs={9} spacing={2}>
          {formValues.map((furniture, index) => (
            <Grid
              item
              container
              xs={3}
              key={furniture._id}
              style={{ position: 'relative' }}
              className='furniture-card'
            >
              <DeleteButton
                style={{ display: showDelete ? 'block' : 'none' }}
                size='small'
                className='card-corner-button'
                aria-label='Remove'
                // onClick={(e) => handleSetFurniture(furniture)}
                onClick={(e) => handleRemoveGrid(e, index)}
              >
                <Delete fontSize='inherit' />
              </DeleteButton>
              {furnitureEditing ? (
                <Grid item container>
                  <Grid item>
                    <Card
                      style={{
                        minWidth: 242,
                        minHeight: 280,
                        textAlign: 'center',
                        margin: '2px 2px',
                      }}
                    >
                      <CardActionArea onClick={() => handleClickOpen(index)}>
                        {selectedImg[index].imagePath ? (
                          <CardMedia
                            component='img'
                            // className={classes.media}
                            style={{ height: '100%' }}
                            image={
                              Config.baseURL + selectedImg[index]['imagePath']
                            }
                            title='uploaded pic'
                          />
                        ) : (
                          <CardContent>
                            <Typography>
                              Add/Display FloorOption Image {index + 1}
                            </Typography>
                          </CardContent>
                        )}
                      </CardActionArea>
                    </Card>
                  </Grid>
                  <Grid item>
                    <Card
                      style={{
                        minWidth: 220,
                        minHeight: 50,
                        margin: '2px 2px',
                        padding: '8px',
                        textAlign: 'center',
                      }}
                    >
                      <TextField
                        label='Floor Option Name'
                        value={furniture.name}
                        name='name'
                        variant='outlined'
                        onChange={(e) => handleInputChange(index, e)}
                        // inputProps={{
                        //   readOnly: requestType === 'view' ? true : false,
                        // }}
                      />
                      <Grid
                        container
                        columnDirection='row'
                        style={{ marginTop: '8px' }}
                      >
                        <Grid item xs={6}>
                          <TextField
                            type='number'
                            value={furniture.width}
                            name='width'
                            onChange={(e) => handleInputChange(index, e)}
                            style={{ marginRight: '4px' }}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position='start'>
                                  W
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            type='number'
                            value={furniture.height}
                            name='height'
                            onChange={(e) => handleInputChange(index, e)}
                            style={{ marginLeft: '4px' }}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position='start'>
                                  H
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                </Grid>
              ) : (
                <Grid item container>
                  <Card
                    style={{
                      minWidth: 242,
                      minHeight: 280,
                      textAlign: 'center',
                      margin: '2px 2px',
                    }}
                  >
                    <CardActionArea>
                      <CardMedia
                        component='img'
                        // className={classes.media}
                        style={{ height: '100%' }}
                        image={Config.baseURL + furniture.imagePath}
                        title='uploaded pic'
                      />
                      <CardContent>
                        <Typography>{furniture.name}</Typography>
                        <Typography>
                          {furniture.width}x{furniture.height}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
              )}
            </Grid>
          ))}
          {furnitureEditing && (
            <Grid item xs={3}>
              <Card
                style={{
                  minWidth: 180,
                  minHeight: 354,
                  textAlign: 'center',
                  margin: '2px 2px',
                }}
              >
                <CardActionArea onClick={() => handleAddGrid()}>
                  <CardContent>
                    <IconButton size='medium' onClick={() => handleAddGrid()}>
                      <Add fontSize='inherit' />
                    </IconButton>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          )}
        </Grid>
      </Grid>
      <div>
        <Stack direction='row' spacing={2}>
          <Button
            variant='contained'
            color='primary'
            type='submit'
            onClick={handleSubmit}
          >
            Save
          </Button>
          <Button variant='contained' color='primary' type='button'>
            Clear
          </Button>
        </Stack>
      </div>
      <FilesModalDilog
        files={docs}
        open={open}
        setOpen={setOpen}
        handleClickFile={handleClickImage}
      />
      <SimpleAlertDialog
        title={`Do you really want to detete ${selectedFurniture.name}?`}
        open={openAlert}
        setOpen={setOpenAlert}
        agree='Yes'
        disagree='No'
        handleAgree={(e) => handleDeleteFurniture()}
      />
    </>
  );
};

export default FurniturePlanner;
