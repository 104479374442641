import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  FormGroup,
  FormControlLabel,
  Checkbox,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
} from '@mui/material';
import './optionaccordian.css';
import Config from '../config';
import Draggable from 'react-draggable';
import { Stage, Layer, Image } from 'react-konva';
import useImage from 'use-image';
import $ from 'jquery';

const URLImage = ({ image }) => {
  const [img] = useImage(image.src);
  return (
    <Image
      image={img}
      x={image.x}
      y={image.y}
      draggable
      // I will use offset to set origin to the center of the image
      offsetX={img ? img.width / 2 : 0}
      offsetY={img ? img.height / 2 : 0}
    />
  );
};

function Optionaccordiannew({
  floorOptions,
  setFloorOptions,
  furnitures,
  inactiveElements,
  dragImageUrl,
  note,
  setNote,
  defaultcheck,
  setDefaultcheck,
  selectedTabIndex,
}) {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const stageRef = React.useRef();
  const [images, setImages] = React.useState([]);
  // console.log('accordion floorOptions: ', defaultcheck);

  const handleCheckbox = (e) => {
    setDefaultcheck({
      ...defaultcheck,
      [e.target.name]: e.target.checked,
    });
    let newFloorOptions = [...floorOptions];
    newFloorOptions[selectedTabIndex][e.target.dataset.index][
      'defaultInactive'
    ] = !e.target.checked;
    setFloorOptions(newFloorOptions);
    console.log('default checked: ', newFloorOptions);
  };
  const onFurnitureDrag = (e, furniture) => {
    // var width = window.innerWidth;
    // var height = window.innerHeight;
    // var stage = new Konva.Stage({
    //   container: 'container',
    //   width: width,
    //   height: height,
    // });
    // var layer = new Konva.Layer();
    // var rect1 = new Konva.Rect({
    //   x: 60,
    //   y: 60,
    //   width: 100,
    //   height: 90,
    //   fill: 'red',
    //   name: 'rect',
    //   draggable: true,
    // });
    // layer.add(rect1);
    // stage.add(layer);
    // console.log('event: ', e);
    // console.log('furniture: ', furniture);
  };
  // console.log('floor options items: ', floorOptions);

  const [accFloorOptions, setAccFloorOptions] = React.useState([]);

  React.useEffect(() => {
    setAccFloorOptions(floorOptions[selectedTabIndex]);
  }, [selectedTabIndex]);

  return (
    <>
      <Accordion
        key='floorOptions'
        expanded={expanded === 'floor-options'}
        onChange={handleChange('floor-options')}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel1a-content'
          id='panel1a-header'
        >
          <Typography>Floor Options</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {accFloorOptions.map((floorOption, floorOptionIndex) => (
            <div key={floorOption._id}>
              <FormControlLabel
                style={{ display: 'block' }}
                control={
                  // <Checkbox
                  //   checked={defaultcheck[floorOption._id]}
                  //   id={floorOption['_id']}
                  //   name={floorOption['_id']}
                  //   onChange={(e) => handleCheckbox(e)}
                  // />
                  <input
                    type='checkbox'
                    checked={!floorOption['defaultInactive']}
                    data-index={floorOptionIndex}
                    id={floorOption['_id']}
                    name={floorOption['_id']}
                    onChange={handleCheckbox}
                  />
                }
                label={floorOption.name}
              />
            </div>
            // <Typography key={subItem._id}>{subItem.name}</Typography>);
          ))}
        </AccordionDetails>
      </Accordion>
      <Accordion
        key='furnitures'
        expanded={expanded === 'furnitures'}
        onChange={handleChange('furnitures')}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel1a-content'
          id='panel1a-header'
        >
          <Typography>Furnitures</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {Object.entries(furnitures).map(([key, value]) => (
            <Accordion key={key}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls='panel1a-content'
              >
                <Typography>{key}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <List
                  sx={{
                    width: '100%',
                    maxWidth: 360,
                    bgcolor: 'background.paper',
                  }}
                >
                  {value.map((furniture, index) => (
                    <>
                      <ListItem>
                        <ListItemAvatar>
                          {/* <Avatar> */}
                          <img
                            style={{ position: 'relative', zIndex: 2 }}
                            className='furniture-image'
                            id={`furniture-image-${furniture._id}`}
                            src={Config.baseURL + furniture.imagePath}
                            alt={furniture.name}
                            draggable='true'
                            onDragStart={(e) => {
                              dragImageUrl.current = e.target.src;
                            }}
                          />
                          {/* </Avatar> */}
                        </ListItemAvatar>
                        <ListItemText
                          primary={furniture.name}
                          secondary={furniture.width + 'x' + furniture.height}
                        />
                      </ListItem>
                    </>
                  ))}
                </List>
              </AccordionDetails>
            </Accordion>
          ))}
        </AccordionDetails>
      </Accordion>
      <Accordion
        key='notes'
        expanded={expanded === 'notes'}
        onChange={handleChange('notes')}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel1a-content'
          id='panel1a-header'
        >
          <Typography>Add Notes</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <input
            draggable='true'
            onDragStart={(e) => {
              setNote(e.target.value);
            }}
            type='text'
            value={note}
            name='note'
            onChange={(e) => {
              setNote(e.target.value);
            }}
          />
        </AccordionDetails>
      </Accordion>
    </>
  );
}

export default Optionaccordiannew;
