import React from 'react';
import { Image, Transformer, Circle } from 'react-konva';
import useImage from 'use-image';

const URLImage = ({
  shapeProps,
  isSelected,
  onSelect,
  onChange,
  image,
  unSelectShape,
  onDelete,
  rotated,
  stageScale,
}) => {
  const [img] = useImage(image.src);
  const shapeRef = React.useRef();
  const deleteButton = React.useRef();
  const trRef = React.useRef();
  React.useEffect(() => {
    if (isSelected) {
      // we need to attach transformer manually
      trRef.current.nodes([shapeRef.current]);
      trRef.current.getLayer().batchDraw();
    }
  }, [isSelected]);

  const onMouseEnter = (event) => {
    if (isSelected) {
      event.target.getStage().container().style.cursor = 'move';
    }
    if (!isSelected) {
      event.target.getStage().container().style.cursor = 'pointer';
    }
  };

  const onMouseLeave = (event) => {
    event.target.getStage().container().style.cursor = 'default';
  };
  const handDelete = () => {
    unSelectShape(null);
    onDelete(shapeRef.current);
  };
  return (
    <React.Fragment key={image.id}>
      <Image
        className={`layerImages ${image.className}`}
        crossOrigin='Anonymous'
        image={img}
        style={image.style}
        x={image.x}
        y={image.y}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        id={image.id}
        onClick={onSelect}
        onTap={onSelect}
        ref={shapeRef}
        {...shapeProps}
        draggable
        onDragEnd={(e) => {
          onChange({
            ...shapeProps,
            x: e.target.x(),
            y: e.target.y(),
          });
        }}
        shadowEnabled={false}
        shadowOpacity={0}
        onTransformEnd={(e) => {
          // transformer is changing scale of the node
          // and NOT its width or height
          // but in the store we have only width and height
          // to match the data better we will reset scale on transform end
          const node = shapeRef.current;
          const scaleX = node.scaleX();
          const scaleY = node.scaleY();

          // we will reset it back
          node.scaleX(1);
          node.scaleY(1);
          onChange({
            ...shapeProps,
            x: node.x(),
            y: node.y(),
            // set minimal value
            width: Math.max(5, node.width() * scaleX),
            height: Math.max(node.height() * scaleY),
            rotation: node.rotation(),
          });
        }}
        // I will use offset to set origin to the center of the image
        offsetX={img ? img.width / 2 : 0}
        offsetY={img ? img.height / 2 : 0}
      />
      {isSelected && (
        <Transformer
          ref={trRef}
          rotationSnapTolerance={5}
          rotationSnaps={[0, 90, 180, 270]}
          boundBoxFunc={(oldBox, newBox) => {
            // limit resize
            if (newBox.width < 10 || newBox.height < 10) {
              return oldBox;
            }

            return newBox;
          }}
        >
          <Circle
            ref={deleteButton}
            radius={8}
            fill='red'
            x={shapeRef.current.width() * stageScale}
            onClick={handDelete}
          ></Circle>
        </Transformer>
      )}
    </React.Fragment>
  );
};

export default URLImage;
