import React, { useState, useEffect } from 'react';
import api from '../hooks/Api';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import useFirestore from '../hooks/useFirestore';
// import { projectFirestore } from '../firebase/config';
import styled from 'styled-components';

import {
  Grid,
  TextField,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Paper,
  Typography,
  IconButton,
} from '@material-ui/core';

import { Add, Remove } from '@material-ui/icons';

const ImgGrid = styled.div`
  margin: 20px auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 40px;
`;

const useStyles = makeStyles((theme) => ({
  pageContent: {
    marging: theme.spacing(5),
    padding: theme.spacing(3),
  },
  card: {
    minWidth: 220,
    minHeight: 300,
    backgroundColor: 'lightgray',
  },
  imgCard: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
}));

const FurnituresForm = () => {
  const { docs } = useFirestore('files');

  const classes = useStyles();

  let history = useHistory();

  const { floorPlanId } = useParams();

  const [formValues, setFormValues] = useState([
    {
      name: '',
      image: '',
      status: 1,
      floorPlanId: 1,
      homebuilderId: '',
      created_by: 1,
      last_update_by: 1,
    },
  ]);

  const [numberOfFurnitures, setNumberOfFurnitures] = useState(1);

  const [open, setOpen] = React.useState(false);

  const [clickedFurnitureGridIndex, setClickedFurnitureGridIndex] =
    useState(null);

  const [selectedImg, setSelectedImg] = useState([
    {
      id: '',
      url: '',
    },
  ]);

  const [homebuilderId, setHomebuilderId] = useState('');

  const [deletedFormValues, setDeletedFormValues] = useState([]);

  const urlSegment = useLocation();
  const requestType = urlSegment.pathname.split('/')[2]
    ? urlSegment.pathname.split('/')[2]
    : '';

  const { id } = useParams();
  // console.log(requestType);
  // console.log(id);

  const getFloorPlan = async () => {
    const response = await api.get(`/floorplans/${floorPlanId}`);
    return response.data;
  };

  const getFurniture = async () => {
    const Furniture = await api.get(`/furnitures/${id}`);
    return Furniture.data;
  };

  const getFurnituresByFloorPlan = async () => {
    const Furnitures = await api.get(`/furnitures/floorplan/${floorPlanId}`);
    return Furnitures.data;
  };

  //   const retreveFloorPlans = async () => {
  //     const response  = await api.get('/Furnitures');
  //     return response.data;
  // }

  const getAllFurnitures = async () => {
    const allFurnitures = await getFurnituresByFloorPlan();
    // console.log(allFurnitures);
    if (allFurnitures.length > 0) {
      const newImgsArr = [];
      // console.log(allFurnitures.image);
      for (let i = 0; i < allFurnitures.length; i++) {
        newImgsArr[i] = {
          id: allFurnitures[i]._id,
          url: allFurnitures[i].image,
        };
      }
      // const [ image ] = allFurnitures;
      setNumberOfFurnitures(allFurnitures.length);
      setSelectedImg(newImgsArr);
      setFormValues(allFurnitures);
    } else {
      const getSingleFloorPlan = await getFloorPlan();
      // console.log(getSingleFloorPlan);

      setHomebuilderId(getSingleFloorPlan.homebuilderId);
      // console.log(homebuilderId);
      // setNumberOfFurnitures(current => current = getSingleFloorPlan.no_of_FloorPlans);
      setNumberOfFurnitures(getSingleFloorPlan.noOfFurnitures);
      makeFurnitureGrids(
        getSingleFloorPlan.noOfFurnitures,
        getSingleFloorPlan.homebuilderId
      );
    }
  };

  const handleClickOpen = (index) => {
    // console.log(index);
    setClickedFurnitureGridIndex((current) => (current = index));
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const Furniture = async () => {
    const getSingleFloorPlan = await getFurniture();
    // console.log(getSingleFloorPlan)
    if (getSingleFloorPlan) {
      // console.log(selectedImg);
      // setSelectedImg(current => {current[0]['id'] = ''; current[0]['url'] = getSingleFloorPlan.image; } )
      const newImgsArray = [...selectedImg];
      newImgsArray[0]['id'] = '';
      newImgsArray[0]['url'] = getSingleFloorPlan.image;
      // console.log(newImgsArray);
      setSelectedImg(newImgsArray);
      // console.log(selectedImg);
      setFormValues([getSingleFloorPlan]);
    }
  };

  useEffect(() => {
    // const Elevation = async () => {

    // const Furnitures = getFurnituresByFloorPlan();
    // console.log(Furnitures);
    // if(getAllFurnitures()){
    // getAllFurnitures();
    // } else {
    //   makeFurnitureGrids(getSingleFloorPlan.no_of_FloorPlans)
    // }
    // makeFurnitureGrids(getSingleFloorPlan.no_of_FloorPlans);

    // };
    if (floorPlanId !== undefined) {
      getAllFurnitures();
    }

    if (requestType !== '' && floorPlanId === undefined) {
      Furniture();
    }
  }, []);

  const makeFurnitureGrids = (noOfFurnitures, homebuilder_id) => {
    // console.log(homebuilderId);
    const newFormValuesArray = [];
    const newImgsArr = [];
    for (let i = 0; i < noOfFurnitures; i++) {
      // console.log(homebuilderId);

      newFormValuesArray[i] = {
        name: '',
        image: '',
        noOfFurnitures: 1,
        status: 1,
        floorPlanId: floorPlanId,
        homeId: 1,
        homebuilderId: homebuilder_id,
        created_by: 1,
        last_update_by: 1,
      };
      newImgsArr[i] = {
        id: '',
        url: '',
      };
    }
    setSelectedImg(newImgsArr);
    setFormValues(newFormValuesArray);
    // console.log(formValues);
  };

  const handleInputChange = (index, e) => {
    const values = [...formValues];
    values[index][e.target.name] = e.target.value;
    setFormValues(values);
  };

  const handleClickImage = (doc) => {
    console.log(doc);
    const newImgsArray = [...selectedImg];
    newImgsArray[clickedFurnitureGridIndex].id = doc.id;
    newImgsArray[clickedFurnitureGridIndex].url = doc.url;
    console.log(newImgsArray);
    setSelectedImg(newImgsArray);
    // setSelectedImg(current => {
    //   current[clickedFurnitureGridIndex]['id'] = doc.id;
    //   current[clickedFurnitureGridIndex]['url'] = doc.url;
    // });

    const values = [...formValues];
    values[clickedFurnitureGridIndex]['image'] =
      selectedImg[clickedFurnitureGridIndex]['url'];
    setFormValues(values);
    setOpen(false);
    // console.log(selectedImg);
    // console.log(values);
    console.log(formValues);
  };

  const modalDialog = (
    <Dialog
      fullWidth
      maxWidth='md'
      open={open}
      onClose={handleClose}
      aria-labelledby='max-width-dialog-title'
    >
      <DialogTitle id='max-width-dialog-title'>Optional sizes</DialogTitle>
      <DialogContent>
        <ImgGrid>
          {docs &&
            docs.map((doc, index) => (
              <Card
                className={classes.imgCard}
                onClick={() => handleClickImage(doc)}
                key={index}
              >
                <CardActionArea>
                  <CardMedia
                    className={classes.media}
                    image={doc.url}
                    title='uploaded pic'
                  />
                  <CardContent>
                    <Typography>Test</Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            ))}
        </ImgGrid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color='primary'>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );

  const handleSubmit = async (event) => {
    event.preventDefault();
    // console.log(homebuilderId);
    console.log(formValues);
    // console.log(formValues);
    let c = 0;
    if (
      requestType === 'edit' &&
      urlSegment.pathname.split('/')[3] !== 'floorplan' &&
      homebuilderId === ''
    ) {
      c = 1;
      await api.patch(`/furnitures/${id}`, formValues[0]);
    } else if (
      requestType === 'edit' &&
      urlSegment.pathname.split('/')[3] === 'floorplan' &&
      homebuilderId === ''
    ) {
      c = 2;
      // console.log('rest');
      // await api.patch(`/furnitures/floorplan/${floorPlanId}`, formValues);
      const oldFormValues = formValues.filter((obj) =>
        checkKeyExists(obj, '_id')
      );

      const newFormValues = formValues.filter(
        (obj) => !checkKeyExists(obj, '_id')
      );
      console.log(oldFormValues);
      console.log(newFormValues);
      console.log(deletedFormValues);
      if (oldFormValues.length > 0) {
        const updateFurnitures = await api.patch(
          `/furnitures/floorplan/${floorPlanId}`,
          oldFormValues
        );
        // console.log(`updated: ${updateFurnitures}`);
      }
      if (newFormValues.length > 0) {
        const createFurnitures = await api.post('/furnitures', newFormValues);
        console.log(`created: ${createFurnitures}`);
      }
      if (deletedFormValues.length > 0) {
        // console.log(deletedFormValues);
        const deletedFurnitures = await api.patch(
          `/furnitures/floorplan/${floorPlanId}`,
          deletedFormValues
        );
        // console.log(`deleted: ${deletedFurnitures}`);
      }
      const floorPlansResponse = await api.patch(
        `/floorplans/query/{floorPlanId: ${floorPlanId}}`,
        {
          noOfFurnitures: numberOfFurnitures,
        }
      );
      console.log(`floorplans: ${floorPlansResponse.data}`);
    } else {
      c = 3;
      await api.post('/furnitures', formValues);
    }

    // if(response.status === 200){
    //   console.log(response.data);

    // }else{
    //   console.log(response);
    // }
    console.log(c);
    history.push('/furnitures');
  };

  const checkKeyExists = (obj, key) => {
    if (obj.hasOwnProperty(key)) {
      return true;
    } else {
      return false;
    }
  };

  const handleAddGrid = () => {
    // console.log(formValues);
    setFormValues([
      ...formValues,
      {
        name: '',
        image: '',
        status: 1,
        floorPlanId: floorPlanId,
        homeId: 1,
        homebuilderId: formValues[0].homebuilderId,
        created_by: 1,
        last_update_by: 1,
      },
    ]);
    setSelectedImg([
      ...selectedImg,
      {
        id: '',
        url: '',
      },
    ]);
    setNumberOfFurnitures((currentValue) => currentValue + 1);
    console.log(formValues);
  };

  const handleRemoveGrid = (e, index) => {
    const newFormValues = [...formValues];
    const selectedImgValues = [...selectedImg];

    let newArr = newFormValues.splice(index, 1);
    newArr[0].status = 0;
    setDeletedFormValues((current) => {
      current = deletedFormValues;
      current.push(newArr[0]);
      return current;
    });
    selectedImgValues.splice(index, 1);

    setFormValues(newFormValues);
    setSelectedImg(selectedImgValues);

    setNumberOfFurnitures((currentValue) => currentValue - 1);
  };

  return (
    <div>
      <Grid container>
        <Grid item container>
          <Grid item container spacing={2}>
            <Grid item>
              <Button variant='contained' size='large'>
                <Link to='/floorplansform'>Add Furniture</Link>
              </Button>
            </Grid>
            <Grid item>
              <Button variant='contained' size='large'>
                <Link to='/furnitures'>View All Furnitures</Link>
              </Button>
            </Grid>
            <Grid item>
              <Button variant='contained' size='large'>
                <Link to={`/furnitures/edit/${id}`}>Edit Furniture</Link>
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <form onSubmit={handleSubmit}>
        <Paper className={classes.pageContent} style={{ marginTop: '10px' }}>
          {/* <Grid container spacing={2}>
          <Grid item xs={6}>
            <Controls.Input
              name="no_of_FloorPlans"
              label="No. of Furnitures to add"
              value={values.no_of_FloorPlans}
              type="text"
              
              onChange={handleInputChange}
            />  
          </Grid>
          <Grid item xs={6} style={{marginTop: '10px'}}>
          <Button variant="contained" color="primary" size="large" type="button" onClick={addFloorPlanGrid}>
            +
          </Button>
          </Grid>
        </Grid> */}

          <Grid container>
            {formValues.map((formValue, index) => (
              <Grid key={index} item container xs={3}>
                <Grid item container>
                  <Grid item>
                    <Card
                      onClick={
                        requestType === 'view'
                          ? null
                          : () => handleClickOpen(index)
                      }
                      style={{
                        minWidth: 180,
                        minHeight: 260,
                        textAlign: 'center',
                      }}
                    >
                      <CardActionArea>
                        {selectedImg[index]['url'] ? (
                          <CardMedia
                            component='img'
                            // className={classes.media}
                            style={{ height: '100%' }}
                            image={selectedImg[index]['url']}
                            title='uploaded pic'
                          />
                        ) : (
                          <CardContent>
                            <Typography>
                              Add/Display Furniture Image {index + 1}
                            </Typography>
                          </CardContent>
                        )}
                      </CardActionArea>
                    </Card>
                  </Grid>
                  <Grid item>
                    <Card
                      style={{
                        minWidth: 220,
                        minHeight: 50,
                        backgroundColor: 'lightgray',
                        marginTop: '15px',
                        textAlign: 'center',
                      }}
                    >
                      <TextField
                        label='Furniture Name'
                        value={formValue.name}
                        name='name'
                        variant='outlined'
                        onChange={(e) => handleInputChange(index, e)}
                        inputProps={{
                          readOnly: requestType === 'view' ? true : false,
                        }}
                      />
                    </Card>
                  </Grid>
                  {/* <Grid item container style={{ marginTop: '10px' }}>
                    <Grid item xs={6}>
                      <Typography>No. of plan options</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        label=''
                        name='noOfFurnitures'
                        value={formValue.noOfFurnitures}
                        variant='outlined'
                        type='number'
                        onChange={(e) => handleInputChange(index, e)}
                        inputProps={{
                          readOnly: requestType === 'view' ? true : false,
                        }}
                      />
                    </Grid>
                  </Grid> */}
                  {requestType === 'edit' ? (
                    <Grid item container style={{ marginTop: '10px' }}>
                      <Grid item xs={6}>
                        <IconButton onClick={(e) => handleRemoveGrid(e, index)}>
                          <Remove />
                        </IconButton>
                      </Grid>
                      <Grid item xs={6}>
                        <IconButton onClick={() => handleAddGrid()}>
                          <Add />
                        </IconButton>
                      </Grid>
                    </Grid>
                  ) : (
                    ''
                  )}
                </Grid>
              </Grid>
            ))}
          </Grid>
          <Grid container spacing={2} style={{ marginTop: '10px' }}>
            <Grid item>
              <Button
                variant='contained'
                color='primary'
                type='submit'
                onClick={handleSubmit}
                disabled={requestType === 'view' ?? true}
              >
                Save
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant='contained'
                color='primary'
                type='button'
                disabled={requestType === 'view' ?? true}
              >
                Clear
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </form>
      {modalDialog}
    </div>
  );
};

export default FurnituresForm;
