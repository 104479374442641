import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import api from '../hooks/Api';
import { useHistory, useParams, useLocation, Link } from 'react-router-dom';
import { Container, InputLabel, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useForm, Form } from './useForm';
import Controls from '../hooks/controls/Controls';
import { Country, State, City } from 'country-state-city';
import ImportRecords from './ImportRecords';
import { ExportSampleExcel } from './ExportSampleExcel';
import Widget from './Widget';
import Config from '../config';
let defaultValues = {
  name: '',
  email: '',
  mobile_no: '',
  city: '',
  state: '',
  address: '',
  website: '',
  brand_image: 'test.jpg',
  brand_color: '#cccccc',
  user_id: 1,
  country: '',
  contact_person: '',
  contact_person_designation: '',
  zipcode: '',
  measurement: '',
  status: 0,
};

const useStyle = makeStyles((theme) => ({
  root: {
    '& .MuiFormControl-root': {
      width: '80%',
      margin: theme.spacing(1),
    },
  },
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(3),
  },
  grid: {
    width: '100%',
    margin: '0px',
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
}));

const HomebuilderForm = () => {
  const location = useLocation();
  const requestType = location.pathname.split('/')[2]
    ? location.pathname.split('/')[2]
    : '';

  // if(requestType === "view"){
  //   setViewData(true);
  // }

  const classes = useStyle();
  let history = useHistory();

  const { id } = useParams();
  const getHomebuilder = async () => {
    const homebuilder = await api.get(`/homebuilders/${id}`);
    return homebuilder.data;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (requestType === 'edit') {
      await api.patch(`/homebuilders/${id}`, values);
      // console.log("edit success!");
    } else {
      await api.post('/homebuilders', values);
      // console.log(response.data);
    }
    history.push('/homebuilders');
  };
  const { values, setValues, handleInputChange } = useForm(defaultValues);

  useEffect(() => {
    const homebuilder = async () => {
      const getSingleHomebuilder = await getHomebuilder();
      if (getSingleHomebuilder) setValues(getSingleHomebuilder);
    };
    if (requestType !== '') {
      homebuilder();
    }
  }, []);
  // communities.map((v, k) => console.log(v._id));

  const getAllCountries = Country.getAllCountries();
  const specificCountries = getAllCountries.filter(
    (country) =>
      country.isoCode === 'AU' ||
      country.isoCode === 'GB' ||
      country.isoCode === 'US' ||
      country.isoCode === 'CA' ||
      country.isoCode === 'NZ'
  );
  const getAllStates = (countryCode) => State.getStatesOfCountry(countryCode);
  const getAllCities = (countryCode, stateCode) =>
    City.getCitiesOfState(countryCode, stateCode);
  // const makeEdit = () => {

  // }
  const ifpWidgetCode1 = `<script src="http://ifp.xscad2.com/bundle.min.js"></script>`;
  // const ifpWidgetCode2 = `<script src="http://ifp.xscad2.com/index.js"></script>`;
  const ifpWidgetCode3 = `<div id="ifp_widget" data=${id}></div>`;

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Grid container direction='column' spacing={2} className={classes.grid}>
          <Grid item container>
            <Grid item container spacing={2}>
              <Grid item>
                <Button variant='contained' size='large'>
                  <Link to='/homebuilderform'>Add Homebuilder</Link>
                </Button>
              </Grid>
              <Grid item>
                <Button variant='contained' size='large'>
                  <Link to='/homebuilders'>View All Homebuilders</Link>
                </Button>
              </Grid>
              <Grid item>
                <Button variant='contained' size='large'>
                  <Link to={`/homebuilder/edit/${id}`}>Edit Homebuilder</Link>
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item container spacing={2}>
            <Grid item xs={6}>
              <Paper className={classes.paper}>
                <Controls.Input
                  fullWidth
                  id='name-input'
                  name='name'
                  label='Name'
                  value={values.name}
                  onChange={handleInputChange}
                  InputProps={{
                    readOnly: requestType === 'view' ? true : false,
                  }}
                />

                <Controls.Input
                  id='email-input'
                  name='email'
                  label='Email'
                  value={values.email}
                  onChange={handleInputChange}
                  InputProps={{
                    readOnly: requestType === 'view' ? true : false,
                  }}
                />
                <Controls.Input
                  id='mobile_no-input'
                  name='mobile_no'
                  label='Mobile No'
                  value={values.mobile_no}
                  onChange={handleInputChange}
                  InputProps={{
                    readOnly: requestType === 'view' ? true : false,
                  }}
                />
                <Controls.Input
                  id='website-input'
                  name='website'
                  label='Website'
                  value={values.website}
                  onChange={handleInputChange}
                  InputProps={{
                    readOnly: requestType === 'view' ? true : false,
                  }}
                />
                <Controls.Input
                  name='contact_person'
                  label='Contact Person'
                  value={values.contact_person}
                  onChange={handleInputChange}
                  InputProps={{
                    readOnly: requestType === 'view' ? true : false,
                  }}
                />
                <Controls.Input
                  name='contact_person_designation'
                  label='Contact Person Designation'
                  value={values.contact_person_designation}
                  onChange={handleInputChange}
                  InputProps={{
                    readOnly: requestType === 'view' ? true : false,
                  }}
                />
                <Controls.Input
                  name='brand_image'
                  label='Brand Logo'
                  value={values.brand_image}
                  onChange={handleInputChange}
                  InputProps={{
                    readOnly: requestType === 'view' ? true : false,
                  }}
                />
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Paper className={classes.paper}>
                <FormControl variant='outlined'>
                  <InputLabel>Country</InputLabel>
                  <Select
                    name='country'
                    label='Country'
                    value={values.country}
                    onChange={handleInputChange}
                    inputProps={{
                      readOnly: requestType === 'view' ? true : false,
                    }}
                  >
                    <MenuItem key='0' value=''>
                      None
                    </MenuItem>
                    {specificCountries.map((option) => (
                      <MenuItem key={option.isoCode} value={option.isoCode}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl variant='outlined'>
                  <InputLabel>State</InputLabel>
                  <Select
                    name='state'
                    label='State'
                    value={values.state}
                    onChange={handleInputChange}
                    inputProps={{
                      readOnly: requestType === 'view' ? true : false,
                    }}
                  >
                    <MenuItem key='0' value=''>
                      None
                    </MenuItem>
                    {getAllStates(values.country).map((option) => (
                      <MenuItem key={option.isoCode} value={option.isoCode}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl variant='outlined'>
                  <InputLabel>City</InputLabel>
                  <Select
                    name='city'
                    label='City'
                    value={values.city}
                    onChange={handleInputChange}
                    inputProps={{
                      readOnly: requestType === 'view' ? true : false,
                    }}
                  >
                    <MenuItem key='0' value=''>
                      None
                    </MenuItem>
                    {getAllCities(values.country, values.state).map(
                      (option) => (
                        <MenuItem key={option.name} value={option.name}>
                          {option.name}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </FormControl>

                <Controls.Input
                  name='zipcode'
                  label='Zipcode'
                  value={values.zipcode}
                  onChange={handleInputChange}
                  InputProps={{
                    readOnly: requestType === 'view' ? true : false,
                  }}
                />
                <Controls.Input
                  id='address-input'
                  name='address'
                  label='Address'
                  value={values.address}
                  onChange={handleInputChange}
                  InputProps={{
                    readOnly: requestType === 'view' ? true : false,
                  }}
                />

                <Controls.Switch
                  name='status'
                  label='Status'
                  value={values.status}
                  onChange={handleInputChange}
                  InputProps={{
                    readOnly: requestType === 'view' ? true : false,
                  }}
                  disabled={requestType === 'view' ?? true}
                />
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Paper className={classes.paper}>
                <FormControl variant='outlined'>
                  <FormLabel>Measurement</FormLabel>
                  <Select
                    name='measurement'
                    value={values.measurement}
                    label='measurement'
                    onChange={handleInputChange}
                    inputProps={{
                      readOnly: requestType === 'view' ? true : false,
                    }}
                  >
                    <MenuItem key='imperial' value='imperial'>
                      Imperial
                    </MenuItem>
                    <MenuItem key='metric' value='metric'>
                      Metric
                    </MenuItem>
                  </Select>
                </FormControl>
                <Typography>Furniture Fitting Template</Typography>
                <Button variant='outlined' color='primary'>
                  Select
                </Button>
                <Typography>IFP Template</Typography>
                <Button variant='outlined' color='primary'>
                  Select
                </Button>
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Paper className={classes.paper}>
                <Grid container>
                  <Grid item>
                    <Typography variant='overline' gutterBottom>
                      IFP Widget code
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    {' '}
                  </Grid>
                  <Grid item>
                    <Button
                      variant='outlined'
                      color='primary'
                      href={`/homebuilder-widget/${id}`}
                    >
                      <Link to={`/homebuilder-widget/${id}`}>View Widget</Link>
                    </Button>
                  </Grid>
                  {/* <Button>Generate</Button> */}
                </Grid>
                {/* <Container>
                  <Typography variant='body2' gutterBottom>
                    Paste it beweeen head tags
                  </Typography>
                </Container> */}

                <Container>
                  <Typography variant='body2' gutterBottom>
                    Paste it before closing body tag
                  </Typography>
                </Container>
                <Container>
                  <Paper style={{ padding: '10px', margin: '10px' }}>
                    <Typography variant='caption'>{ifpWidgetCode1}</Typography>
                  </Paper>
                </Container>
                {/* <Container>
                  <Paper style={{ padding: '10px', margin: '10px' }}>
                    <Typography variant='caption'>{ifpWidgetCode2}</Typography>
                  </Paper>
                </Container> */}
                <Container>
                  <Typography variant='body2' gutterBottom>
                    Paste it after the element where you want to show the widget
                  </Typography>
                </Container>
                <Container>
                  <Paper style={{ padding: '10px', margin: '10px' }}>
                    <Typography variant='caption' display='block'>
                      {ifpWidgetCode3}
                    </Typography>
                  </Paper>
                </Container>
              </Paper>
            </Grid>
            <Grid item xs={6} container spacing={4}>
              <Grid item>
                <Button
                  variant='contained'
                  color='primary'
                  type='submit'
                  disabled={requestType === 'view' ?? true}
                >
                  Save
                </Button>
              </Grid>
              <Grid item>
                <Button variant='contained' color='primary' onClick={() => {}}>
                  Clear
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Form>
      <div>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Paper className={classes.paper} style={{ textAlign: 'center' }}>
              <ExportSampleExcel
                csvData={[defaultValues]}
                fileName='homebuildersSample'
              />
            </Paper>
          </Grid>
          <Grid item xs={9}>
            <Paper className={classes.paper}>
              <Typography>Import Multiple Records with Excel file</Typography>
              <ImportRecords />
            </Paper>
          </Grid>
        </Grid>
      </div>
    </>
  );
};
export default HomebuilderForm;
