import React, { useState, useEffect } from 'react';
import api from '../hooks/Api';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import useFirestore from '../hooks/useFirestore';
// import { projectFirestore } from '../firebase/config';
import styled from 'styled-components';

import {
  Grid,
  TextField,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Paper,
  Typography,
  IconButton,
  Switch,
  CardHeader,
} from '@material-ui/core';

import { Add, Remove, Close } from '@material-ui/icons';
import FloorOptionRules from './FloorOptionRules';
import FilesModalDilog from './files/FilesModalDialog';
import useFileManager from '../hooks/files/useFileManager';
import Config from '../config';

const ImgGrid = styled.div`
  margin: 20px auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 40px;
`;

const useStyles = makeStyles((theme) => ({
  pageContent: {
    marging: theme.spacing(5),
    padding: theme.spacing(3),
  },
  card: {
    minWidth: 220,
    minHeight: 300,
    backgroundColor: 'lightgray',
  },
  imgCard: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
}));

const FloorOptionsForm = () => {
  const [refresh, setRefersh] = useState(false);
  // here useFileManager hook first arguments is request that can be fetch, delete or update as value
  // param will be null when request is fetch else param will be a
  // parameter on which delete or update of a file will be executed
  // refresh and setRefresh are state variable by which we change state of page and refresh it
  const { docs } = useFileManager('fetch', null, refresh, setRefersh);

  const classes = useStyles();

  let history = useHistory();

  const { floorPlanId } = useParams();

  const [formValues, setFormValues] = useState([
    {
      name: '',
      imagePath: '',
      status: 1,
      floorPlanId: 1,
      homebuilderId: '',
    },
  ]);

  const [numberOfFloorOptions, setNumberOfFloorOptions] = useState(1);

  const [open, setOpen] = React.useState(false);

  const [clickedFloorOptionGridIndex, setClickedFloorOptionGridIndex] =
    useState(null);

  const [selectedImg, setSelectedImg] = useState([
    {
      id: '',
      imagePath: '',
    },
  ]);

  const [homebuilderId, setHomebuilderId] = useState('');

  const [deletedFormValues, setDeletedFormValues] = useState([]);

  const urlSegment = useLocation();
  const requestType = urlSegment.pathname.split('/')[2]
    ? urlSegment.pathname.split('/')[2]
    : '';

  const title = `${requestType.toUpperCase()} Floor Options`;

  const { id } = useParams();
  // console.log(requestType);
  // console.log(id);

  const getFloorPlan = async () => {
    const response = await api.get(`/floorplans/${floorPlanId}`);
    return response.data;
  };

  const getFloorOption = async () => {
    const FloorOption = await api.get(`/flooroptions/${id}`);
    return FloorOption.data;
  };

  const getFloorOptionsByFloorPlan = async () => {
    const FloorPlans = await api.get(`/flooroptions/floorplan/${floorPlanId}`);
    return FloorPlans.data;
  };

  //   const retreveFloorPlans = async () => {
  //     const response  = await api.get('/FloorPlans');
  //     return response.data;
  // }

  const getAllFloorOptions = async () => {
    const allFloorOptions = await getFloorOptionsByFloorPlan();
    // console.log(allFloorOptions);
    if (allFloorOptions.length > 0) {
      const newImgsArr = [];
      // console.log(allFloorOptions.image);
      for (let i = 0; i < allFloorOptions.length; i++) {
        newImgsArr[i] = {
          id: allFloorOptions[i]._id,
          imagePath: allFloorOptions[i].imagePath,
        };
      }
      // const [ image ] = allFloorOptions;
      setNumberOfFloorOptions(allFloorOptions.length);
      setSelectedImg(newImgsArr);
      setFormValues(allFloorOptions);
    } else {
      const getSingleFloorOption = await getFloorPlan();
      // console.log(getSingleFloorOption.homebuilder_id);

      setHomebuilderId(getSingleFloorOption.homebuilderId);
      // console.log('homebuilder id: ', homebuilderId);
      // setNumberOfFloorOptions(current => current = getSingleFloorOption.no_of_FloorPlans);
      setNumberOfFloorOptions(getSingleFloorOption.noOfPlanOptions);
      makeFloorOptionGrids(
        getSingleFloorOption.noOfPlanOptions,
        getSingleFloorOption.homebuilderId
      );
    }
  };

  const handleClickOpen = (index) => {
    // console.log(index);
    setClickedFloorOptionGridIndex((current) => (current = index));
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const FloorOption = async () => {
    const getSingleFloorOption = await getFloorOption();
    // console.log(getSingleFloorOption)
    if (getSingleFloorOption) {
      // console.log(selectedImg);
      // setSelectedImg(current => {current[0]['id'] = ''; current[0]['url'] = getSingleFloorOption.image; } )
      const newImgsArray = [...selectedImg];
      newImgsArray[0]['id'] = '';
      newImgsArray[0]['imagePath'] = getSingleFloorOption.imagePath;
      // console.log(newImgsArray);
      setSelectedImg(newImgsArray);
      // console.log(selectedImg);
      setFormValues([getSingleFloorOption]);
    }
  };

  useEffect(() => {
    // const Elevation = async () => {

    // const FloorPlans = getFloorOptionsByFloorPlan();
    // console.log(FloorPlans);
    // if(getAllFloorOptions()){
    // getAllFloorOptions();
    // } else {
    //   makeFloorOptionGrids(getSingleFloorOption.no_of_FloorPlans)
    // }
    // makeFloorOptionGrids(getSingleFloorOption.no_of_FloorPlans);

    // };
    // console.log('floor plan id: ', floorPlanId);
    if (floorPlanId !== undefined) {
      getAllFloorOptions();
    }

    if (requestType !== '' && floorPlanId === undefined) {
      FloorOption();
    }
  }, []);

  const makeFloorOptionGrids = (noOfFloorOptions, homebuilderId) => {
    // console.log(homebuilderId);
    const newFormValuesArray = [];
    const newImgsArr = [];
    for (let i = 0; i < noOfFloorOptions; i++) {
      // console.log(homebuilderId);

      newFormValuesArray[i] = {
        name: '',
        imagePath: '',
        status: 1,
        floorPlanId: floorPlanId,
        homebuilderId: homebuilderId,
      };
      newImgsArr[i] = {
        id: '',
        imagePath: '',
      };
    }
    setSelectedImg(newImgsArr);
    setFormValues(newFormValuesArray);
    // console.log(formValues);
  };

  const handleInputChange = (index, e) => {
    const values = [...formValues];
    values[index][e.target.name] = e.target.value;
    setFormValues(values);
  };

  const handleClickImage = (doc) => {
    console.log(doc);
    const newImgsArray = [...selectedImg];
    newImgsArray[clickedFloorOptionGridIndex].id = doc._id;
    newImgsArray[clickedFloorOptionGridIndex].imagePath = doc.path;
    console.log(newImgsArray);
    setSelectedImg(newImgsArray);
    // setSelectedImg(current => {
    //   current[clickedFloorOptionGridIndex]['id'] = doc.id;
    //   current[clickedFloorOptionGridIndex]['url'] = doc.url;
    // });

    const values = [...formValues];
    values[clickedFloorOptionGridIndex]['imagePath'] =
      selectedImg[clickedFloorOptionGridIndex]['imagePath'];
    setFormValues(values);
    setOpen(false);
    // console.log(selectedImg);
    // console.log(values);
    console.log(formValues);
  };

  const handleStatusChange = (e, index) => {
    // console.log(e);
    const values = [...formValues];
    // console.log(values);
    values[index].status = e.target.checked;
    setFormValues(values);

    // setFormValues({ ...formValues, [e.target.name]: e.target.checked });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    // console.log(homebuilderId);
    // console.log('form values: ', formValues);
    // console.log(formValues);
    if (
      requestType === 'edit' &&
      urlSegment.pathname.split('/')[3] !== 'floorplan' &&
      homebuilderId === ''
    ) {
      await api.patch(`/flooroptions/${id}`, formValues[0]);
    } else if (
      requestType === 'edit' &&
      urlSegment.pathname.split('/')[3] === 'floorplan' &&
      homebuilderId === ''
    ) {
      // console.log('rest');
      // await api.patch(`/flooroptions/floorplan/${floorPlanId}`, formValues);
      const oldFormValues = formValues.filter((obj) =>
        checkKeyExists(obj, '_id')
      );

      const newFormValues = formValues.filter(
        (obj) => !checkKeyExists(obj, '_id')
      );
      console.log(oldFormValues);
      console.log(newFormValues);
      console.log(deletedFormValues);
      if (oldFormValues.length > 0) {
        const updateFloorOptions = await api.patch(
          `/flooroptions/floorplan/${floorPlanId}`,
          oldFormValues
        );
        // console.log(`updated: ${updateFloorOptions}`);
      }
      if (newFormValues.length > 0) {
        const createFloorOptions = await api.post(
          '/flooroptions',
          newFormValues
        );
        // console.log(`created: ${createFloorOptions}`);
      }
      if (deletedFormValues.length > 0) {
        // console.log(deletedFormValues);
        const deletedFloorOptions = await api.patch(
          `/flooroptions/floorplan/${floorPlanId}`,
          deletedFormValues
        );
        // console.log(`deleted: ${deletedFloorOptions}`);
      }
      const floorPlansResponse = await api.patch(
        `/floorplans/query/{floorPlanId: ${floorPlanId}}`,
        {
          noOfPlanOptions: numberOfFloorOptions,
        }
      );
      console.log(`floorplans: ${floorPlansResponse}`);
    } else {
      await api.post('/flooroptions', formValues);
    }

    // if(response.status === 200){
    //   console.log(response.data);

    // }else{
    //   console.log(response);
    // }
    history.push('/flooroptions');
  };

  const checkKeyExists = (obj, key) => {
    if (obj.hasOwnProperty(key)) {
      return true;
    } else {
      return false;
    }
  };

  const handleAddGrid = () => {
    setFormValues([
      ...formValues,
      {
        name: '',
        imagePath: '',
        status: 1,
        floorPlanId: floorPlanId,
        homebuilderId: formValues[0].homebuilderId,
      },
    ]);
    setSelectedImg([
      ...selectedImg,
      {
        id: '',
        imagePath: '',
      },
    ]);
    setNumberOfFloorOptions((currentValue) => currentValue + 1);
  };

  const handleRemoveGrid = (e, index) => {
    const newFormValues = [...formValues];
    const selectedImgValues = [...selectedImg];

    let newArr = newFormValues.splice(index, 1);
    newArr[0].status = 0;

    setDeletedFormValues((current) => {
      current = deletedFormValues;
      current.push(newArr[0]);
      return current;
    });
    selectedImgValues.splice(index, 1);

    setFormValues(newFormValues);
    setSelectedImg(selectedImgValues);

    setNumberOfFloorOptions((currentValue) => currentValue - 1);
  };

  return (
    <div>
      <Grid container>
        <Grid item container>
          <Grid item container spacing={2}>
            <Grid item>
              <Button variant='contained' size='large'>
                <Link to='/floorplansform'>Add FloorOption</Link>
              </Button>
            </Grid>
            <Grid item>
              <Button variant='contained' size='large'>
                <Link to='/flooroptions'>View All Floor Options</Link>
              </Button>
            </Grid>
            <Grid item>
              <Button variant='contained' size='large'>
                <Link to={`/flooroptions/edit/${id}`}>Edit Floor Option</Link>
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <form onSubmit={handleSubmit}>
        <Paper className={classes.pageContent} style={{ marginTop: '10px' }}>
          {/* <Grid container spacing={2}>
          <Grid item xs={6}>
            <Controls.Input
              name="no_of_FloorPlans"
              label="No. of FloorPlans to add"
              value={values.no_of_FloorPlans}
              type="text"
              
              onChange={handleInputChange}
            />  
          </Grid>
          <Grid item xs={6} style={{marginTop: '10px'}}>
          <Button variant="contained" color="primary" size="large" type="button" onClick={addFloorPlanGrid}>
            +
          </Button>
          </Grid>
        </Grid> */}
          <Typography variant='h6' gutterBottom>
            {title}
          </Typography>
          <Grid container>
            {formValues.map((formValue, index) => (
              <Grid key={index} item container xs={3}>
                <Grid item container>
                  <Grid item>
                    <Card
                      style={{
                        minWidth: 180,
                        minHeight: 280,
                        textAlign: 'center',
                        margin: '2px 2px',
                      }}
                    >
                      <CardHeader
                        className='IfpCustomGridHeader'
                        action={
                          <Grid container direction='row'>
                            <Grid item>
                              <Button disabled={requestType === 'view' ?? true}>
                                <Switch
                                  disabled={requestType === 'view' ?? true}
                                  checked={formValue.status}
                                  onChange={(e) => handleStatusChange(e, index)}
                                  name='status'
                                  inputProps={{
                                    'aria-label': 'secondary checkbox',
                                  }}
                                />
                              </Button>
                            </Grid>
                            <Grid item>
                              <IconButton
                                disabled={requestType === 'edit' ? false : true}
                                aria-label='Remove'
                                onClick={(e) => handleRemoveGrid(e, index)}
                              >
                                <Close />
                              </IconButton>
                            </Grid>
                          </Grid>
                        }
                      />
                      <CardActionArea
                        disabled={requestType === 'view' ?? true}
                        onClick={
                          requestType === 'view'
                            ? null
                            : () => handleClickOpen(index)
                        }
                      >
                        {selectedImg[index]['imagePath'] ? (
                          <CardMedia
                            component='img'
                            // className={classes.media}
                            style={{ height: '100%' }}
                            image={
                              Config.baseURL + selectedImg[index]['imagePath']
                            }
                            title='uploaded pic'
                          />
                        ) : (
                          <CardContent>
                            <Typography>
                              Add/Display FloorOption Image {index + 1}
                            </Typography>
                          </CardContent>
                        )}
                      </CardActionArea>
                    </Card>
                  </Grid>
                  <Grid item>
                    <Card
                      style={{
                        minWidth: 220,
                        minHeight: 50,
                        margin: '2px 2px',
                        padding: '8px',
                        textAlign: 'center',
                      }}
                    >
                      <TextField
                        label='Floor Option Name'
                        value={formValue.name}
                        name='name'
                        variant='outlined'
                        onChange={(e) => handleInputChange(index, e)}
                        inputProps={{
                          readOnly: requestType === 'view' ? true : false,
                        }}
                      />
                    </Card>
                  </Grid>
                  {/* <Grid item container style={{ marginTop: '10px' }}>
                    <Grid item xs={6}>
                      <Typography>No. of plan options</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        label=''
                        name='noOfPlanOptions'
                        value={formValue.noOfPlanOptions}
                        variant='outlined'
                        type='number'
                        onChange={(e) => handleInputChange(index, e)}
                        inputProps={{
                          readOnly: requestType === 'view' ? true : false,
                        }}
                      />
                    </Grid>
                  </Grid> */}
                  {/* {requestType === 'edit' ? (
                    <Grid item container style={{ marginTop: '10px' }}>
                      <Grid item xs={6}>
                        <IconButton onClick={(e) => handleRemoveGrid(e, index)}>
                          <Remove />
                        </IconButton>
                      </Grid>
                      <Grid item xs={12}>
                        <IconButton onClick={() => handleAddGrid()}>
                          <Add />
                        </IconButton>
                      </Grid>
                    </Grid>
                  ) : (
                    ''
                  )} */}
                </Grid>
              </Grid>
            ))}
            {requestType === 'edit' ? (
              <Grid item xs={3}>
                <Card
                  style={{
                    minWidth: 180,
                    minHeight: 354,
                    textAlign: 'center',
                    margin: '2px 2px',
                  }}
                >
                  <CardActionArea onClick={() => handleAddGrid()}>
                    <CardContent>
                      <IconButton size='medium' onClick={() => handleAddGrid()}>
                        <Add fontSize='inherit' />
                      </IconButton>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            ) : (
              ''
            )}
          </Grid>

          <Grid container spacing={2} style={{ marginTop: '10px' }}>
            <Grid item>
              <Button
                variant='contained'
                color='primary'
                type='submit'
                onClick={handleSubmit}
                disabled={requestType === 'view' ?? true}
              >
                Save
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant='contained'
                color='primary'
                type='button'
                disabled={requestType === 'view' ?? true}
              >
                Clear
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </form>
      <Paper className={classes.pageContent} style={{ marginTop: '10px' }}>
        <Grid container>
          <Grid item xs={12}>
            <FloorOptionRules
              floorPlanId={floorPlanId}
              homebuilderId={homebuilderId}
            />
          </Grid>
        </Grid>
      </Paper>
      <Paper className={classes.pageContent} style={{ marginTop: '10px' }}>
        <Grid container>
          <Grid item xs={12}>
            {id === undefined && (
              <Button variant='contained' size='large'>
                <Link to={`/previewoptions/${floorPlanId}`}>
                  Options Coordinates
                </Link>
              </Button>
            )}
          </Grid>
        </Grid>
      </Paper>
      <FilesModalDilog
        files={docs}
        open={open}
        setOpen={setOpen}
        handleClickFile={handleClickImage}
      />
    </div>
  );
};

export default FloorOptionsForm;
