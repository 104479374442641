import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Container, Button, ButtonGroup, Avatar } from '@material-ui/core';
import api from '../hooks/Api';
import Datatable from '../hooks/Datatable';
import Config from '../config';

require('es6-promise').polyfill();
require('isomorphic-fetch');

const title = 'Floor Plans';

function FloorPlans() {
  const [data, setData] = useState([]);

  const deleteRow = async (oldData) => {
    // console.log(oldData);
    const response = await api.delete(`/floorplans/${oldData._id}`);
    // console.log(response.data);
    if (response.status === 200) {
      setData((data) => {
        const index = oldData.tableData.id;
        const updatedRows = [...data];
        // let newData = data.filter((newData) => newData._id !== oldData._id);
        updatedRows.splice(index, 1);
        return updatedRows;
      });
    }
  };
  const [furnitures, setFurnitures] = useState([]);

  const columns = [
    {
      title: 'Name',
      field: 'name',
    },
    {
      title: 'Image',
      field: 'imagePath',
      render: (rowData) => (
        <Avatar alt='Uploaded pic' src={Config.baseURL + rowData.imagePath} />
      ),
    },
    {
      title: 'View/Edit Home',
      field: '_id',
      render: (rowData) => {
        return (
          <div>
            <ButtonGroup
              variant='text'
              color='primary'
              aria-label='text primary button group'
            >
              <Button>
                <Link to={`/floorplans/view/${rowData._id}`}>View</Link>
              </Button>
              <Button>
                <Link to={`/floorplans/edit/${rowData._id}`}> Edit </Link>
              </Button>
              <Button onClick={() => deleteRow(rowData)}>Delete</Button>
            </ButtonGroup>
          </div>
        );
      },
    },
    {
      title: 'No of Plan Options',
      field: 'noOfPlanOptions',
      render: (rowData) => (
        <Button>
          <Link to={`/flooroptions/edit/floorplan/${rowData._id}/`}>
            {rowData.noOfPlanOptions}
          </Link>
        </Button>
      ),
    },
  ];

  const retreveFloorPlans = async () => {
    const response = await api.get('/floorplans');

    return response.data;
  };

  const retreveFurnitures = async (floorPlanId) => {
    const response = await api.get(`/furnitures/floorplan/${floorPlanId}`);
    // console.log(response.data);
    const furnitures = response.data;
    let newFurnitures = [];
    newFurnitures[floorPlanId] = furnitures.length;
    setFurnitures((prev) => [...newFurnitures]);
  };

  useEffect(() => {
    const getAllFloorPlans = async () => {
      const allfloorPlans = await retreveFloorPlans();
      if (allfloorPlans) setData(allfloorPlans);
    };

    getAllFloorPlans();
  }, []);

  return (
    <>
      <Container>
        <Button variant='contained'>
          <Link to='/floorplansform'>Add New</Link>
        </Button>
      </Container>
      <Datatable
        title={title}
        data={data}
        columns={columns}
        delete={deleteRow}
        component='floorPlans'
      />
    </>
  );
}

export default FloorPlans;
