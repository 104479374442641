import React, { useEffect, useState, createContext } from 'react';
import Routes from './routes';
import { BrowserRouter as Router, NavLink } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles, useTheme, fade } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import {
  Grid,
  Badge,
  Box,
  Menu,
  MenuItem,
  Button,
  Collapse,
  ListSubheader,
  InputBase,
  Card,
  CardContent,
  Typography,
} from '@material-ui/core';
import NotificationsIcon from '@material-ui/icons/Notifications';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import StarBorder from '@material-ui/icons/StarBorder';
import SearchIcon from '@material-ui/icons/Search';
import logo from '../assets/images/XSCAD-logo.svg';
import api from '../hooks/Api';
import useData from '../hooks/useData';
import CommunitiesService from '../services/communitiesService';

const drawerWidth = 280;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& .link': {
      textDecoration: 'none',
    },
    '& .MuiListSubheader-sticky': {
      backgroundColor: '#fff',
    },
    '& .IfpCustomGridHeader': {
      padding: '8px',
      borderBottom: '1px solid lightgray',
    },
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(2),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  active: {
    backgroundColor: theme.palette.action.selected,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 1),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  grow: {
    flexGrow: 1,
  },
}));

const HomebuildersData = createContext();

export default function MaterialNavbar() {
  const classes = useStyles();
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const [open, setOpen] = React.useState(false);
  const version = '0.03';

  const navItems = [
    ['All Homebuilders', 'Add New Homebuilder'],
    [
      'Homebuilders',
      'Communities',
      'Homes',
      'Elevations',
      'Floorplans',
      'FloorOptions',
      'furniturePlanner',
    ],
    ['Approvals', 'Stick Notes', 'MediaLibrary'],
    ['Reports', 'Analytics', 'Users'],
  ];

  const navItemsSubHeader = [
    null,
    'Add/Edit Homebuilder Data',
    'All Users',
    'Admin Only',
  ];

  const subHeader = () => {};

  const [subNavItems, setSubNavItems] = useState([]);

  const [selectedNavItems, setSelectedNavItems] = useState(
    Array(navItems[1].length).fill([])
  );

  const [listItemOpen, setlistItemOpen] = React.useState(
    Array(navItems[1].length).fill(false)
  );

  const [activeItem, setActiveItem] = useState(null);

  const [index, setIndex] = useState(null);

  const { data: homebuilders, setData: setHomebuilders } = useData(
    'get',
    'homebuilders'
  );

  // const communitiesData = useData(
  //   'get',
  //   'communities',
  //   'homebuilders',
  //   selectedNavItems[index]
  // );

  // const homebuilders = { data, isPending, error };

  // console.log(data);

  const [centraltData, setCentralData] = useState({});

  console.log(centraltData);

  const [communities, setCommunities] = useState([]);

  const [homes, setHomes] = useState([]);

  const [elevations, setElevations] = useState([]);

  const [floorPlans, setFloorPlans] = useState([]);

  const [floorOptions, setFloorOptions] = useState([]);

  const [furnitures, setFurnitures] = useState([]);

  const [selectedIndex, setSelectedIndex] = React.useState([null, null]);

  const retreveData = async (endpoint) => {
    const response = await api.get(`/${endpoint}`);
    return response.data;
  };

  const retreveDataByParent = async (endpoint, parent, parentId) => {
    const response = await api.get(`/${endpoint}/${parent}/${parentId}`);
    return response.data;
  };

  const getCommunities = async (homebuilderId) => {
    const allCommunities = await retreveDataByParent(
      'communities',
      'homebuilder',
      homebuilderId
    );
    if (allCommunities) {
      setCommunities(allCommunities);
    } else {
      console.log('community error');
    }
  };

  const getHomes = async (communityId) => {
    const allHomes = await retreveDataByParent(
      'homes',
      'community',
      communityId
    );

    if (allHomes) {
      setHomes(allHomes);
    } else {
      console.log('homes error');
    }
  };

  const getElevations = async (homeId) => {
    const allElevations = await retreveDataByParent(
      'elevations',
      'home',
      homeId
    );

    if (allElevations) {
      setElevations(allElevations);
    } else {
      console.log('elevations error');
    }
  };

  const getFloorPlans = async (elevationId) => {
    const allFloorPlans = await retreveDataByParent(
      'floorplans',
      'elevation',
      elevationId
    );

    if (allFloorPlans) {
      setFloorPlans(allFloorPlans);
    } else {
      console.log('floor plans error');
    }
  };

  const getFloorOptions = async (floorPlanId) => {
    const allFloorOptions = await retreveDataByParent(
      'floorOptions',
      'floorplan',
      floorPlanId
    );

    if (allFloorOptions) {
      setFloorOptions(allFloorOptions);
    } else {
      console.log('floor options error');
    }
  };

  const getFurnitures = async (floorPlanId) => {
    const allFurnitures = await retreveDataByParent(
      'furnitures',
      'floorplan',
      floorPlanId
    );

    if (allFurnitures) {
      setFurnitures(allFurnitures);
    } else {
      console.log('furnitures error');
    }
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleListItemOpenClose = (e, index) => {
    // Make available sub items of homebuilders

    let newSubNavItemsArray = [...subNavItems];
    newSubNavItemsArray[1] = [];
    newSubNavItemsArray[2] = [];
    newSubNavItemsArray[4] = [];
    newSubNavItemsArray[5] = [];

    newSubNavItemsArray[6] = [];
    setSubNavItems((prev) => [...newSubNavItemsArray]);

    if (index === 0) {
      newSubNavItemsArray[index] = [...homebuilders];
      setCommunities((prev) => []);
      setHomes((prev) => []);
      setElevations((prev) => []);
      setFloorPlans((prev) => []);
      setFloorOptions((prev) => []);
    }
    // Make available sub items of communities
    if (index === 1 && newSubNavItemsArray[index - 1]) {
      newSubNavItemsArray[index] = [...communities];
      setHomes((prev) => []);
      setElevations((prev) => []);
      setFloorPlans((prev) => []);
      setFloorOptions((prev) => []);
    }
    // Make available sub items of homes
    if (index === 2 && newSubNavItemsArray[index - 1]) {
      newSubNavItemsArray[index] = [...homes];
      setElevations((prev) => []);
      setFloorPlans((prev) => []);
      setFloorOptions((prev) => []);
    }
    // Make available sub items of elevations
    if (index === 3 && newSubNavItemsArray[index - 1]) {
      newSubNavItemsArray[index] = [...elevations];
      setFloorPlans((prev) => []);
      setFloorOptions((prev) => []);
    }
    // Make available sub items of floorplans
    if (index === 4 && newSubNavItemsArray[index - 1]) {
      newSubNavItemsArray[index] = [...floorPlans];
      setFloorOptions((prev) => []);
      setFurnitures((prev) => []);
    }
    // Make available sub items of flooroptions
    if (index === 5 && newSubNavItemsArray[index - 1]) {
      newSubNavItemsArray[index] = [...floorOptions];
      // setFurnitures((prev) => []);
    }
    if (index === 6 && newSubNavItemsArray[index - 2]) {
      newSubNavItemsArray[index] = [...furnitures];
    }
    setSubNavItems((prev) => [...newSubNavItemsArray]);

    let newListItemOpen = [...listItemOpen];
    newListItemOpen[index] = !newListItemOpen[index];
    setlistItemOpen(newListItemOpen);
    // makeListItemActive(e, navItemIndex, index);

    // console.log(selectedNavItems);
  };

  const updateSelectedNavItems = (index, subNavId) => {
    let newSelectedNavItems = [...selectedNavItems];
    newSelectedNavItems[index] = subNavId;
    setSelectedNavItems((prev) => [...newSelectedNavItems]);
  };

  const handleGoButton = async (e, navItemIndex, index) => {
    setActiveItem(index);
    // console.log(activeItem);
    let newSubNavItemsArray = [...subNavItems];
    newSubNavItemsArray[1] = [];
    newSubNavItemsArray[2] = [];
    newSubNavItemsArray[4] = [];
    newSubNavItemsArray[5] = [];

    newSubNavItemsArray[6] = [];
    // newSubNavItemsArray[7] = [];

    setSubNavItems((prev) => [...newSubNavItemsArray]);

    if (index === 0 && homebuilders) {
      await getCommunities(selectedNavItems[index]);
      setIndex(index);

      // const dataOfComm = CommunitiesService.GetCommunitiesService(
      //   'homebuilder',
      //   selectedNavItems[index]
      // );
      // console.log(dataOfComm);
      // setCentralData({ homebuilders, communities: dataOfComm });
    }

    // Get all homes of selected community from navbar
    if (index === 1 && communities) {
      await getHomes(selectedNavItems[index]);
    }

    // Get all elevations of selected home from navbar
    if (index === 2 && homes) {
      await getElevations(selectedNavItems[index]);
    }

    // Get all floorplans of selected elevation from navbar
    if (index === 3 && elevations) {
      await getFloorPlans(selectedNavItems[index]);
    }

    // Get all floorplans of selected elevation from navbar
    if (index === 4 && floorPlans) {
      await getFloorOptions(selectedNavItems[index]);
      await getFurnitures(selectedNavItems[index]);
    }

    makeListItemActive(e, navItemIndex, index);
  };

  const menuId = 'primary-search-account-menu';

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
    </Menu>
  );

  const makeListItemActive = (e, navItemIndex, index) => {
    let newSelectedIndex = [...selectedIndex];
    newSelectedIndex[0] = navItemIndex;
    newSelectedIndex[1] = index;

    setSelectedIndex((prev) => [...newSelectedIndex]);
    console.log(selectedIndex);
  };

  const renderNavItems = navItems.map((navItem, navItemIndex) => (
    <div key={navItemIndex}>
      <List
        key={navItemIndex}
        subheader={
          navItemsSubHeader[navItemIndex] ? (
            <ListSubheader component='div' id='nested-list-subheader'>
              {navItemsSubHeader[navItemIndex]}
            </ListSubheader>
          ) : (
            ''
          )
        }
      >
        {navItem.map((text, index) => (
          <React.Fragment key={index}>
            <ListItem
              key={index}
              button
              onClick={
                navItemIndex === 1
                  ? (e) => handleListItemOpenClose(e, index)
                  : (e) => makeListItemActive(e, navItemIndex, index)
              }
              selected={
                selectedIndex[0] === navItemIndex && selectedIndex[1] === index
              }
              activeClassName={classes.active}
            >
              {navItemIndex === 1 ? (
                <>
                  <ListItemText primary={text} />
                  <ListItemIcon>
                    {listItemOpen[index] ? <ExpandLess /> : <ExpandMore />}
                  </ListItemIcon>
                  <NavLink className='link' to={`/${text}`}>
                    <Button
                      variant='outlined'
                      size='small'
                      onClick={(e) => handleGoButton(e, navItemIndex, index)}
                    >
                      Go
                    </Button>
                  </NavLink>
                </>
              ) : (
                <NavLink className='link' to={`/${text}`}>
                  <ListItemText primary={text} />
                </NavLink>
              )}
            </ListItem>
            {navItemIndex === 1 ? (
              <Collapse in={listItemOpen[index]} timeout='auto' unmountOnExit>
                <List component='div' disablePadding>
                  {subNavItems[index]
                    ? subNavItems[index].map((subNavItem, subNavItemIndex) => (
                        <ListItem
                          key={subNavItemIndex}
                          button
                          className={classes.nested}
                          onClick={(e) =>
                            updateSelectedNavItems(index, subNavItem._id)
                          }
                        >
                          <ListItemText primary={subNavItem.name} />
                        </ListItem>
                      ))
                    : ''}
                </List>
              </Collapse>
            ) : (
              ''
            )}
          </React.Fragment>
        ))}
      </List>

      <Box height={10}></Box>
      <Divider />
    </div>
  ));

  return (
    <Router>
      <div className={classes.root}>
        <CssBaseline />

        <AppBar
          color='primary'
          position='fixed'
          className={clsx(classes.appBar, {
            [classes.appBarShift]: open,
          })}
        >
          <Toolbar>
            <IconButton
              color='inherit'
              aria-label='open drawer'
              onClick={handleDrawerOpen}
              edge='start'
              className={clsx(classes.menuButton, open && classes.hide)}
            >
              <MenuIcon />
            </IconButton>

            <Box>
              {open ? '' : <img src={logo} width='33%' alt='xscad-logo' />}
            </Box>
            {/* <Box>
          <Typography variant="h6" noWrap>
            IFP Panel
          </Typography>
          </Box> */}

            <Grid container>
              <Grid item sm></Grid>
              <Grid item>
                <IconButton color='inherit'>
                  <Badge badgeContent={17} color='secondary'>
                    <NotificationsIcon />
                  </Badge>
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton
                  color='inherit'
                  edge='end'
                  aria-label='account of current user'
                  aria-controls={menuId}
                  aria-haspopup='true'
                  onClick={handleProfileMenuOpen}
                >
                  <Badge>
                    <AccountCircle />
                  </Badge>
                </IconButton>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        {renderMenu}
        <Drawer
          className={classes.drawer}
          variant='persistent'
          anchor='left'
          open={open}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.drawerHeader}>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'ltr' ? (
                <ChevronLeftIcon />
              ) : (
                <ChevronRightIcon />
              )}
            </IconButton>
            <img src={logo} width='75%' alt='xscad-logo' />
          </div>
          <Divider />
          <List>
            <ListItem>
              <div className={classes.search}>
                <div className={classes.searchIcon}>
                  <SearchIcon />
                </div>
                <InputBase
                  placeholder='Search…'
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                  }}
                  inputProps={{ 'aria-label': 'search' }}
                />
              </div>
            </ListItem>
          </List>
          <Divider />

          {renderNavItems}

          {/* <List>
            {navItems[1].map((text, index) => (
              // <NavLink className={classes.link} activeClassName="active" to={`/${text}`}>
              <div>
                <ListItem
                  button
                  onClick={(e) => handleListItemOpenClose(index)}
                  key={index}
                  exact
                  to={`/${text}`}
                  activeClassName={classes.active}
                >
                  <ListItemText primary={text} />
                  <ListItemIcon>
                    {listItemOpen[index] ? <ExpandLess /> : <ExpandMore />}
                  </ListItemIcon>

                  <NavLink
                    className='link'
                    activeClassName='active'
                    to={`/${text}`}
                  >
                    <Button variant='outlined' size='small'>
                      Go
                    </Button>
                  </NavLink>
                </ListItem>
                <Collapse in={listItemOpen[index]} timeout='auto' unmountOnExit>
                  <List component='div' disablePadding>
                    <ListItem button className={classes.nested}>
                      <ListItemIcon>
                        <StarBorder />
                      </ListItemIcon>
                      <ListItemText primary='Starred' />
                    </ListItem>
                    <ListItem button className={classes.nested}>
                      <ListItemIcon>
                        <StarBorder />
                      </ListItemIcon>
                      <ListItemText primary='Starred' />
                    </ListItem>
                  </List>
                </Collapse>
              </div>
            ))}
          </List>
          <Divider />
          <List
            component='nav'
            aria-labelledby='nested-list-subheader'
            subheader={
              <ListSubheader component='div' id='nested-list-subheader'>
                All Users
              </ListSubheader>
            }
          >
            {['Approvals', 'Stick Notes', 'MediaLibrary'].map((text, index) => (
              <ListItem key={index} button>
                <NavLink
                  className='link'
                  activeClassName='active'
                  to={`/${text}`}
                >
                  <ListItemText primary={text} />
                </NavLink>
              </ListItem>
            ))}
          </List>

          <Divider />
          <List
            subheader={
              <ListSubheader
                component='div'
                id='nested-list-subheader-admin-only'
              >
                Admin Only
              </ListSubheader>
            }
          >
            {['Reports', 'Analytics', 'Users'].map((text, index) => (
              <ListItem key={index} button>
                <ListItemText primary={text} />
              </ListItem>
            ))}
          </List> */}

          {/* <Divider /> */}
          <Grid>
            <Card>
              <CardContent>
                <Typography variant='overline' component='h6'>
                  XSCAD IFP Version: {version}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Drawer>

        <main
          className={clsx(classes.content, {
            [classes.contentShift]: open,
          })}
        >
          <div className={classes.drawerHeader} />
          <HomebuildersData.Provider value={homebuilders}>
            <Routes />
          </HomebuildersData.Provider>
        </main>
      </div>
    </Router>
  );
}

export { HomebuildersData };
