import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Container, Button, ButtonGroup, Avatar } from '@material-ui/core';
import api from '../hooks/Api';
import Datatable from '../hooks/Datatable';
import Config from '../config';

require('es6-promise').polyfill();
require('isomorphic-fetch');

const title = 'Floor Options';

function FloorOptions() {
  const [data, setData] = useState([]);

  const deleteRow = async (oldData) => {
    // console.log(oldData);
    const response = await api.delete(`/flooroptions/${oldData._id}`);
    // console.log(response.data);
    if (response.status === 200) {
      setData((data) => {
        const index = oldData.tableData.id;
        const updatedRows = [...data];
        // let newData = data.filter((newData) => newData._id !== oldData._id);
        updatedRows.splice(index, 1);
        return updatedRows;
      });
    }
  };
  const columns = [
    {
      title: 'Name',
      field: 'name',
    },
    {
      title: 'Image',
      field: 'imagePath',
      render: (rowData) => (
        <Avatar alt='Uploaded pic' src={Config.baseURL + rowData.imagePath} />
      ),
    },
    {
      title: 'View/Edit Home',
      field: '_id',
      render: (rowData) => {
        return (
          <div>
            <ButtonGroup
              variant='text'
              color='primary'
              aria-label='text primary button group'
            >
              <Button>
                <Link to={`/flooroptions/view/${rowData._id}`}>View</Link>
              </Button>
              <Button>
                <Link to={`/flooroptions/edit/${rowData._id}`}> Edit </Link>
              </Button>
              <Button onClick={() => deleteRow(rowData)}>Delete</Button>
            </ButtonGroup>
          </div>
        );
      },
    },
  ];

  const retreveFloorOptions = async () => {
    const response = await api.get('/flooroptions');
    return response.data;
  };

  useEffect(() => {
    const getAllFloorOptions = async () => {
      const allfloorOptions = await retreveFloorOptions();
      if (allfloorOptions) setData(allfloorOptions);
    };
    getAllFloorOptions();
  }, []);

  return (
    <>
      <Container>
        <Button variant='contained'>
          <Link to='/flooroptionsform'>Add New</Link>
        </Button>
      </Container>
      <Datatable
        title={title}
        data={data}
        columns={columns}
        delete={deleteRow}
        component='flooroptions'
      />
    </>
  );
}

export default FloorOptions;
