const checkKeyExists = (obj, key) => {
  if (obj.hasOwnProperty(key)) {
    return true;
  } else {
    return false;
  }
};

export const stringLastElement = (string, seperator) => {
  const array = string.split(seperator);
  const lastElement = array[array.length - 1];
  return lastElement;
};

function arrayRemove(arr, value) {
  return arr.filter(function (ele) {
    return ele != value;
  });
}

// 📁 say.js
function sayHi(user) {
  alert(`Hello, ${user}!`);
}

function sayBye(user) {
  alert(`Bye, ${user}!`);
}

export { sayHi, sayBye, checkKeyExists, arrayRemove };
