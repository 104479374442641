import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { getSingleFloorPlan } from '../services/floorPlansService';
import {
  getOptionsByFloorPlan,
  updateFloorOptionCoordinates,
} from '../services/floorOptionsService';
import {
  Grid,
  Paper,
  makeStyles,
  List,
  ListItem,
  ListItemText,
  Typography,
  Divider,
  Button,
} from '@material-ui/core';
import Draggable from 'react-draggable';
import Config from '../config';
import { stringLastElement } from '../helpers/helpers';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: 'repeat(12, 1fr)',
    gridGap: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    whiteSpace: 'nowrap',
    marginBottom: theme.spacing(1),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
}));

const PreviewOptions = () => {
  const classes = useStyles();
  const { id: floorPlanId } = useParams();
  const [floorPlan, setFloorPlan] = useState(null);
  const [floorOptions, setFloorOptions] = useState(null);
  const [coordinates, setCoordinates] = useState({});
  const [positions, setPositions] = useState([]);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [frameLeft, setFrameLeft] = useState(0);
  const [frameTop, setFrameTop] = useState(0);
  const [success, setSuccess] = useState('');
  const frameRef = React.useRef(null);
  const elementRef = React.useRef(null);
  useEffect(() => {
    const singleFloorPlan = async () => {
      const floorPlanData = await getSingleFloorPlan(floorPlanId);
      // console.log('floor Plan : ', floorPlanData);
      if (floorPlanData) setFloorPlan(floorPlanData);
    };

    let dummyPositions = { ...positions };

    const getFloorOptions = async () => {
      const floorOptionsData = await getOptionsByFloorPlan(floorPlanId);

      if (floorOptionsData) {
        setFloorOptions(floorOptionsData);

        //   for(const floorOption in floorOptionsData){

        //   }

        floorOptionsData.forEach((floorOption) => {
          // console.log('floor option : ', floorOption);

          const itemId = floorOption._id;
          dummyPositions[itemId] = {};
          dummyPositions[itemId]['plan'] = floorOption.planCoordinate;
          dummyPositions[itemId]['label'] = floorOption.labelCoordinate;
        });
        // if (frameRef.current != null) {
        //   const frameRect = frameRef.current;
        //   console.log('frame left: ', frameRect.offsetLeft);
        //   console.log('frame top: ', frameRect.offsetTop);
        //   setFrameLeft(frameRect.offsetLeft);
        //   setFrameTop(frameRect.offsetTop);
        //   console.log('frame left: ', frameRect);
        // }

        setPositions(dummyPositions);
        setHasLoaded(true);
        //   setPositions(floorOptionsData);
      }
    };

    // const existingDivPositions = JSON.parse(
    //     localStorage.getItem('positions_div')
    //   );

    console.log('existing positions: ', dummyPositions);
    console.log('has loaded');
    getFloorOptions();
    singleFloorPlan();
  }, []);
  const frameoffset = document.getElementById('frame');
  const handleStop = (e, data) => {
    console.log('data: ', data);

    const id = e.target.id;
    const itemId = stringLastElement(id, '-');
    let coords = [];
    const ele = document.querySelector('#' + id).getBoundingClientRect();

    console.log('frame: ', frameRef.current.getBoundingClientRect());
    // console.log({
    //   x: data.x - frameoffset.offsetLeft,
    //   y: data.y - frameoffset.offsetTop,
    // });

    coords.push({
      [itemId]: {
        x: ele.left - frameoffset.offsetLeft,
        y: ele.top - frameoffset.offsetTop,
      },
    });
    setCoordinates(coords);
    let dummyPositions = { ...positions };

    dummyPositions[itemId] = {};
    dummyPositions[itemId]['x'] = ele.left - frameoffset.offsetLeft;
    dummyPositions[itemId]['y'] = ele.top - frameoffset.offsetTop;
    setPositions(dummyPositions);
    console.log('new positions:', positions);
    console.log('element: ', coords);
    console.log('positions: ', coordinates);
  };

  const hanndleCoords = async () => {
    const response = await updateFloorOptionCoordinates(positions);
    console.log(response.status);
    if (response.status === 200) {
      setSuccess('Coordinates saved successfully!');
    }
  };
  return (
    <Paper className={classes.paper}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Button variant='contained'>
            <Link to={`/optionscoordinates/${floorPlanId}`} target='_blank'>
              Edit Widget
            </Link>
          </Button>
        </Grid>
        <Grid item xs={3}></Grid>
        {hasLoaded ? (
          <Grid item xs={9}>
            {/* <Paper className={classes.paper} style={{ height: '1280px' }}> */}
            {floorPlan !== null && (
              <div
                ref={frameRef}
                id='frame'
                style={{
                  position: 'relative',
                  display: 'inline-block',
                  zIndex: '1',
                }}
              >
                <img
                  src={Config.baseURL + floorPlan.imagePath}
                  alt='floorplan'
                />

                {Object.keys(positions).length !== 0 &&
                  floorOptions.map((floorOption, index) => (
                    <>
                      <span
                        id={`option-drag-${floorOption._id}`}
                        className='optionDragElement'
                        ref={elementRef}
                        style={{
                          position: 'absolute',
                          zIndex: '2',
                          left: positions[floorOption._id]['plan']['x'],
                          top: positions[floorOption._id]['plan']['y'],
                        }}
                      >
                        <img
                          id={`option-drag-img-${floorOption._id}`}
                          src={Config.baseURL + floorOption.imagePath}
                          alt='floorplan'
                        />
                      </span>
                      <Typography
                        id={`label-drag-${floorOption._id}`}
                        className='labelDragElement'
                        data-type='label'
                        component='div'
                        variant='caption'
                        style={{
                          position: 'absolute',
                          zIndex: '3',
                          left: positions[floorOption._id]['label']['x'],
                          top: positions[floorOption._id]['label']['y'],
                        }}
                      >
                        {floorOption.name}
                      </Typography>
                      {/* <Divider component='li' /> */}
                    </>
                  ))}
              </div>
            )}
            {/* </Paper> */}
          </Grid>
        ) : (
          <p>Floor Options not loaded yet</p>
        )}
        {/* <Grid item xs={1}></Grid> */}

        {/* <Grid>
          <Button onClick={hanndleCoords}>Save Coordinates</Button>
          {success !== '' && <Typography component='p'></Typography>}
        </Grid>
        <Grid>
          <Button onClick={hanndleCoords}>Preview Widget</Button>
        </Grid> */}
      </Grid>
    </Paper>
  );
};

export default PreviewOptions;
