import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import api from '../hooks/Api';
import { useHistory, useLocation, useParams } from 'react-router';
import { useForm, Form } from './useForm';
import Controls from '../hooks/controls/Controls';
import {
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Paper,
  Select,
} from '@material-ui/core';
import { Country, State, City } from 'country-state-city';
import ImportRecords from './ImportRecords';
import { Link } from 'react-router-dom';

const defaultValues = {
  name: '',
  country: '',
  city: '',
  state: '',
  status: 0,
  homebuilder_id: '',
  homebuilder: {},
  create_date: '2021-04-23',
  last_update: '2021-04-23 00:03:41',
  created_by: '1',
  last_update_by: '1',
};

const useStyles = makeStyles((theme) => ({
  pageContent: {
    marging: theme.spacing(5),
    padding: theme.spacing(3),
  },
  grid: {
    width: '100%',
    margin: '0px',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

const CommunityForm = () => {
  const location = useLocation();
  let requestType = '';
  requestType = location.pathname.split('/')[2]
    ? location.pathname.split('/')[2]
    : '';

  const classes = useStyles();
  let history = useHistory();
  const { values, setValues, handleInputChange } = useForm(defaultValues);

  const { id } = useParams();
  const getCommunity = async () => {
    const community = await api.get(`/communities/${id}`);
    // console.log(homebuilder);
    return community.data;
  };

  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormValues({
  //     ...formValues,
  //     [name]: value,
  //   });
  // };
  const getAllCountries = Country.getAllCountries();
  const specificCountries = getAllCountries.filter(
    (country) =>
      country.isoCode === 'AU' ||
      country.isoCode === 'GB' ||
      country.isoCode === 'US' ||
      country.isoCode === 'CA' ||
      country.isoCode === 'NZ'
  );
  const getAllStates = (countryCode) => State.getStatesOfCountry(countryCode);
  const getAllCities = (countryCode, stateCode) =>
    City.getCitiesOfState(countryCode, stateCode);
  const [homebuildersData, setHomebuildersData] = useState([]);

  const retreveAllHomebuilders = async () => {
    const response = await api.get('/homebuilders');
    return response.data;
  };

  const retreveSingleHomebuilder = async () => {
    const response = await api.get(`/homebuilders/${values.homebuilder_id}`);
    return response.data;
  };

  useEffect(() => {
    const community = async () => {
      const getSingleCommunity = await getCommunity();
      if (getSingleCommunity) setValues(getSingleCommunity);
    };
    // console.log(requestType);
    community();
  }, []);

  useEffect(() => {
    const getAllHomebuilders = async () => {
      const allHomebuilders = await retreveAllHomebuilders();
      if (allHomebuilders) setHomebuildersData(allHomebuilders);
    };

    getAllHomebuilders();
  }, []);

  const getSingleHomebuilder = async () => {
    const singleHomebuilder = await retreveSingleHomebuilder();
    if (singleHomebuilder)
      setValues((prev) => (prev.homebuilder = singleHomebuilder));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    getSingleHomebuilder();
    console.log(values);
    if (requestType === 'edit') {
      await api.patch(`/communities/${id}`, values);
    } else {
      await api.post('/communities', values);
    }

    history.push('/communities');
  };

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Grid container className={classes.grid} direction='column' spacing={2}>
          <Grid item container spacing={2}>
            <Grid item>
              <Button variant='contained' size='large'>
                <Link to='/communityform'>Add Community</Link>
              </Button>
            </Grid>
            <Grid item>
              <Button variant='contained' size='large'>
                <Link to={`/communities/edit/${id}`}>Edit Community</Link>
              </Button>
            </Grid>
            <Grid item>
              <Button variant='contained' size='large'>
                <Link to='/communities'>View All Communities</Link>
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Paper className={classes.paper}>
              <Controls.Input
                name='name'
                label='Name'
                value={values.name}
                onChange={handleInputChange}
                InputProps={{
                  readOnly: requestType === 'view' ? true : false,
                }}
              />
              {/* <Controls.Select
            name="country"
            label="Country"
            value={values.country}
            onChange={handleInputChange}
            options={getAllCountries}
            key="isoCode"
            text="name"
          /> */}
              <FormControl variant='outlined'>
                <InputLabel>Country</InputLabel>
                <Select
                  name='country'
                  label='Country'
                  value={values.country}
                  onChange={handleInputChange}
                  inputProps={{
                    readOnly: requestType === 'view' ? true : false,
                  }}
                >
                  <MenuItem key='0' value=''>
                    None
                  </MenuItem>
                  {specificCountries.map((option) => (
                    <MenuItem key={option.isoCode} value={option.isoCode}>
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl variant='outlined'>
                <InputLabel>State</InputLabel>
                <Select
                  name='state'
                  label='State'
                  value={values.state}
                  onChange={handleInputChange}
                  inputProps={{
                    readOnly: requestType === 'view' ? true : false,
                  }}
                >
                  <MenuItem key='0' value=''>
                    None
                  </MenuItem>
                  {getAllStates(values.country).map((option) => (
                    <MenuItem key={option.isoCode} value={option.isoCode}>
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl variant='outlined'>
                <InputLabel>City</InputLabel>
                <Select
                  name='city'
                  label='City'
                  value={values.city}
                  onChange={handleInputChange}
                  inputProps={{
                    readOnly: requestType === 'view' ? true : false,
                  }}
                >
                  <MenuItem key='0' value=''>
                    None
                  </MenuItem>
                  {getAllCities(values.country, values.state).map((option) => (
                    <MenuItem key={option.name} value={option.name}>
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl variant='outlined'>
                <InputLabel>Homebuilder</InputLabel>
                <Select
                  name='homebuilder_id'
                  label='Homebuilder'
                  value={values.homebuilder_id}
                  onChange={handleInputChange}
                  inputProps={{
                    readOnly: requestType === 'view' ? true : false,
                  }}
                >
                  <MenuItem key='0' value=''>
                    None
                  </MenuItem>
                  {homebuildersData.map((option) => (
                    <MenuItem key={option._id} value={option._id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Controls.Switch
                name='status'
                label='Status'
                value={values.status}
                onChange={handleInputChange}
                disabled={requestType === 'view' ?? true}
              />
            </Paper>

            {/* <Controls.Input
            name="city"
            label="City"
            value={values.city}
            onChange={handleInputChange}
          />
          <Controls.Input
            name="state"
            label="State"
            value={values.state}
            onChange={handleInputChange}
          /> */}
          </Grid>
          <Grid item xs={6} container spacing={4}>
            <Grid item>
              <Button
                variant='contained'
                color='primary'
                type='submit'
                disabled={requestType === 'view' ?? true}
              >
                Save
              </Button>
            </Grid>
            <Grid item>
              <Button variant='contained' color='primary'>
                Clear
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Form>
      <div>
        <Grid container>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <ImportRecords />
            </Paper>
          </Grid>
        </Grid>
      </div>
    </>
  );
};
export default CommunityForm;
