import React from 'react';
import Draggable from 'react-draggable';

const DraggableDemo = () => {
  return (
    <Draggable
      handle='.handle'
      defaultPosition={{ x: 0, y: 0 }}
      position={null}
      grid={[25, 25]}
      scale={1}
      onStart={Draggable.handleStart}
      onDrag={Draggable.handleDrag}
      onStop={Draggable.handleStop}
    >
      <div>
        <div className='handle'>Drag from here</div>
        <div>This readme is really dragging on...</div>
      </div>
    </Draggable>
  );
};

export default DraggableDemo;
