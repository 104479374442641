import React, { useState, useEffect } from 'react';
import { Container, Button, ButtonGroup } from '@material-ui/core';
import { Link } from 'react-router-dom';
import api from '../hooks/Api';
import Datatable from '../hooks/Datatable';

const retreveHomes = async () => {
  const response = await api.get('/homes');
  return response.data;
};
const title = 'Homes';

function Homes() {
  const [data, setData] = useState([]);

  const deleteRow = async (oldData) => {
    const response = await api.delete(`/homes/${oldData._id}`);
    if (response.status === 200) {
      // console.log(oldData);
      setData((data) => {
        const index = oldData.tableData.id;
        const updatedRows = [...data];
        // let newData = data.filter((newData) => newData._id !== oldData._id);
        updatedRows.splice(index, 1);
        // console.log(newData);
        return updatedRows;
      });
    }
  };

  const columns = [
    {
      title: 'Home',
      field: 'name',
    },
    {
      title: 'Status',
      field: 'status',
      render: (rowData) => {
        if (rowData.status === 1) {
          return <span style={{ color: 'green' }}>Active</span>;
        } else {
          return <span style={{ color: 'red' }}>Inactive</span>;
        }
      },
    },
    {
      title: 'View/Edit Homebuilder Info',
      field: '_id',
      render: (rowData) => {
        return (
          <div>
            <ButtonGroup
              variant='text'
              color='primary'
              aria-label='text primary button group'
            >
              <Button>
                <Link to={`/homes/view/${rowData._id}`}>View</Link>
              </Button>
              <Button>
                <Link to={`/homes/edit/${rowData._id}`}> Edit </Link>
              </Button>
              <Button onClick={() => deleteRow(rowData)}>Delete</Button>
            </ButtonGroup>
          </div>
        );
      },
    },
    {
      title: 'No of elevations',
      field: 'no_of_elevations',
      render: (rowData) => (
        <Button>
          <Link to={`/elevations/edit/home/${rowData._id}/`}>
            {rowData.no_of_elevations}
          </Link>
        </Button>
      ),
    },
    {
      title: 'No of floor plans',
      field: 'noOfFloorPlans',
      render: (rowData) => (
        <Button>
          <Link to={`/floorplans/edit/home/${rowData._id}/`}>
            {rowData.noOfFloorPlans}
          </Link>
        </Button>
      ),
    },
  ];
  const getAllHomes = async () => {
    const allHomes = await retreveHomes();
    if (allHomes) setData(allHomes);
  };
  useEffect(() => {
    getAllHomes();
  }, []);

  return (
    <div>
      <Container>
        <Button variant='contained'>
          <Link to='/homesform'>Add New</Link>
        </Button>
      </Container>
      {/* <HomesTable data={data} /> */}
      <Datatable
        title={title}
        data={data}
        columns={columns}
        delete={deleteRow}
        component='homes'
      />
    </div>
  );
}

export default Homes;
