import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';

export function useForm(initialFValues) {
  const [values, setValues] = useState(initialFValues);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    console.log(values);
  };

  return {
    values,
    setValues,
    handleInputChange,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiFormControl-root': {
      width: '100%',
      margin: '8px 0px',
    },
  },
}));

export function Form(props) {
  const { onSubmit } = props;
  const classes = useStyles();

  return (
    <form onSubmit={onSubmit} className={classes.root}>
      {props.children}
    </form>
  );
}
