import React from 'react';
import { Route } from 'react-router-dom';
import { Container } from '@material-ui/core';
import Homebuilders from './Homebuilders';
import Communities from './Communities';
import Homes from './Homes';
import Elevations from './Elevations';
import HomebuilderForm from './HomebuilderForm';
import CommunityForm from './CommunityForm';
import HomesForm from './HomesForm';
import ElevationsForm from './ElevationsForm';
import MaterialUi from './MaterialUi';
import Employees from '../employees/Employees';
import MediaLibrary from './MediaLibrary';
import FloorPlans from './FloorPlans';
import FloorPlansForm from './FloorPlansForm';
import FloorOptions from './FloorOptions';
import FloorOptionsForm from './FloorOptionsForm';
import Furnitures from './Furnitures';
import FurnituresForm from './FurnituresForm';
import Widget from './Widget';
import IfpW from './IfpW';
import HomebuilderWidget from './HomebuilderWidget';
import FilesLibrary from './FilesLibrary';
import KonvaDemo from './KonvaDemo';
import DraggableDemo from './DraggableDemo';
import OptionsCoordinates from './OptionsCoordinates';
import PreviewOptions from './PreviewOptions';
import FurniturePlanner from './FurniturePlanner';
import Printfloorplan from './printfloorplan';

function Routes() {
  const WidgetDiv = document.querySelector('.ifp_widget');
  return (
    <>
      <Route
        exact
        path='/homebuilders'
        render={(props) => (
          <React.Fragment>
            <Container fixed>
              <Homebuilders />
            </Container>
          </React.Fragment>
        )}
      />
      <Route
        exact
        path='/communities'
        render={(props) => (
          <React.Fragment>
            <Container>
              <Communities />
            </Container>
          </React.Fragment>
        )}
      />

      <Route
        exact
        path='/homes'
        render={(props) => (
          <React.Fragment>
            <Container>
              <Homes />
            </Container>
          </React.Fragment>
        )}
      />

      <Route
        exact
        path='/elevations'
        render={(props) => (
          <React.Fragment>
            <Container>
              <Elevations />
            </Container>
          </React.Fragment>
        )}
      />

      <Route
        exact
        path='/homebuilderform'
        render={(props) => (
          <React.Fragment>
            <Container>
              <HomebuilderForm />
            </Container>
          </React.Fragment>
        )}
      />

      <Route
        exact
        path='/homebuilder/edit/:id'
        render={(props) => (
          <React.Fragment>
            <Container>
              <HomebuilderForm />
            </Container>
          </React.Fragment>
        )}
      />

      <Route
        exact
        path='/homebuilder/view/:id'
        render={(props) => (
          <React.Fragment>
            <Container>
              <HomebuilderForm />
            </Container>
          </React.Fragment>
        )}
      />

      <Route
        exact
        path='/communityform'
        render={(props) => (
          <React.Fragment>
            <Container>
              <CommunityForm />
            </Container>
          </React.Fragment>
        )}
      />

      <Route
        exact
        path='/communities/edit/:id'
        render={(props) => (
          <React.Fragment>
            <Container>
              <CommunityForm />
            </Container>
          </React.Fragment>
        )}
      />
      <Route
        exact
        path='/communities/view/:id'
        render={(props) => (
          <React.Fragment>
            <Container>
              <CommunityForm />
            </Container>
          </React.Fragment>
        )}
      />
      <Route
        exact
        path='/homes/edit/:id'
        render={(props) => (
          <React.Fragment>
            <Container>
              <HomesForm />
            </Container>
          </React.Fragment>
        )}
      />
      <Route
        exact
        path='/homes/view/:id'
        render={(props) => (
          <React.Fragment>
            <Container>
              <HomesForm />
            </Container>
          </React.Fragment>
        )}
      />
      <Route
        exact
        path='/elevations/edit/home/:homeId/'
        render={(props) => (
          <React.Fragment>
            <Container>
              <ElevationsForm />
            </Container>
          </React.Fragment>
        )}
      />

      <Route
        exact
        path='/homesform'
        render={(props) => (
          <React.Fragment>
            <Container>
              <HomesForm />
            </Container>
          </React.Fragment>
        )}
      />

      <Route
        exact
        path='/elevationsform'
        render={(props) => (
          <React.Fragment>
            <Container>
              <ElevationsForm />
            </Container>
          </React.Fragment>
        )}
      />
      <Route
        exact
        path='/elevations/view/:id'
        render={(props) => (
          <React.Fragment>
            <Container>
              <ElevationsForm />
            </Container>
          </React.Fragment>
        )}
      />
      <Route
        exact
        path='/elevations/edit/:id'
        render={(props) => (
          <React.Fragment>
            <Container>
              <ElevationsForm />
            </Container>
          </React.Fragment>
        )}
      />
      <Route
        exact
        path='/floorplans'
        render={(props) => (
          <React.Fragment>
            <Container>
              <FloorPlans />
            </Container>
          </React.Fragment>
        )}
      />
      <Route
        exact
        path='/floorplansform'
        render={(props) => (
          <React.Fragment>
            <Container>
              <FloorPlansForm />
            </Container>
          </React.Fragment>
        )}
      />
      <Route
        exact
        path='/floorplans/view/:id'
        render={(props) => (
          <React.Fragment>
            <Container>
              <FloorPlansForm />
            </Container>
          </React.Fragment>
        )}
      />
      <Route
        exact
        path='/floorplans/edit/:id'
        render={(props) => (
          <React.Fragment>
            <Container>
              <FloorPlansForm />
            </Container>
          </React.Fragment>
        )}
      />
      <Route
        exact
        path='/floorplans/edit/elevation/:elevationId/'
        render={(props) => (
          <React.Fragment>
            <Container>
              <FloorPlansForm />
            </Container>
          </React.Fragment>
        )}
      />
      <Route
        exact
        path='/floorplans/edit/home/:homeId/'
        render={(props) => (
          <React.Fragment>
            <Container>
              <FloorPlansForm />
            </Container>
          </React.Fragment>
        )}
      />
      <Route
        exact
        path='/flooroptions'
        render={(props) => (
          <React.Fragment>
            <Container>
              <FloorOptions />
            </Container>
          </React.Fragment>
        )}
      />
      <Route
        exact
        path='/flooroptionsform'
        render={(props) => (
          <React.Fragment>
            <Container>
              <FloorOptionsForm />
            </Container>
          </React.Fragment>
        )}
      />
      <Route
        exact
        path='/flooroptions/view/:id'
        render={(props) => (
          <React.Fragment>
            <Container>
              <FloorOptionsForm />
            </Container>
          </React.Fragment>
        )}
      />
      <Route
        exact
        path='/flooroptions/edit/:id'
        render={(props) => (
          <React.Fragment>
            <Container>
              <FloorOptionsForm />
            </Container>
          </React.Fragment>
        )}
      />
      <Route
        exact
        path='/flooroptions/edit/floorplan/:floorPlanId/'
        render={(props) => (
          <React.Fragment>
            <Container>
              <FloorOptionsForm />
            </Container>
          </React.Fragment>
        )}
      />
      <Route
        exact
        path='/furnitures'
        render={(props) => (
          <React.Fragment>
            <Container>
              <Furnitures />
            </Container>
          </React.Fragment>
        )}
      />
      <Route
        exact
        path='/furnituresform'
        render={(props) => (
          <React.Fragment>
            <Container>
              <FurnituresForm />
            </Container>
          </React.Fragment>
        )}
      />
      <Route
        exact
        path='/furnitures/view/:id'
        render={(props) => (
          <React.Fragment>
            <Container>
              <FurnituresForm />
            </Container>
          </React.Fragment>
        )}
      />
      <Route
        exact
        path='/furnitures/edit/:id'
        render={(props) => (
          <React.Fragment>
            <Container>
              <FurnituresForm />
            </Container>
          </React.Fragment>
        )}
      />
      <Route
        exact
        path='/furnitures/edit/floorplan/:floorPlanId/'
        render={(props) => (
          <React.Fragment>
            <Container>
              <FurnituresForm />
            </Container>
          </React.Fragment>
        )}
      />
      <Route
        exact
        path='/furnitureplanner'
        render={(props) => (
          <React.Fragment>
            <FurniturePlanner />
          </React.Fragment>
        )}
      />

      <Route
        exact
        path='/materialUi'
        render={(props) => (
          <React.Fragment>
            <Container>
              <MaterialUi />
            </Container>
          </React.Fragment>
        )}
      />
      <Route
        exact
        path='/employees'
        render={(props) => (
          <React.Fragment>
            <Container>
              <Employees />
            </Container>
          </React.Fragment>
        )}
      />
      <Route
        exact
        path='/medialibrary'
        render={(props) => (
          <React.Fragment>
            <MediaLibrary />
          </React.Fragment>
        )}
      />
      <Route
        exact
        path='/fileslibrary'
        render={(props) => (
          <React.Fragment>
            <FilesLibrary />
          </React.Fragment>
        )}
      />
      <Route
        exact
        path='/ifp_widget'
        render={(props) => (
          <React.Fragment>
            <Widget />
          </React.Fragment>
        )}
      />
      <Route
        exact
        path='/homebuilder-widget/:id'
        render={(props) => (
          <React.Fragment>
            <HomebuilderWidget />
          </React.Fragment>
        )}
      />
      <Route
        exact
        path='/konvademo'
        render={(props) => (
          <React.Fragment>
            <KonvaDemo />
          </React.Fragment>
        )}
      />
      <Route
        exact
        path='/draggabledemo'
        render={(props) => (
          <React.Fragment>
            <DraggableDemo />
          </React.Fragment>
        )}
      />
      <Route
        exact
        path='/optionscoordinates/:id'
        render={(props) => (
          <React.Fragment>
            <OptionsCoordinates />
          </React.Fragment>
        )}
      />
      <Route
        exact
        path='/previewoptions/:id'
        render={(props) => (
          <React.Fragment>
            <PreviewOptions />
          </React.Fragment>
        )}
      />
      <Route
        exact
        path='/printfloorplan'
        render={(props) => (
          <React.Fragment>
            <Printfloorplan />
          </React.Fragment>
        )}
      />
    </>
  );
}

export default Routes;
