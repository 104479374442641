import Api from '../hooks/Api';

const service = 'elevations';

export const getElevationsByHome = async (homeId) => {
  try {
    const response = await Api.get(`/${service}/home/${homeId}`);
    return response;
  } catch (error) {
    throw error;
  }
};
