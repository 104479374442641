import React from 'react';
import {
  Stage,
  Layer,
  Label,
  Tag,
  Text,
  Transformer,
  Circle,
} from 'react-konva';

const TrText = ({
  shapeProps,
  isSelected,
  onSelect,
  onChange,
  unSelectShape,
  onDelete,
}) => {
  const shapeRef = React.useRef();
  const deleteButton = React.useRef();

  const trRef = React.useRef();

  React.useEffect(() => {
    if (isSelected) {
      // we need to attach transformer manually
      trRef.current.nodes([shapeRef.current]);
      trRef.current.getLayer().batchDraw();
    }
  }, [isSelected]);

  React.useLayoutEffect(() => {
    shapeRef.current._sync();
    if (isSelected) {
      trRef.current.nodes([shapeRef.current]);
      trRef.current.getLayer().batchDraw();
    }
    shapeRef.current.getLayer().draw();
  }, [isSelected, shapeProps]);

  const handDelete = () => {
    unSelectShape(null);
    onDelete(shapeRef.current);
  };

  return (
    <React.Fragment>
      <Label
        onClick={onSelect}
        onTap={onSelect}
        ref={shapeRef}
        {...shapeProps}
        draggable
        onDragEnd={(e) => {
          onChange({
            ...shapeProps,
            x: e.target.x(),
            y: e.target.y(),
          });
        }}
        onTransformEnd={(e) => {
          // transformer is changing scale of the node
          // and NOT its width or height
          // but in the store we have only width and height
          // to match the data better we will reset scale on transform end
          const node = shapeRef.current;
          const scaleX = node.scaleX();
          const scaleY = node.scaleY();

          // we will reset it back
          node.scaleX(1);
          node.scaleY(1);
          onChange({
            ...shapeProps,
            x: node.x(),
            y: node.y(),
            // set minimal value
            width: Math.max(5, node.width() * scaleX) + Math.random() * 0.00001,
            height: Math.max(node.height() * scaleY),
            rotation: node.rotation(),
          });
        }}
      >
        <Tag fill={shapeProps.fill} />
        <Text
          text={shapeProps.text}
          width={shapeProps.width}
          height={shapeProps.height}
          style={{ fontSize: `${shapeProps.width}vw` }}
          padding={3}
        />
      </Label>
      {isSelected && (
        <Transformer
          ref={trRef}
          rotationSnapTolerance={5}
          rotationSnaps={[0, 90, 180, 270]}
          boundBoxFunc={(oldBox, newBox) => {
            // limit resize
            if (newBox.width < 5 || newBox.height < 5) {
              return oldBox;
            }
            return newBox;
          }}
        >
          <Circle
            ref={deleteButton}
            radius={8}
            fill='red'
            x={shapeRef.current.width()}
            onClick={handDelete}
          ></Circle>
        </Transformer>
      )}
    </React.Fragment>
  );
};

export default TrText;

// const initialRectangles = [
//   {
//     x: 10,
//     y: 10,
//     width: 100,
//     height: 20,
//     fill: "red",
//     id: "rect1",
//     text: "Hello"
//   },
//   {
//     x: 250,
//     y: 250,
//     width: 100,
//     height: 20,
//     fill: "green",
//     id: "rect2",
//     text: "Testing"
//   }
// ];

// const App = () => {
//   const [rectangles, setRectangles] = React.useState(initialRectangles);
//   const [selectedId, selectShape] = React.useState(null);

//   const checkDeselect = (e) => {
//     // deselect when clicked on empty area
//     const clickedOnEmpty = e.target === e.target.getStage();
//     if (clickedOnEmpty) {
//       selectShape(null);
//     }
//   };

//   return (
//     <Stage
//       width={window.innerWidth}
//       height={window.innerHeight}
//       onMouseDown={checkDeselect}
//       onTouchStart={checkDeselect}
//     >
//       <Layer>
//         {rectangles.map((rect, i) => {
//           return (
//             <TrText
//               key={i}
//               shapeProps={rect}
//               isSelected={rect.id === selectedId}
//               onSelect={() => {
//                 selectShape(rect.id);
//               }}
//               onChange={(newAttrs) => {
//                 const rects = rectangles.slice();
//                 rects[i] = newAttrs;
//                 setRectangles(rects);
//               }}
//             />
//           );
//         })}
//       </Layer>
//     </Stage>
//   );
// };

// export default App;
