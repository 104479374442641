import React, { useState } from 'react';
import ImageGrid from './ImageGrid';
import ImageModal from './ImageModal';
import MediaProgressBar from './MediaProgressBar';
import styled from 'styled-components';
import { Grid, makeStyles, Paper } from '@material-ui/core';

const Form = styled.form`
  margin: 30px auto 10px;
  text-align: center;
`;

const Label = styled.label`
  display: block;
  width: 30px;
  height: 30px;
  border: 1px solid #efb6b2;
  border-radius: 50%;
  margin: 10px auto;
  line-height: 30px;
  color: #efb6b2;
  font-weight: bold;
  font-size: 24px;
  :hover {
    background: #efb6b2;
    color: white;
  }
`;

const Output = styled.div`
  height: 60px;
  font-size: 0.8rem;
`;
const Error = styled.div`
  color: #ff4a4a;
`;

const usesStyles = makeStyles((theme) => ({
  grid: {
    width: '100%',
    margin: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

const MediaLibrary = () => {
  const classes = usesStyles();

  const [files, setFiles] = useState([]);
  const [error, setError] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState(null);
  // const types = ['image/jpg', 'image/jpeg', 'image/png', 'image/svg+xml'];

  const changeHandler = (e) => {
    // console.log('changesd');
    let inputFiles = e.target.files;
    if (inputFiles.length > 0) {
      setFiles(inputFiles);
      setError(null);
    } else {
      setFiles([]);
      setError('Please select an image file (png,jpg or svg)');
    }
  };

  return (
    <>
      <Grid container direction='column'>
        <Grid item className={classes.grid}>
          <Paper className={classes.paper}>
            <Form>
              <Label>
                <input
                  type='file'
                  onChange={changeHandler}
                  style={{ height: '0', width: '0', opacity: '0' }}
                  multiple
                />
                <span>+</span>
              </Label>
              <Output>
                {error && <Error>{error} </Error>}
                {files.length > 0 && <div>{files.length} files uploading</div>}
                {files.length > 0 && (
                  <MediaProgressBar files={files} setFiles={setFiles} />
                )}
              </Output>
            </Form>
          </Paper>
        </Grid>
        <Grid item className={classes.grid}>
          <Paper className={classes.paper}>
            <ImageGrid setSelectedFiles={setSelectedFiles} />
          </Paper>
        </Grid>
      </Grid>

      {selectedFiles && (
        <ImageModal
          selectedFiles={selectedFiles}
          setSelectedFiles={setSelectedFiles}
        />
      )}
    </>
  );
};

export default MediaLibrary;
