import api from '../hooks/Api';

const service = 'floorplans';

export const getFloorPlans = async () => {
  try {
    const { data } = await api.get(`${service}`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const getSingleFloorPlan = async (id) => {
  try {
    const { data } = await api.get(`/${service}/${id}`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const getFloorPlansByHome = async (homeId) => {
  try {
    const response = await api.get(`/${service}/home/${homeId}`);
    return response;
  } catch (error) {
    throw error;
  }
};
