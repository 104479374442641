import {
  FormControl,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
} from '@material-ui/core';
import React from 'react';

export default function Select(props) {
  const { name, value, label, onChange, options, key, text } = props;
  console.log(options);
  return (
    <FormControl variant='outlined'>
      <InputLabel>{label}</InputLabel>
      <MuiSelect name={name} label={label} value={value} onChange={onChange}>
        <MenuItem key='0' value=''>
          None
        </MenuItem>
        {options.map((option) => (
          <MenuItem key={option[key]} value={option[key]}>
            {option[text]}
          </MenuItem>
        ))}
      </MuiSelect>
    </FormControl>
  );
}
