import { Grid, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useParams } from 'react-router-dom';
import Widget from './Widget';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    margin: 'auto',
    maxWidth: 900,
  },
  image: {
    width: 260,
    height: 260,
  },
  img: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
  },
}));

const HomebuilderWidget = () => {
  const classes = useStyles();
  const { id } = useParams();
  return (
    <div>
      <Widget data={id} />
    </div>
  );
};

export default HomebuilderWidget;
