import React, { useState } from 'react';
import useUpload from '../hooks/files/useUpload';

import styled from 'styled-components';

const ProgressBar = styled.div`
  height: 5px;
  background: #efb6b2;
  margin-top: 20px;
`;

const MediaProgressBar = ({ files, setFiles }) => {
  const [refresh, setRefresh] = useState(false);
  const { uploadedFiles, progress, error } = useUpload(
    files,
    refresh,
    setRefresh
  );

  // useEffect(() => {
  //   console.log('error: ', error);
  //   console.log('files progress: ', progress);
  //   console.log('files uploaded: ', uploadedFiles);
  //   if (uploadedFiles.length === 0) {
  //     setFiles([]);
  //   }
  // }, [uploadedFiles, progress, setFiles]);

  return (
    <ProgressBar style={{ width: progress + '%' }}>{progress}%</ProgressBar>
  );
};

export default MediaProgressBar;
